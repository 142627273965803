import React, { useState, useEffect } from 'react';
import { deleteDataRequest, getDataRequest } from 'utils/request';
import FeatherIcon from 'feather-icons-react';
import { stringifyTime } from 'tools/StringTool';
import EmailAuthModal from 'pages/ServiceCenter/EmailAuthModal';
import { NoBtnModal, TwoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import theme from 'styles/theme';
import {
  QnACard,
  QnACardColumn,
  QnACardStatus,
  QnADiv,
  QnATitle,
  QnADetailTitle,
  QnACardRow,
  QnACardRowSecond,
  OpenButton,
  QnACardRowDetail,
  CloseButton,
  DeleteButton,
  ContentBox1,
  ContentBox2,
  ContentRow,
  ContentRowTitle,
  ContentData,
  ContentDataText,
  QnAImg,
} from '../style';
import { QNA_CARD_STATUS_SETTING } from '../constants';

const NoAuthQuestion = ({ simpleQnAData }) => {
  const { manToManInquirySeq, answerStatus } = simpleQnAData;
  const QnACardStatusData = QNA_CARD_STATUS_SETTING.find(
    cardSetting => cardSetting.type === answerStatus
  );
  //이메일 '인증' 버튼 Params
  const [emailAuthParamsOne, setEmailAuthParamsOne] = useState({
    email: '',
    certificationPurposeType: '',
    manToManInquirySeq: manToManInquirySeq,
  });
  //이메일 '인증 완료' 버튼 params (delete에서도 사용됨)
  const [emailAuthParamsTwo, setEmailAuthParamsTwo] = useState({
    email: '',
    certificationNumber: '',
    memberCertificationSeq: 0,
    manToManInquirySeq: manToManInquirySeq,
  });

  // 메시지
  const [incorrectEmailInputMessage, setIncorrectEmailInputMessage] =
    useState(''); //이메일 오류 메시지
  const [incorrectAuthNumInputMessage, setIncorrectAuthNumInputMessage] =
    useState(''); //인증 번호 오류 메시지

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState(''); //alert 모달 메시지

  //이메일 인증 모달 상태
  const [openAuthModal, setOpenAuthModal] = useState(false);
  //추후에 이메일 인증이 완료되면 seq를 받아서 모달을 닫아주고 상세 보기가 열리도록 함
  const [isClickedRequestAuthBtn, setIsClickedRequestAuthBtn] = useState(false);

  // 인증 제한 시간 표시
  const [count, setCount] = useState(150);
  const [time, setTime] = useState('02 : 30');

  //디테일 페이지 상태
  const [isDetailShow, setIsDetailShow] = useState(false);
  const [hasEmailAuth, setHasEmailAuth] = useState(false);

  //상세 컴포넌트
  const [detailQnAData, setDetailQnAData] = useState({}); //QnA 상세 데이터
  const [isEmailAuthStepOneCompleted, setIsEmailAuthStepOneCompleted] =
    useState(false);

  useEffect(() => {
    if (isEmailAuthStepOneCompleted) {
      const id = setInterval(() => {
        setCount(count => count - 1);
        setTime(() => stringifyTime(count - 1));
      }, 1000);

      if (count === 0 || !isClickedRequestAuthBtn) {
        clearInterval(id);
      }
      return () => clearInterval(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, emailAuthParamsTwo, isClickedRequestAuthBtn]);

  //이메일 인증 onChange
  const onChangeAuthInput = event => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmailAuthParamsOne(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setEmailAuthParamsTwo(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setIncorrectEmailInputMessage('');
    } else if (name === 'certificationNumber') {
      setEmailAuthParamsTwo(prevState => ({
        ...prevState,
        [name]: value,
      }));
      setIncorrectAuthNumInputMessage('');
    }
  };

  // 인증 요청 버튼 클릭시
  const onClickRequestAuth = () => {
    getAuthEmailStepOne({
      emailAuthParamsOne,
      setEmailAuthParamsTwo,
      setIncorrectEmailInputMessage,
      setIsEmailAuthStepOneCompleted,
      setAlertMessage,
    });
    setIsClickedRequestAuthBtn(true);
  };

  // 재인증 클릭시
  const onClickRequestAgain = () => {
    setCount(151);
    getAuthEmailStepOne({
      emailAuthParamsOne,
      setEmailAuthParamsTwo,
      setIncorrectEmailInputMessage,
      setIsEmailAuthStepOneCompleted,
    });
  };

  //인증 완료 클릭시
  const onClickCompleteAuth = () => {
    getAuthEmailStepTwo({
      emailAuthParamsOne,
      emailAuthParamsTwo,
      onCloseAuthModal,
      setDetailQnAData,
      setIsDetailShow,
      setIncorrectAuthNumInputMessage,
      setIsClickedRequestAuthBtn,
      setHasEmailAuth,
      setAlertMessage,
    });
    setIsClickedRequestAuthBtn(false);
  };

  //이메일 인증 Modal open
  const openEmailAuthModal = emailAuthPurpose => {
    // 인증 목적
    switch (emailAuthPurpose) {
      // 문의 삭제
      case 'MAN_TO_MAN_DEL':
        setOpenAuthModal(true);
        setEmailAuthParamsOne({
          email: '',
          certificationPurposeType: emailAuthPurpose,
          manToManInquirySeq: manToManInquirySeq,
        });
        break;
      // 문의 상세 보기
      case 'MAN_TO_MAN_DETAIL':
        if (!!hasEmailAuth) {
          setIsDetailShow(true);
        } else {
          setOpenAuthModal(true);
          setEmailAuthParamsOne({
            email: '',
            certificationPurposeType: emailAuthPurpose,
            manToManInquirySeq: manToManInquirySeq,
          });
        }
        break;
      default:
        break;
    }
  };

  //이메일 인증 Modal close
  const onCloseAuthModal = () => {
    setEmailAuthParamsOne({
      email: '',
      certificationPurposeType: '',
      manToManInquirySeq: manToManInquirySeq,
    });
    setEmailAuthParamsTwo({
      email: '',
      certificationNumber: '',
      memberCertificationSeq: 0,
      manToManInquirySeq: manToManInquirySeq,
    });
    setOpenAuthModal(false);
    setIsClickedRequestAuthBtn(false);
    setIncorrectEmailInputMessage('');
    setIncorrectAuthNumInputMessage('');
    setTime(() => stringifyTime(150));
  };

  // 문의 삭제 버튼 클릭
  const onClickDeleteBtn = () => {
    setShowDeleteModal(true);
  };

  // 문의 삭제 모달창 확인 버튼 클릭
  const onClickDeleteModalConfirmBtn = () => {
    openEmailAuthModal('MAN_TO_MAN_DEL');
    setShowDeleteModal(false);
  };

  return (
    <>
      {/* QnA 컴포넌트 */}
      <QnACard>
        <QnACardColumn>
          {isDetailShow ? (
            <QnADetailComp
              QnAData={detailQnAData}
              QnACardStatusData={QnACardStatusData}
              onClickDeleteBtn={onClickDeleteBtn}
              setIsDetailShow={setIsDetailShow}
            />
          ) : (
            <QnASimpleComp
              QnAData={simpleQnAData}
              QnACardStatusData={QnACardStatusData}
              openEmailAuthModal={openEmailAuthModal}
            />
          )}
        </QnACardColumn>
      </QnACard>
      {/* 비회원 이메일 인증 모달 */}
      <EmailAuthModal
        openAuthModal={openAuthModal}
        onCloseAuthModal={onCloseAuthModal}
        emailAuthParamsOne={emailAuthParamsOne}
        onChangeAuthInput={onChangeAuthInput}
        incorrectEmailInputMessage={incorrectEmailInputMessage}
        isClickedRequestAuthBtn={isClickedRequestAuthBtn}
        onClickRequestAuth={onClickRequestAuth}
        onClickRequestAgain={onClickRequestAgain}
        emailAuthParamsTwo={emailAuthParamsTwo}
        time={time}
        incorrectAuthNumInputMessage={incorrectAuthNumInputMessage}
        onClickCompleteAuth={onClickCompleteAuth}
      />
      {/* 문의 삭제 모달창  */}
      <TwoBtnModal
        showModal={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onClick={onClickDeleteModalConfirmBtn}
        leftButton="아니오"
        rightButton="예"
      >
        <ScrollArea>
          <Contents>문의 건을 삭제하시겠어요?</Contents>
        </ScrollArea>
      </TwoBtnModal>
      {/* API 통신 에러 모달창 */}
      <NoBtnModal
        showModal={!!alertMessage}
        onClose={() => setAlertMessage('')}
      >
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </>
  );
};

const QnASimpleComp = ({
  QnAData = {},
  openEmailAuthModal = () => {},
  QnACardStatusData = {},
}) => {
  const { inquiryDt, customerEmail } = QnAData;
  const { color, backgroundColor, text } = QnACardStatusData;
  return (
    <>
      <QnACardRow>
        <QnATitle>{customerEmail}의 문의</QnATitle>
        <QnADiv>
          <QnACardStatus color={color} backgroundColor={backgroundColor}>
            {text}
          </QnACardStatus>
          <OpenButton onClick={() => openEmailAuthModal('MAN_TO_MAN_DETAIL')}>
            <FeatherIcon icon="chevron-down" width="20px" height="20px" />
          </OpenButton>
        </QnADiv>
      </QnACardRow>
      <QnACardRow>
        <QnACardRowSecond color={theme.color.grey9}>
          {inquiryDt}
        </QnACardRowSecond>
      </QnACardRow>
    </>
  );
};

const QnADetailComp = ({
  QnAData = {},
  QnACardStatusData = {},
  onClickDeleteBtn = () => {},
  setIsDetailShow,
}) => {
  const { data = {}, qnaAnswerList } = QnAData;
  const { inquirySubject, inquiryContents, filePathList, inquiryDt } = data;
  const { color, backgroundColor, text } = QnACardStatusData;

  return (
    <>
      <QnACardRowDetail>
        <QnADetailTitle>{inquirySubject}</QnADetailTitle>
        <QnADiv>
          <QnACardStatus color={color} backgroundColor={backgroundColor}>
            {text}
          </QnACardStatus>

          <CloseButton onClick={() => setIsDetailShow(false)}>
            <FeatherIcon icon="chevron-up" width="20px" height="20px" />
          </CloseButton>
        </QnADiv>
      </QnACardRowDetail>
      <ContentBox1>
        <QnACardRowDetail>
          <QnACardRowSecond style={{ marginBottom: '10px' }}>
            {inquiryContents}
          </QnACardRowSecond>
        </QnACardRowDetail>
        <QnACardRow
          style={{ justifyContent: 'flex-start', margin: '10px 0px' }}
        >
          {filePathList?.length > 0 &&
            filePathList.map((item, index) => {
              return <QnAImg src={item} alt={item} key={index} />;
            })}
        </QnACardRow>
        <QnACardRowDetail>
          <QnACardRowSecond color={theme.color.grey9}>
            {inquiryDt}
          </QnACardRowSecond>
          {!qnaAnswerList?.length > 0 && (
            <DeleteButton onClick={onClickDeleteBtn}>삭제</DeleteButton>
          )}
        </QnACardRowDetail>
      </ContentBox1>
      {qnaAnswerList?.length > 0 &&
        qnaAnswerList.map(qnaAnswer => (
          <ContentBox2>
            <ContentRow>
              <QnADiv>
                <ContentRowTitle>셀러 크레딧</ContentRowTitle>
              </QnADiv>
            </ContentRow>
            <ContentData>
              <ContentDataText>{qnaAnswer.answerContents}</ContentDataText>
            </ContentData>
            <QnACardRowDetail>
              <QnACardRowSecond color={theme.color.grey9}>
                {qnaAnswer.answerDt}
              </QnACardRowSecond>
            </QnACardRowDetail>
          </ContentBox2>
        ))}
    </>
  );
};

const getAuthEmailStepOne = ({
  emailAuthParamsOne,
  setEmailAuthParamsTwo,
  setIncorrectEmailInputMessage,
  setIsEmailAuthStepOneCompleted,
  setAlertMessage,
}) => {
  const successFnc = data => {
    setEmailAuthParamsTwo(prevState => ({
      ...prevState,
      memberCertificationSeq: data.memberCertificationSeq,
      manToManInquirySeq: data.manToManInquirySeq,
    }));
    setIsEmailAuthStepOneCompleted(true);
  };
  const errorFnc = (data, message) => {
    console.error(message);
    setIncorrectEmailInputMessage(message);
    setIsEmailAuthStepOneCompleted(false);
  };
  getDataRequest({
    url: '/v1/na/qna/email/certi/request',
    params: emailAuthParamsOne,
    successFnc: successFnc,
    errorFnc: errorFnc,
    setModalMessage: setAlertMessage,
  });
};

const getAuthEmailStepTwo = ({
  emailAuthParamsOne,
  emailAuthParamsTwo,
  onCloseAuthModal,
  setIncorrectAuthNumInputMessage,
  setIsClickedRequestAuthBtn,
  setDetailQnAData,
  setIsDetailShow,
  setHasEmailAuth,
  setAlertMessage,
}) => {
  const successFnc = data => {
    switch (emailAuthParamsOne.certificationPurposeType) {
      case 'MAN_TO_MAN_DETAIL':
        getDetailQnARequest({
          manToManInquirySeq: data.manToManInquirySeq,
          memberCertificationSeq: data.memberCertificationSeq,
          setIncorrectAuthNumInputMessage,
          successFnc: data => {
            setDetailQnAData(data);
            setIsDetailShow(true);
            setHasEmailAuth(true);
          },
        });
        break;
      case 'MAN_TO_MAN_DEL':
        deleteQnARequest({
          manToManInquirySeq: data.manToManInquirySeq,
          memberCertificationSeq: data.memberCertificationSeq,
          setAlertMessage,
        });
        break;
      default:
        break;
    }
    setIsClickedRequestAuthBtn(false);
    onCloseAuthModal();
  };
  const errorFnc = (data, message) => {
    setIncorrectAuthNumInputMessage(message);
  };
  getDataRequest({
    url: '/v1/na/qna/email/certi',
    params: emailAuthParamsTwo,
    successFnc: successFnc,
    errorFnc: errorFnc,
    setModalMessage: setIncorrectAuthNumInputMessage,
  });
};

const getDetailQnARequest = ({
  manToManInquirySeq,
  memberCertificationSeq,
  setIncorrectAuthNumInputMessage,
  successFnc,
}) => {
  getDataRequest({
    url: `/v1/na/qna/${manToManInquirySeq}`,
    params: { memberCertificationSeq },
    successFnc: successFnc,
    errorFnc: setIncorrectAuthNumInputMessage,
    setModalMessage: setIncorrectAuthNumInputMessage,
  });
};

const deleteQnARequest = ({
  manToManInquirySeq,
  memberCertificationSeq,
  setAlertMessage,
}) => {
  deleteDataRequest({
    url: `/v1/na/qna/${manToManInquirySeq}`,
    params: { memberCertificationSeq },
    successFnc: () => {
      window.location.reload();
    },
    errorFnc: (data, message) => {
      setAlertMessage(message);
    },
    setModalMessage: setAlertMessage,
  });
};
export default NoAuthQuestion;
