import { formatNormal } from './NumberTool';

export const getString = n => {
  return n < 10000000
    ? formatNormal(n / 10000, { format: '0,000.[000]', unit: '만원' })
    : formatNormal(n / 100000000, { format: '0,000.[000]', unit: '억원' });
};

export const priceToString = price => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/* '01012345678' -> '010-1234-5678'*/
export const formatMobile = str =>
  str.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3');

export const formatBizRegistrationNumber = str =>
  str.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{5})/, '$1-$2-$3');

export const replaceSpace = (str, replaceChar = '') => {
  return str.replace(/\s/g, replaceChar);
};

export const replaceHyphen = (str, replaceChar = '') => {
  return str?.replace(/-/g, replaceChar);
};

export const replaceNumberAndSpecialCharacter = str =>
  str.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/g, '');

export const replaceSpecialCharacter = str =>
  str.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}\[\]\\\/]/g, '');

export const replaceNonNumber = str => str.replace(/[^0-9]/g, '');

/* 문자열의 앞뒤 공백 제거 */
export const trimSpace = str => str.trim();

/* 문자열의 앞 공백 제거 */
export const replaceFirstSpace = str => str.replace(/^\s*/, '');

/* 문자열의 뒤 공백 제거 */
export const replaceLastSpace = str => str.replace(/\s*$/, '');

/* 문자열의 모든 공백 제거 */
export const replaceAllSpace = str => str.replace(/(\s*)/g, '');

/* 문자열의 다중 공백 싱글 공백으로 변경 */
export const leaveOneSpace = str => str.replace(/ +/g, ' ');

/* 한글 체크 */
export const checkKor = str => {
  const regExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  if (regExp.test(str)) {
    return true;
  } else {
    return false;
  }
};

export const leftPad = (str, size, padChar) => {
  str += '';
  const gap = size - str.length;
  let padStr = '';
  for (let ii = 0; ii < gap; ii++) {
    padStr += padChar;
  }
  return padStr + str;
};

export const stringifyTime = seconds => {
  const min = Math.floor(seconds / 60);
  const sec = seconds % 60;
  return `${min < 10 ? '0' : ''}${min} : ${sec < 10 ? '0' : ''}${sec}`;
};

// UTF-16 변환 후 Base64로 인코딩
export const encodeToBase64String = plainText => {
  const encoder = new TextEncoder();
  const encoded = encoder.encode(plainText);
  return btoa(String.fromCharCode.apply(null, encoded));
};
