import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import { TextMedium } from './style';

const SearchIdFail = () => {
  const { login } = ROUTE_PATH;
  const location = useLocation();
  const navigate = useNavigate();

  const pageStatus = location.state;
  const returnToBasicPage = () => {
    if (!pageStatus) {
      window.history.back();
    }
  };
  useEffect(() => {
    returnToBasicPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSearchIdBizNumBtn = () => {
    navigate(login.searchId);
  };
  const onClickSignupButton = () => {
    navigate(login.joinMember);
  };

  return (
    <>
      <TextMedium>사업자번호로 아이디를 찾지 못했어요.</TextMedium>
      <StyledActiveButton tabIndex={0} onClick={onClickSearchIdBizNumBtn}>
        사업자번호 다시 입력
      </StyledActiveButton>
      <StyledLinkButton tabIndex={0} onClick={onClickSignupButton}>
        회원가입
      </StyledLinkButton>
    </>
  );
};

export default SearchIdFail;
