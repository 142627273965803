// 금융기관 이름 default 필터링 리스트
export const defaultFinanceNameList = [
  {
    value: '금융기관 이름으로 필터하기',
    label: '금융기관 이름으로 필터하기',
  },
  { value: '전체', label: '전체' },
];

// 직원 목록 default 필터링 리스트
export const defaultManagerNameList = [
  {
    value: '담당 직원 이름으로 필터하기',
    label: '담당 직원 이름으로 필터하기',
  },
  { value: '전체', label: '전체' },
];

// 판매몰 상태 필터링 리스트
export const loanStatusList = [
  { label: '대출 상태로 필터하기', value: 1 },
  { label: '전체', value: 2 },
  { label: '신청 완료', value: 'REQ' },
  { label: '담당자 확인 중', value: 'CONF' },
  { label: '기한 만료', value: 'EXPI' },
  { label: '승인', value: 'APRV' },
  { label: '보류', value: 'REJE' },
  { label: '종료', value: 'END' },
];
