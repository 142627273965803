import React from 'react';
import { useNavigate } from 'react-router-dom';
import { KEY_DATAPROVIDEAGREESEQ, createMarkup } from 'tools/WebTool';
import { OneBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';

const DeepLinkAgreeOneBtnModal = ({
  errorNo,
  showOneBtnModal = false,
  setShowOneBtnModal = () => {},
  btnModalMessage = '',
  setBtnModalMessage = () => {},
  errorData = {},
}) => {
  const navigate = useNavigate();

  //미확인 건이 있을 경우 : errorNo === 500
  const onClickUnidentifiedExistBtn = () => {
    if (!!errorData.dataProvideAgreeSeq) {
      localStorage.setItem(
        KEY_DATAPROVIDEAGREESEQ,
        errorData.dataProvideAgreeSeq
      );
    }
    setShowOneBtnModal(false);
  };

  //OneBtnModal 닫기 버튼
  const onCloseOneBtnModal = () => {
    setBtnModalMessage('');
    setShowOneBtnModal(false);
    navigate('/');
  };

  return errorNo === 500 ? (
    <OneBtnModal
      showModal={showOneBtnModal}
      onClose={onCloseOneBtnModal}
      onClick={onClickUnidentifiedExistBtn}
      btnContent="미확인 데이터 제공동의 확인하기"
    >
      <ScrollArea>
        <Contents dangerouslySetInnerHTML={createMarkup(btnModalMessage)} />
      </ScrollArea>
    </OneBtnModal>
  ) : (
    <OneBtnModal
      showModal={showOneBtnModal}
      onClose={onCloseOneBtnModal}
      onClick={onCloseOneBtnModal}
      btnContent="확인"
    >
      <ScrollArea>
        <Contents dangerouslySetInnerHTML={createMarkup(btnModalMessage)} />
      </ScrollArea>
    </OneBtnModal>
  );
};

export default DeepLinkAgreeOneBtnModal;
