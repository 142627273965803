import styled from 'styled-components';

export const CategoryWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const CategoryButtonWrapper = styled.div`
  display: block;
  width: calc(100% - 50px);
  max-height: ${props =>
    props.isOpen === false && props.initialWrapperHeight > 100
      ? '100px'
      : 'fit-content'};

  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  -webkit-line-clamp: 2; /* 크롬, 사파리 */
  -moz-line-clamp: 2; /* 파이어폭스 */
  -o-line-clamp: 2; /* 오페라 */
  -ms-line-clamp: 2; /* 익스플로러 */
  line-clamp: 2;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;

  @media screen and (max-width: 320px) {
    max-height: ${props =>
      props.isOpen === false && props.initialWrapperHeight > 100
        ? '75px'
        : 'fit-content'};
  }
`;

export const CategoryButton = styled.button`
  line-height: 1.6rem;
  padding: 9px 18px;
  margin: 0 14px 16px 0;

  font-size: 1.4rem;
  text-align: center;
  vertical-align: middle;

  color: ${props => (props.isClicked ? '#fff' : '#009ff9')};
  background: ${props => (props.isClicked ? '#009ff9' : '#fff')};
  border: 1px solid #009ff9;
  border-radius: 50px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 320px) {
    padding: 6px 12px;
    font-size: 1.2rem;
    margin: 0 7px 8px 0;
  }
`;

export const CategoryToggleButton = styled.button`
  position: absolute;
  top: 50px;
  right: 0;

  width: 36px;
  height: 36px;

  margin: 0 14px 14px 0;
  line-height: 1.6rem;
  vertical-align: middle;
  text-align: center;
  font-size: 1.4rem;

  border: 1px solid #009ff9;
  border-radius: 50px;
  color: #009ff9;
  background: url(/images/main_ar_s2.png) no-repeat center;
  background-size: 6px 11px;

  transform: ${props => (props.isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};

  @media screen and (max-width: 320px) {
    margin: 0;
    width: 28px;
    height: 28px;
    top: 40px;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 12px;
  height: 34px;
`;

export const TitleText = styled.p`
  display: block;
  max-width: 65%;

  font-size: 1.6rem;
  font-weight: 700;
  color: #111;
  text-align: left;
  line-height: 2.3rem;
`;

export const SelectWrapper = styled.div`
  width: 144px;
`;
