import styled from 'styled-components';
import theme from 'styles/theme';

export const ContentSubpage = styled.div`
  width: 100%;
`;

export const TextMedium = styled.div`
  padding: 41px 0 18px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 13px 19px;
  background: ${theme.backgroundColor.contentBox};
  border-radius: 10px;
  margin: 23px 0 0;
`;

export const ContentTitle = styled.div`
  margin: 19px 0 0;
  width: 100%;
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.navyBlue};

  &:first-child {
    margin: 0;
  }
`;

export const ContentData = styled.div`
  width: 100%;
  margin: 3px 0 0;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.backgroundColor.lightGrey};
  margin: 20px 0 23px;
`;

export const PolicyAgreeAllWrapper = styled.div`
  display: block;
  width: 100%;
  margin: 30px 0 0;
  padding: 9px 0 10px 14px;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const PolicyAgreeAllCheckboxLabel = styled.label`
  position: relative;
  display: inline-block;
  float: left;
  max-width: 100%;
  padding: 6px 16px 5px 29px;
  margin-bottom: 0;

  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
`;

export const PolicyAgreeAllCheckbox = styled.input`
  margin: 4px 0 0;
  box-sizing: border-box;
  padding: 0;
  line-height: normal;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
`;

export const PolicyAgreeAllCheckboxIcon = styled.span`
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 7px;
  width: 20px;
  height: 20px;
  input[type='checkbox']:not(:checked) + & {
    background: url(/images/check-004.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
  input[type='checkbox']:checked + & {
    background: url(/images/check-004-cn1.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const TitleName = styled.span`
  color: ${theme.color.blue};
`;

export const BusinessTypeWrapper = styled.div`
  display: block;
  width: 100%;
  margin: 9px 0 0;

  &:after {
    content: "";
    display: block;
    clear: both;
`;

export const BusinessTypeLabel = styled.div`
  float: left;
  height: 37px;
  line-height: 37px;
  text-align: left;
  font-size: 1.3rem;
  font-weight: 500;
  color: ${theme.color.black};
`;

export const BusinessTypeInputWrapper = styled.div`
  float: right;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

const BusinessTypeInputRadio = styled.input`
  display: none;
  width: 0;
  height: 0;

  & + label {
    height: 37px;
    line-height: 37px;
    text-align: center;
    width: 98px;
    font-size: 13px;
    color: ${theme.color.white};
    font-weight: 500;
    background: ${theme.backgroundColor.grey5};
    float: left;
    cursor: default;
  }
  & + label:link,
  & + label:visited {
    color: ${theme.color.white};
  }
  &:checked + label {
    background: ${theme.backgroundColor.blue};
  }
`;

export const BusinessTypeInputRadioStart = styled(BusinessTypeInputRadio)`
  & + label {
    border-radius: 10px 0 0 10px;
  }
`;

export const BusinessTypeInputRadioEnd = styled(BusinessTypeInputRadio)`
  & + label {
    border-radius: 0 10px 10px 0;
  }
`;

export const PolicyAgreeWrapper = styled.div`
  display: block;
  width: 100%;
  margin: 20px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const PolicyAgreeTitle = styled.label`
  display: inline-block;
  margin-bottom: 0;
  float: left;
  position: relative;
  max-width: 100%;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 16px 5px 29px;
  line-height: 20px;
`;

export const PolicyAgreeCheckbox = styled.input`
  border: none;
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  margin: 4px 0 0;
  box-sizing: border-box;
  padding: 0;
  line-height: normal;
`;

export const PolicyAgreeCheckboxType = styled.span`
  font-weight: 500;
  color: ${props =>
    props.policyTypeText === '필수'
      ? `${theme.color.blue}`
      : `${theme.color.policyGrey}`};
`;

export const PolicyAgreeCheckboxIcon = styled.span`
  position: absolute;
  left: 0;
  top: 7px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  input[type='checkbox']:not(:checked) + span + & {
    background: url(/images/check-004.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
  input[type='checkbox']:checked + span + & {
    background: url(/images/check-004-cn1.png) no-repeat;
    background-size: 20px 20px;
    content: '';
  }
`;

export const PolicyAgreeShowWrapper = styled.a`
  display: block;
  padding: 0px;
  cursor: pointer;
  float: right;
  width: 58px;
  height: 17px;
  margin: 7px 0 0;
  background: url(/images/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;

  &:hover,
  &:active {
    text-decoration: none;
  }

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const PolicyAgreeShow = styled.span`
  display: block;
  float: left;
  width: 44px;
  height: 17px;
  text-align: center;
  line-height: 17px;
  color: ${theme.color.blue};
  font-size: 12px;
  font-weight: 500;
  border-radius: 5px;
  background: ${theme.backgroundColor.checkBlue};
  cursor: pointer;
`;
