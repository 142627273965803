import styled from 'styled-components';
import theme from 'styles/theme';
import { Header } from '../style';

export const BackPageTopbarHeader = styled(Header)`
  position: fixed;
  display: flex;
  justify-content: space-between;
`;

export const LeftLayoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80px;
  padding-left: 20px;
  cursor: pointer;
  @media screen and (max-width: 375px) {
    width: 60px;
    padding-left: 10px;
  }
`;

export const RightLayoutContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  cursor: pointer;
  width: 80px;
  padding-right: 20px;
  @media screen and (max-width: 375px) {
    width: 50px;
    padding-right: 20px;
  }
`;

export const HeaderH2 = styled.h1`
  padding: 3px 0;
  height: 60px;
  line-height: 5.7rem;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    letter-spacing: -0.2px;
    font-size: 1.6rem;
  }
`;
export const LoginButton = styled.button`
  display: block;
  float: right;
  width: 60px;
  height: 60px;
  font-size: 0;
  background: url(/images/fi-004b.png) center no-repeat;
  background-size: 18px 18px;
  opacity: 0.8;

  @media screen and (max-width: 375px) {
    width: 42px;
    height: 60px;
    background: url(/images/fi-004b.png) left no-repeat;
    background-size: 18px 18px;
  }
`;

export const HiddenSpan = styled.span`
  font-size: 0;
`;
