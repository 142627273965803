import React from 'react';
import DOMPurify from 'dompurify';
import CustomerServiceFooter from 'layouts/Footer/CustomerServiceFooter';
import TextField from 'components/TextField';
import BlueAlertBox from 'components/BlueAlertBox';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import FoldedCard from 'components/FoldedCard';
import LinkageGuideBox from 'pages/MyPage/DataLinkage/LinkageGuideBox';
import {
  CaptchaImage,
  TextFiledDescription,
} from 'pages/MallSecondAuthPage/style';
import { GreenQuestion } from 'pages/MyPage/DataLinkage/DataLinkageRequest/style';
import { Card, CardName } from 'components/FoldedCard/style';

const DataInterlockLinkInfo = ({
  redGuideContents = '',
  scrapingCompletedYn = '',
  secondAuthReleaseType = '',
  captchaData = {},
  onClickValidateMallAuth = () => {},
  faqList = [],
  mallName = '',
  mallMainId = '',
  onChangeTextField = () => {},
  mallMainPassword = '',
  secondAuthReleaseMessage = '',
  isChecked = false,
  onChangeCheckBox = () => {},
  onChangeCaptchaTextField = () => {},
  activateButton = () => {},
  onClickDetailBtn = () => {},
}) => {
  return (
    <>
      <TextField
        name="name"
        placeholder="이름"
        readOnly
        value={mallName || ''}
        style={{ margin: 0 }}
      />
      {redGuideContents && (
        <BlueAlertBox
          background="icyRed"
          title="주요 안내"
          contents={
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(redGuideContents),
              }}
            />
          }
        />
      )}

      <TextField
        name="mallMainId"
        placeholder="아이디"
        type="text"
        value={mallMainId || ''}
        onChange={onChangeTextField}
        readOnly={scrapingCompletedYn === 'Y'}
      />
      <TextField
        name="mallMainPassword"
        placeholder="비밀번호"
        type="password"
        value={mallMainPassword || ''}
        onChange={onChangeTextField}
      />

      {(secondAuthReleaseType === 'REQUIRED' ||
        secondAuthReleaseType === 'RECOMMENDED') && (
        <LinkageGuideBox
          secondAuthReleaseMessage={secondAuthReleaseMessage}
          secondAuthReleaseType={secondAuthReleaseType}
          isChecked={isChecked}
          onChangeCheckBox={onChangeCheckBox}
        />
      )}

      {captchaData?.captchaUrl && (
        <div>
          <TextFiledDescription>
            해당 판매몰의 경우 자동 등록 방지를 위한 캡차 인증이 진행 됩니다.
            <br />
            캡차 인증 후 판매몰의 방침에 따라 2차 인증 절차를 진행할 수
            있습니다.
          </TextFiledDescription>
          <CaptchaImage src={captchaData.captchaUrl} alt="captchaImage" />
          {captchaData?.captchaQuestion ? (
            <>
              <GreenQuestion>{captchaData.captchaQuestion}</GreenQuestion>
              <TextField
                name="captchaValue"
                placeholder="정답을 입력해 주세요."
                value={captchaData.captchaValue}
                onChange={onChangeCaptchaTextField}
              />
            </>
          ) : (
            <TextField
              name="captchaValue"
              placeholder="이미지의 문자와 숫자를 입력해 주세요."
              value={captchaData.captchaValue}
              onChange={onChangeCaptchaTextField}
            />
          )}
        </div>
      )}

      <StyledActiveButton
        disabled={activateButton()}
        onClick={onClickValidateMallAuth}
        margin="23px 0px 46px"
      >
        판매몰 계정 확인
      </StyledActiveButton>

      {faqList?.length > 0 && (
        <>
          <Card style={{ borderBottom: 'none', padding: '4px 0' }}>
            <CardName style={{ fontWeight: 500 }}>
              데이터 연동 시 자주 찾는 질문이에요.
            </CardName>
          </Card>
          {faqList.map((item, index) => {
            return (
              <FoldedCard
                key={`FoldedCard_${index}`}
                index={index}
                borderTop={index === 0}
                isOpen={item.isOpen}
                title={item.subject}
                contents={item.contents}
                onClickDetailBtn={onClickDetailBtn}
                style={{ padding: '8px 0' }}
                blackArrow={true}
              />
            );
          })}
        </>
      )}
      <CustomerServiceFooter
        hasOneToOne={true}
        hasInfo={false}
        margin="46px 0px 0px 0px"
      />
    </>
  );
};

export default DataInterlockLinkInfo;
