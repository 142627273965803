import React, { useEffect, useState } from 'react';
import { getDataRequest, getListDataRequest } from 'utils/request';
import { replaceSpecialCharacter } from 'tools/StringTool';
import { createMarkup } from 'tools/WebTool';
import TextField from 'components/TextField';
import BlueAlertBox from 'components/BlueAlertBox';
import FoldedCard from 'components/FoldedCard';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import LinkageGuideBox from 'pages/MyPage/DataLinkage/LinkageGuideBox';
import {
  CaptchaImage,
  TextFiledDescription,
} from 'pages/MallSecondAuthPage/style';
import { Card, CardName } from 'components/FoldedCard/style';
import { SelectOption, SelectProvider, GreenQuestion } from '../../../style';

const DataInterlockLinkInfo = ({
  dataInterlockInfo = {},
  setDataInterlockInfo,
  dataInterlockMallList = [],
  idPlaceholder = '아이디',
  pwPlaceholder = '비밀번호',
  isChecked = false,
  setIsChecked,
  captchaData = {},
  setCaptchaData,
  onClickActiveBtn = () => {},
  dataProviderSeq,
}) => {
  const {
    mallName = '',
    mallId = '',
    mallPassword = '',
    secondAuthReleaseType = '',
    secondAuthReleaseMessage = '',
    mallSeq = 0,
  } = dataInterlockInfo;

  const [guidanceContents, setGuidanceContents] = useState('');
  const [faqList, setFaqList] = useState([]);

  useEffect(() => {
    if (mallSeq && dataProviderSeq) {
      const params = {
        guidanceExposureLocationCode: 'INTERLOCK',
        mallSeq: mallSeq,
        dataProviderSeq: dataProviderSeq,
      };
      getDataRequest({
        url: `/v1/au/salesMall/guidance`,
        params,
        successFnc: data => {
          setGuidanceContents(data?.guidanceContents || '');
        },
      });
      // 판매몰의 자주묻는 질문
      getListDataRequest({
        url: '/v1/au/faq/interlock/list',
        params: {
          mallSeq,
        },
        successFnc: list => {
          setFaqList(list.map(item => ({ ...item, isOpen: false })));
        },
      });
    }
  }, [mallSeq, dataProviderSeq]);

  // 버튼 활성화
  const activateButton = () => {
    let isDisabled = true;
    if (!!mallId && !!mallPassword) {
      isDisabled = false;
      if (secondAuthReleaseType === 'REQUIRED') {
        isDisabled = !isChecked;
        if (captchaData?.errorCode) {
          isDisabled = !(isChecked && captchaData?.captchaValue);
        }
      }
    }

    return isDisabled;
  };

  const onChangeSelectProvider = event => {
    const { value } = event.target;
    !!setDataInterlockInfo &&
      setDataInterlockInfo({
        ...dataInterlockMallList.find(item => item.value === value),
        mallId: '',
        mallPassword: '',
      });
    // 선택되는 판매몰 변경될때 아래값 초기화
    setCaptchaData({});
    setIsChecked(false);
  };

  const onChangeTextField = event => {
    const { name, value } = event.target;
    !!setDataInterlockInfo &&
      setDataInterlockInfo(prev => ({ ...prev, [name]: value }));
  };

  const onChangeCaptchaTextField = event => {
    const value = replaceSpecialCharacter(event.target.value?.trim());
    setCaptchaData(prev => ({ ...prev, captchaValue: value }));
  };

  const onChangeCheckBox = event => {
    const { checked } = event.target;
    setIsChecked(checked);
    const secondAuthReleaseYn = checked === true ? 'Y' : 'N';
    setDataInterlockInfo(prev => ({ ...prev, secondAuthReleaseYn }));
  };

  const onClickDetailBtn = seq => {
    setFaqList(prev =>
      prev.map((item, index) =>
        index === seq
          ? { ...item, isOpen: !item.isOpen }
          : { ...item, isOpen: false }
      )
    );
  };

  return (
    <div>
      <SelectProvider onChange={onChangeSelectProvider}>
        {dataInterlockMallList?.map((item, index) => (
          <SelectOption
            key={`SelectOption_${item.value}_${index}`}
            value={item.value}
          >
            {item.label}
          </SelectOption>
        ))}
      </SelectProvider>

      {!!mallName && (
        <div>
          {!!guidanceContents && (
            <BlueAlertBox
              background="icyRed"
              title="주요 안내"
              contents={
                <div dangerouslySetInnerHTML={createMarkup(guidanceContents)} />
              }
            />
          )}
          <TextField
            name="mallId"
            placeholder={idPlaceholder}
            value={mallId}
            onChange={onChangeTextField}
            required
          />
          <TextField
            type="password"
            name="mallPassword"
            placeholder={pwPlaceholder}
            value={mallPassword}
            onChange={onChangeTextField}
            required
          />
          {(secondAuthReleaseType === 'REQUIRED' ||
            secondAuthReleaseType === 'RECOMMENDED') && (
            <LinkageGuideBox
              secondAuthReleaseMessage={secondAuthReleaseMessage}
              secondAuthReleaseType={secondAuthReleaseType}
              isChecked={isChecked}
              onChangeCheckBox={onChangeCheckBox}
            />
          )}

          {!!captchaData.captchaUrl && (
            <div>
              <TextFiledDescription>
                해당 판매몰의 경우 자동 등록 방지를 위한 캡차 인증이 진행
                됩니다.
                <br />
                캡차 인증 번호 입력 후 다시 한번 [판매몰 계정 확인] 버튼을
                눌러주세요.
              </TextFiledDescription>
              <CaptchaImage
                src={captchaData.captchaUrl || ''}
                alt="captchaAuth"
              />

              {!!captchaData.captchaQuestion ? (
                <>
                  <GreenQuestion>{captchaData.captchaQuestion}</GreenQuestion>
                  <TextField
                    name="captchaImageCode"
                    placeholder="정답을 입력해 주세요."
                    value={captchaData.captchaValue || ''}
                    onChange={onChangeCaptchaTextField}
                  />
                </>
              ) : (
                <TextField
                  name="captchaImageCode"
                  placeholder="이미지의 문자와 숫자를 입력해 주세요."
                  value={captchaData.captchaValue || ''}
                  onChange={onChangeCaptchaTextField}
                />
              )}
            </div>
          )}

          <StyledActiveButton
            onClick={onClickActiveBtn}
            margin="23px 0 46px"
            disabled={activateButton()}
          >
            판매몰 계정 확인
          </StyledActiveButton>

          {faqList?.length > 0 && (
            <>
              <Card style={{ borderBottom: 'none', padding: '4px 0' }}>
                <CardName style={{ fontWeight: 500 }}>
                  데이터 연동 시 자주 찾는 질문이에요.
                </CardName>
              </Card>
              {faqList.map((item, index) => {
                return (
                  <FoldedCard
                    key={`FoldedCard_${index}`}
                    index={index}
                    borderTop={index === 0}
                    isOpen={item.isOpen}
                    title={item.subject}
                    contents={item.contents}
                    onClickDetailBtn={onClickDetailBtn}
                    style={{ padding: '8px 0' }}
                    blackArrow={true}
                  />
                );
              })}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DataInterlockLinkInfo;
