export const theme = {
  // layout
  layout: {
    minWidth: '280px',
    maxWidth: '486px',
    topBanner: '40px',
  },

  // font size
  fontSize: {
    h1: '3.2rem', // title
    h2: '2.4rem', // subTitle
    h3: '2rem', // textLarge
    h4: '1.6rem', // textMedium
    h5: '1.3rem', // textSmall
    h6: '1.1rem', // textXSmall
  },

  // font weight
  fontWeight: {
    bold: 800,
    normal: 500, // 기존 400에서 퍼블에서 가장 많이 쓰인 500으로 변경했습니다.
    light: 200,
  },

  // color, font color
  color: {
    black: '#111',
    white: '#fff',
    red: '#F61F1D',
    placeholder: '#8EAFC7',
    blue: '#02A4FF', // 메인 블루
    aizenBlue: '#00a3ff',
    infoBlue: '#009FF9',
    button: '#00a4ff',
    buttonLink: '#009FF9',
    label: '#0092e4',
    navyBlue: '#0060a4',
    checkBlue: '#39adff',
    checkGreen: '#6ec438', // #169F00 대체
    green: '#169F00',
    lightGreen: '#29CC6A',
    purple: '#7B61FF',
    policyGrey: '#969696',
    lightGrey: '#d9d9d9',
    greyA: '#aaa',
    grey3: '#333',
    grey4: '#444',
    grey5: '#555',
    grey6: '#666',
    grey7: '#777',
    grey9: '#999',
    grey11: '#F7F7F7',
    textShadow: 'rgba(0, 0, 0, 0.25)',
  },

  // background color
  backgroundColor: {
    white: '#fff',
    form: '#e9f0f5',
    input: '#eaeaea', // #e0e0e0 대체
    contentBox: '#f5f5f5',
    contentBox2: '#f1f6f9',
    table: '#f4f4f4', // #f1f6f9 대체
    footerBox: '#f1f1f1',
    buttonLightGrey: '#D9D9D9',
    labelToggleGrey: '#C0C0C0',
    buttonGrey: '#B9CDD9',
    blue: '#02A4FF', // #20ABFA, #02A3FF 모두 통용해서 쓰겠습니다
    scrollIcyBlue: '#CAECFF',
    icyBlue: '#e4f4ff', // #D9F1FF 대체
    lightIcyBlye: '#EEF9FF',
    checkGreen: '#CBFFCD',
    checkBlue: '#d9f1ff',
    red: '#f7cdcd',
    icyRed: '#FFE8E9',
    purple: '#e5d9ff',
    lightGrey: '#d0d0d0',
    greyA: '#aaa',
    grey3: '#333',
    grey5: '#555',
    grey7: '#777',
    grey73: '#737171',
    grey8: '#FBFAFA',
    shadow: 'rgba(0, 0, 0, 0.5)',
    black: '#000',
    kakaoTalk: '#F6E24B',
  },

  // border color
  borderColor: {
    white: '#fff',
    ivory: '#e2e2e2',
    footer: '#c0c0c0',
    contentBox: '#f5f5f5',
    lightGrey: '#d0d0d0',
    buttonGrey: '#B9CDD9',
    greyA: '#aaa',
    grey4: '#444',
    grey5: '#555',
    blue: '#02A4FF', // 메인 블루
    buttonLink: '#009ff9',
  },
};

export default theme;
