import { useState, useEffect } from 'react';

const useInfiniteScroll = (
  initialList = [],
  getList = page => {},
  paginationData = { articleCount: 15, totalCount: 0, pageNo: 0 },
  inView = false
) => {
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  // 검색
  const onClickSearchButton = () => {
    setPage(1);
    getList(1);
  };

  // 목록 조회
  useEffect(() => {
    if (inView && hasMore) {
      setTimeout(() => getList(page), 500); // 스크롤시 데이터 즉각적으로 보이는것보다 약간의 시차가 발생하는게 더 자연스러움
    }
  }, [inView, hasMore]);

  useEffect(() => {
    const list = initialList;
    const paging = paginationData;

    // 1. 리스트 데이터가 없을 때
    if (list.length === 0) {
      setHasMore(false);
      setList([]);
      // 2. 리스트 데이터가 있을 때
    } else {
      // 현재 페이지가 1이고 마지막 페이지도 1일 때
      if (paging?.endPageNo === 1) {
        setHasMore(false);
        setList(list);
        // 현재 페이지가 1이고 마지막 페이지가 1이 아닐 때
      } else if (paging?.pageNo === 1 && paging?.endPageNo !== 1) {
        setHasMore(true);
        setList(list);
        setPage(prev => prev + 1);
        // 현재 페이지가 1이 아니고 마지막 페이지보다 현재 페이지가 적을 때
      } else if (paging?.pageNo !== 1 && paging?.pageNo < paging?.endPageNo) {
        setHasMore(true);
        setList(prev => [...prev, ...list]);
        setPage(prev => prev + 1);
        // 현재 페이지가 1이 아니고 마지막 페이지랑 현재 페이지랑 같을 때
      } else if (paging?.pageNo !== 1 && paging?.pageNo === paging?.endPageNo) {
        setHasMore(false);
        setList(prev => [...prev, ...list]);
      }
    }
  }, [initialList, paginationData]);

  return { list, onClickSearchButton };
};

export default useInfiniteScroll;
