import React from 'react';
import SelectBox from 'components/SelectBox';
import { Header, Filter, TotalCount } from '../style';
import { TextBold } from '../../style';

const CardHeader = ({
  mallCount,
  mallListInFilter,
  dataProviderListInFilter,
  mallSeq,
  dataProviderSeq,
  onClickSelect,
  onChangeSelect,
}) => {
  return (
    <Header>
      <TotalCount>
        총<TextBold style={{ marginLeft: '2px' }}>{mallCount}</TextBold>개
      </TotalCount>
      <Filter>
        {dataProviderListInFilter?.length > 2 && (
          <SelectBox
            onClickSelect={onClickSelect}
            optionHandleChange={onChangeSelect}
            optionList={dataProviderListInFilter}
            name="dataProvider"
            value={dataProviderSeq}
            isNotDisabled={true}
            noPadding={true}
            $gray
            style={{
              width: '160px',
              padding: '0 22px 0 0',
              fontSize: '1.3rem',
            }}
          />
        )}
        {mallListInFilter?.length > 0 && (
          <SelectBox
            onClickSelect={onClickSelect}
            optionHandleChange={onChangeSelect}
            optionList={mallListInFilter}
            name="mall"
            value={mallSeq}
            isNotDisabled={true}
            noPadding={true}
            $gray
            style={{
              padding: '0 22px 0 3px',
              fontSize: '1.3rem',
            }}
          />
        )}
      </Filter>
    </Header>
  );
};

export default CardHeader;
