import styled from 'styled-components';
import theme from 'styles/theme';

export const ContentSubpage = styled.div`
  position: relative;
  width: 100%;
  background: ${theme.backgroundColor.white};
`;

export const TextMedium = styled.div`
  width: 100%;
  padding: 41px 0 18px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  text-align: center;
  color: ${theme.color.black};
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 13px 19px;
  margin: 23px 0 0;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox};
`;

export const ContentTitle = styled.div`
  width: 100%;
  margin: 19px 0 0;
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.navyBlue};

  &:first-child {
    margin: 0;
  }
`;

export const ContentData = styled.div`
  width: 100%;
  margin: 3px 0 0;
`;

export const ContentText = styled.div`
  width: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  line-height: 2.1rem;
  color: ${theme.color.black};
`;

export const ContentBlueText = styled(ContentText)`
  color: ${theme.color.blue};
`;

export const ButtonSection = styled.div`
  display: flex;
  width: 100%;
`;
