import React, { useEffect, useState } from 'react';
import { OneBtnModal, FilterModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import SelectManager from 'components/SelectManager';

const SelectManagerModal = ({
  validManagerList = [],
  showSelectManagerModal,
  setShowSelectManagerModal,
  onClickSelectManager,
  bankName,
  setSelectedManager,
  showFilterModal,
  setShowFilterModal,
  onClickFinishSelect,
  filterModalAlert,
  filterModalMessage,
}) => {
  const [checkStatusList, setCheckStatusList] = useState([]);

  useEffect(() => {
    setCheckStatusList(
      validManagerList.map(item => {
        return {
          businessManagerSeq: item.businessManagerSeq,
          managerName: item.managerName,
          mobilePhone: item.mobilePhone,
          department: item.department,
          position: item.position,
          isChecked: false,
        };
      })
    );
  }, [validManagerList]);

  return (
    <OneBtnModal
      showModal={showSelectManagerModal}
      onClose={() => setShowSelectManagerModal(false)}
      onClick={onClickSelectManager}
      btnContent="담당자 선택"
      border={showFilterModal}
    >
      <ScrollArea>
        <Contents>
          <SelectManager
            bankName={bankName}
            managerList={validManagerList}
            setSelectedManager={setSelectedManager}
            checkStatusList={checkStatusList}
            setCheckStatusList={setCheckStatusList}
          />
        </Contents>
      </ScrollArea>
      <FilterModal
        showModal={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        onClick={() => onClickFinishSelect('selectManager')}
        alert={filterModalAlert}
        leftButton="다시 선택"
        rightButton="선택 완료"
      >
        <ScrollArea>
          <Contents>{filterModalMessage}</Contents>
        </ScrollArea>
      </FilterModal>
    </OneBtnModal>
  );
};

export default SelectManagerModal;
