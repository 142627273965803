import React, { useEffect } from 'react';
import {
  ModalWrapper,
  ModalHeader,
  Title,
  ModalClose,
  SideArea,
  ModalBtnWrap,
  ModalOneBtn,
  Wrapper,
} from '../style';

const OneBtnModal = ({
  title,
  showModal,
  onClose,
  onClick,
  btnContent,
  children,
  border,
}) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    showModal && (
      <Wrapper>
        <ModalWrapper border={!!border}>
          <ModalHeader>
            <Title>{title}</Title>
            <ModalClose onClick={onClose} />
          </ModalHeader>
          {children}
          <ModalBtnWrap>
            <ModalOneBtn onClick={onClick ? onClick : onClose}>
              {btnContent}
            </ModalOneBtn>
          </ModalBtnWrap>
        </ModalWrapper>
        <SideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default OneBtnModal;
