import React, { useEffect } from 'react';
import {
  ModalWrapper,
  ModalHeader,
  Title,
  ModalClose,
  SideArea,
  Wrapper,
} from '../style';

const NoBtnModal = ({ title, showModal, onClose, children }) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    !!showModal && (
      <Wrapper>
        <ModalWrapper>
          <ModalHeader>
            <Title>{title}</Title>
            <ModalClose onClick={onClose} />
          </ModalHeader>
          {children}
        </ModalWrapper>
        <SideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default NoBtnModal;
