import styled from 'styled-components';
import theme from 'styles/theme';

export const DeepLinkBanner = styled.div`
  position: fixed;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  width: 100%;
  height: 40px;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};

  text-align: center;
  background: lightskyblue;
`;

export const ModalClose = styled.button`
  display: block;
  position: absolute;
  z-index: 3001;
  width: 50px;
  height: 40px;
  top: 0;
  right: 0;

  font-size: 0;
  background: url('/images/close-26-r-fff.png') 14px 13px no-repeat;
  background-size: 12px 12px;
`;

export const Span = styled.span`
  font-size: 1.2rem;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    cursor: pointer;
  }
`;
