import styled from 'styled-components';
import theme from 'styles/theme';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import StyledLinkButton from 'components/Button/StyledLinkButton';

export const SearchBoxSection = styled.div`
  width: 100%;
`;

export const SearchRow = styled.div`
  display: flex;
  padding: 0 0 17px;
  width: 100%;
`;

export const InputNormal = styled.input`
  width: 100%;
  height: 45px;

  margin-right: 10px;
  padding: 0 14px;
  line-height: 4.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  vertical-align: middle;
  border-radius: 10px;
  background: ${theme.backgroundColor.form};
  color: ${theme.color.black};

  &:focus {
    border: 1px solid ${theme.borderColor.blue};
  }

  ::placeholder {
    color: ${theme.color.placeholder};
  }

  // search cancel 버튼 커스텀 할 경우
  ::-webkit-search-cancel-button {
    //-webkit-appearance: none;
    //width: 14px;
    //height: 14px;
    //background: url('/images/close-26-r-b21.png') center center no-repeat;
    //cursor: pointer;
  }

  @media screen and (max-width: 320px) {
    ::placeholder {
      font-size: 1rem;
    }
  }
`;

export const SearchBtn = styled(StyledActiveButton)`
  float: right;
  position: relative;
  margin: 0px;
  width: 62px;

  // @media screen and (max-width: 320px) {
  //   width: 62px;
  // }
`;

export const ResetBtn = styled(StyledLinkButton)`
  float: right;
  position: relative;
  margin: 0px;
  width: 62px;
`;

export const SearchBtnText = styled.span`
  position: absolute;
  top: 14px;
  left: 17px;
  width: 17px;
  height: 17px;
  background: url(/images/search-14-fff.png) center no-repeat;
  background-size: 17px 17px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 320px) {
    top: 14px;
    left: 15px;
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
  }
`;

export const ResetBtnText = styled.span`
  position: absolute;
  top: 14px;
  left: 17px;
  width: 17px;
  height: 17px;
  background: url(/images/reload31.png) center no-repeat;
  background-size: 17px 17px;

  &:hover {
    cursor: pointer;
  }
`;
