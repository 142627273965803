import { useEffect, useState } from 'react';
import { getCookie } from 'tools/WebTool';
import { LoadingBar } from 'components/LoadingIndicator';
import TextField from 'components/TextField';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { InputMessage } from 'components/TextField/style';
import { alertMessages } from 'messages/alertMessages';
import {
  ContentSubpage,
  ContentTopLogo,
  SaveIdSection,
  CheckboxLabel,
  Checkbox,
  CheckboxText,
} from './style';

const LoginBox = ({
  login,
  setLoginInfo,
  loginInfo,
  incorrectInputMessage,
  setIncorrectInputMessage,
  isCheckedSaveId,
  setIsCheckedSaveID,
  isLoading,
}) => {
  const [idMessage, setIdMessage] = useState({ status: false, message: '' });
  const [passwordMessage, setPasswordMessage] = useState({
    status: false,
    message: '',
  });

  const onChangeData = e => {
    const { value, name } = e.target;
    setLoginInfo(prevState => ({
      ...prevState,
      [name]: value.trim(),
    }));
    setIncorrectInputMessage('');
    if (name === 'memberId') {
      setIdMessage({ status: false, message: '' });
    } else if (name === 'password') {
      setPasswordMessage({ status: false, message: '' });
    }
  };

  const onKeyDown = e => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const onClickLoginButton = () => {
    if (isLoading === false) {
      login();
    }
  };

  const onIdBlur = () => {
    loginInfo.memberId
      ? setIdMessage({
          status: false,
          message: '',
        })
      : setIdMessage({
          status: true,
          message: alertMessages.typeId,
        });
  };

  const onPasswordBlur = () => {
    loginInfo.password
      ? setPasswordMessage({
          status: false,
          message: '',
        })
      : setPasswordMessage({
          status: true,
          message: alertMessages.typePw,
        });
  };

  const onChangeCheckbox = event => {
    setIsCheckedSaveID(event.target.checked);
  };

  useEffect(() => {
    const idCookie = getCookie('userId');
    idCookie &&
      setLoginInfo(prevState => ({
        ...prevState,
        memberId: idCookie,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentSubpage>
      <ContentTopLogo
        src="images/top-logo-new.png"
        alt="Seller CreditConnect"
      />
      <TextField
        type="text"
        title="아이디 입력"
        name="memberId"
        placeholder="아이디"
        onChange={onChangeData}
        onBlur={onIdBlur}
        onKeyDown={onKeyDown}
        showMessage={idMessage.status}
        message={idMessage.message}
        value={loginInfo.memberId}
      />

      <TextField
        type="password"
        title="비밀번호 입력"
        name="password"
        placeholder="비밀번호"
        onChange={onChangeData}
        onBlur={onPasswordBlur}
        onKeyDown={onKeyDown}
        showMessage={passwordMessage.status}
        message={passwordMessage.message}
        value={loginInfo.password}
      />

      {incorrectInputMessage && (
        <InputMessage>{incorrectInputMessage}</InputMessage>
      )}
      <StyledActiveButton
        id="loginButton"
        tabIndex={0}
        disabled={!loginInfo.memberId || !loginInfo.password}
        onClick={onClickLoginButton}
      >
        {isLoading === false ? '로그인' : <LoadingBar message={true} />}
      </StyledActiveButton>

      <SaveIdSection>
        <CheckboxLabel>
          <Checkbox
            type="checkbox"
            onChange={onChangeCheckbox}
            checked={isCheckedSaveId}
          />
          <CheckboxText>아이디 저장</CheckboxText>
        </CheckboxLabel>
      </SaveIdSection>
    </ContentSubpage>
  );
};

export default LoginBox;
