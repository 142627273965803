import styled from 'styled-components';
import theme from 'styles/theme';
import {
  BADGE_BACKGROUND_COLOR,
  BADGE_COLOR,
} from 'pages/MyPage/LoanLimit/LoanProductsListPage/constants';

export const ColoredStatusSpan = styled.span`
  display: block;
  padding: 0 5px;
  text-align: center;
  line-height: 1.8rem;
  font-size: 1.2rem;
  font-weight: 500;

  border-radius: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;

  background: ${theme.backgroundColor.checkBlue};
  color: ${theme.color.blue};

  @media screen and (max-width: 375px) {
    line-height: 1.6rem;
    font-size: 1rem;
  }

  ${props => {
    if (props.badge) {
      const background =
        theme.backgroundColor[BADGE_BACKGROUND_COLOR[props.badge]] ||
        theme.backgroundColor.checkBlue;
      const color = theme.color[BADGE_COLOR[props.badge]] || theme.color.blue;
      return `
        background: ${background};
        color: ${color};
      `;
    }
  }}
`;
