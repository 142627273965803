import React, { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import IdentificationBox from 'components/IdentificationBox';
import { NoBtnModal } from 'components/Modal';
import { customerService } from 'messages/contactInfo';
import { ScrollArea, Contents } from 'components/Modal/style';
import {
  ContentSubpage,
  TextLarge,
  TextMedium,
  TextBlue,
  ContentBox,
  ContentTitle,
  ContentData,
  ContentText,
  ChildText,
  ChildTextContent,
} from '../../SearchId/SearchIdIdentification/style';

const SearchPasswordIdentification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bizData = location.state;
  const { login } = ROUTE_PATH;

  // 오류 모달 alertMessage
  const [alertMessage, setAlertMessage] = useState('');

  // 본인인증 완료(성공)
  const identificationSuccessCallback = useCallback(
    memberCertificationSeq => {
      const bizInfo = {
        businessRegistrationNumber: bizData?.bizNum,
        memberCertificationSeq: memberCertificationSeq,
      };
      checkMemberCertification({
        bizInfo,
        setAlertMessage,
        login,
        navigate,
      });
    },
    [bizData, login, navigate]
  );

  // 본인인증 완료(decode 실패)
  const identificationExceptCallback = useCallback(err => {
    console.error(err);
    const { data } = err.response;
    setAlertMessage(data.message);
  }, []);

  return (
    <ContentSubpage>
      {bizData ? (
        <>
          <TextLarge>
            <TextBlue>{bizData && bizData.representativeName}</TextBlue>님
          </TextLarge>
          <TextMedium>
            휴대폰으로 본인인증 하면,
            <br />
            비밀번호 찾기가 완료돼요.
          </TextMedium>
          <ContentBox>
            <ContentTitle>상호명</ContentTitle>
            <ContentData>
              <ContentText>{bizData && bizData.bizName}</ContentText>
            </ContentData>
            <ContentTitle>대표자</ContentTitle>
            <ContentData>
              <ContentText>{bizData && bizData.representativeName}</ContentText>
            </ContentData>
          </ContentBox>
          <ChildText>
            <ChildTextContent>
              대표자가 변경되었다면, 고객센터
              <TextBlue> {customerService.phoneContact} </TextBlue>로
              연락주세요.
            </ChildTextContent>
          </ChildText>
          <IdentificationBox
            identificationPurposeType="MBR_PW_RE_CONFIG"
            identificationSuccessCallback={identificationSuccessCallback}
            identificationExceptCallback={identificationExceptCallback}
          />
        </>
      ) : null}
      {alertMessage && (
        <NoBtnModal
          showModal={alertMessage}
          onClose={() => setAlertMessage('')}
        >
          <ScrollArea>
            <Contents>{alertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      )}
    </ContentSubpage>
  );
};

// 본인확인
const checkMemberCertification = ({
  bizInfo,
  setAlertMessage,
  login,
  navigate,
}) => {
  api({
    url: `/v1/na/member/id/check?` + qs.stringify({ ...bizInfo }),
    method: 'get',
    headers: { 'content-type': 'application/json' },
  })
    .then(res => {
      const { success, message, data } = res.data;
      if (success) {
        const bizData = {
          bizName: data.businessName,
          representativeName: data.representativeName,
          memberId: data.memberId,
          memberCertificationSeq: bizInfo.memberCertificationSeq,
        };
        navigate(login.searchPasswordResetPassword, {
          state: bizData,
        });
      } else {
        setAlertMessage(message);
      }
    })
    .catch(err => {
      console.error(err);
      const { data } = err.response;
      setAlertMessage(data.message);
    });
};

export default SearchPasswordIdentification;
