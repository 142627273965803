import styled from 'styled-components';
import theme from 'styles/theme';

export const SearchBoxWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 30px 0 0;
  margin-bottom: 13px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const QnAListWrap = styled.div`
  display: block;
  width: 100%;
  border-top: 1px solid ${theme.borderColor.grey4};
`;

export const NoListTextTitle = styled.div`
  display: block;
  width: 100%;
  padding: 123px 0 100px;

  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2.4rem;

  border-top: 1px solid black;
  color: ${theme.color.black};
`;

export const QnACard = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 18px 0;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const QnACardColumn = styled.div`
  position: relative;
  float: right;
  width: 100%;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const QnADetailTitle = styled.div`
  display: block;
  margin: 7px 0 14px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 400;
  color: ${theme.color.black};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const QnATitle = styled(QnADetailTitle)`
  margin: 7px 7px 0 0;
  padding: 0 0 10px;
  white-space: nowrap;
`;

export const QnACardStatus = styled.button`
  display: block;
  height: 20px;
  white-space: nowrap;
  padding: 3px 12px 1px;
  margin-right: 5px;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 5px;
  cursor: default;
  color: ${props => (props.color ? props.color : `${theme.color.grey7}`)};
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : `${theme.backgroundColor.contentBox}`}};
`;

export const QnACardRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const QnACardRowDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

export const QnADiv = styled.div`
  display: flex;
  align-items: center;
`;

export const QnAImg = styled.img`
  width: 25%;
  padding: 0 1px;
  border-radius: 5px;
`;

export const QnACardRowSecond = styled.div`
  float: left;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  padding: 2px 0 0 0;
  color: ${props => (props.color ? props.color : theme.color.black)};
`;

export const OpenButton = styled.button`
  width: 20px;
  height: 20px;
`;

export const CloseButton = styled.button`
  width: 20px;
  height: 20px;
`;

export const DeleteButton = styled.button`
  display: block;
  height: 18px;
  padding: 2px 16px;
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 5px;
  color: ${theme.color.white};
  background: ${theme.backgroundColor.greyA};
`;

export const ContentBox1 = styled.div`
  display: block;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 20px 16px;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox};
`;

export const ContentBox2 = styled.div`
  display: block;
  width: 100%;
  margin-top: 10px;
  padding: 20px 16px;
  border-radius: 10px;
  background: ${theme.backgroundColor.contentBox2};
`;

export const ContentRow = styled.div`
  display: block;
  width: 100%;
  padding: 0 0 12px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ContentRowTitle = styled.div`
  float: left;
  width: auto;
  margin-right: 10px;
  font-size: 1.4rem;
  line-height: 2.1rem;
  font-weight: 700;
  color: ${theme.color.black};
`;

export const ContentData = styled.div`
  display: block;
  width: 100%;
  margin: 3px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ContentDataText = styled.div`
  float: left;
  display: block;
  line-height: 2.1rem;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  color: ${theme.color.black};
`;
