import React, { useEffect, useState } from 'react';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import { TwoBtnModal, NoBtnModal, FilterModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import DataTable from 'components/Table/DataTable';
import SelectManagerModal from './SelectManagerModal';
import {
  LoanCard,
  LoanCardColumn,
  LoanStatus,
  LoanBankName,
  LoanCardRow,
  LoanCardRowFirst,
  LoanCardRowSecond,
  DetailButton,
  LoanCardRowDate,
  DetailModalWrap,
  DetailModalTitle,
  DetailModalTitleTable,
  DetailModalData,
  DetailModalText,
  ButtonWrap,
  ToolTip,
} from './style';

// table header
const tableHead = ['날짜', '상태'];
const LoanProductsList = ({
  item,
  financeInstitutionList,
  validManagerList,
  locationState = {},
}) => {
  const { auth } = useApp();
  const representativeYn = auth?.memberRoleData?.representativeYn;
  const [viewDetailModal, setViewDetailModal] = useState(false);
  const [creditRequestDetailData, setCreditRequestDetailData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [alertModal, setAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [toolTipModal, setToolTipModal] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  // 담당자 변경에 필요한 상태값들
  const [showSelectManagerModal, setShowSelectManagerModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState({});
  const [filterModalMessage, setFilterModalMessage] = useState('');
  const [filterModalAlert, setFilterModalAlert] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [backendAlertModal, setBackendAlertModal] = useState(false);
  const [backendAlertMessage, setBackendAlertMessage] = useState('');
  const [locationReload, setLocationReload] = useState(false);

  const [financeInstitutionSeq, setfinanceInstitutionSeq] = useState(0);
  // 도이치파이낸셜의 seq 는 10003 이다.
  const isFinanceInstDeutsch = financeInstitutionSeq === 10003;

  const {
    creditRequestSeq,
    creditStatus,
    creditStatusName,
    financeInstitutionName,
    managerName,
    mallList,
    requestCompletionDt,
    position,
    department,
  } = item;

  const isDisabledChangeManager =
    creditStatus !== 'REQ' ||
    isFinanceInstDeutsch ||
    creditRequestDetailData.dataUseType === 'CREDIT_LOAN_CMPRS' ||
    representativeYn === 'N';

  // 회원 탈퇴 프로세스로 진입한 케이스
  const isMemberWithdrawalProcess =
    locationState?.parentPage === 'memberWithdrawalPage' &&
    locationState?.creditRequestSeq === creditRequestSeq;

  useEffect(() => {
    setfinanceInstitutionSeq(
      financeInstitutionList?.find(
        item => item.label === financeInstitutionName
      ).value || 0
    );
  }, [financeInstitutionList]);

  useEffect(() => {
    if (isMemberWithdrawalProcess) {
      onClickShowDetailModal(locationState?.creditRequestSeq);
    }
  }, []);

  // alert modal 띄우기
  const showAlertModal = () => {
    setAlertModal(!alertModal);
  };

  const showManagerChangeModal = () => {
    setShowSelectManagerModal(!showSelectManagerModal);
    setViewDetailModal(!viewDetailModal);
  };

  const showToolTipModal = () => {
    setToolTipModal(!toolTipModal);
  };

  // 상세보기 Modal창 관리 함수
  const onClickShowDetailModal = creditRequestSeq => {
    setViewDetailModal(!viewDetailModal);
    getCreditRequestDetail(creditRequestSeq);
  };

  const onCloseShowDetailModal = () => {
    setViewDetailModal(!viewDetailModal);
    if (isMemberWithdrawalProcess) {
      // location.state 초기화
      window.history.replaceState({}, '');
    }
  };

  // 대출 신청 취소 버튼 클릭시
  const onClickBtnCancelLoan = () => {
    setShowConfirmModal(true);
  };

  const onClickBtnCancelLoanFinalRequest = () => {
    onCloseShowDetailModal();
    putDeleteRequestBtn();
  };

  // 담당자 변경 버튼 클릭시
  const onClickChangeManager = () => {
    showManagerChangeModal();
  };

  const onClickSelectManager = () => {
    if (selectedManager && selectedManager.businessManagerSeq) {
      const managerName = selectedManager.managerName;
      setFilterModalMessage(`${managerName}님을 대출 담당자로 선택하시겠어요?`);
      setFilterModalAlert(false);
    } else {
      setFilterModalMessage('담당자를 선택해주세요.');
      setFilterModalAlert(true);
    }
    return setShowFilterModal(true);
  };

  // 확인용 모달에서 선택 완료 버튼 클릭시
  const onClickFinishSelect = selectType => {
    if (selectType === 'selectMall') {
      setShowFilterModal(false);
    }
    if (selectType === 'selectManager') {
      changeManager();
      setShowSelectManagerModal(false);
      setViewDetailModal(!viewDetailModal);
    }
  };

  // 모달 상세 & 테이블 목록 가져오기
  const getCreditRequestDetail = creditRequestSeq => {
    api({
      url: `/v1/au/credit-request/${creditRequestSeq}`,
      method: 'get',
    })
      .then(res => {
        const { data } = res.data;
        setCreditRequestDetailData(data?.result || []);
        if (data.hisList) {
          setTableData([
            ...data.hisList.map(item => ({
              historyDate: `${item.historyDt.slice(
                0,
                2
              )}년 ${item.historyDt.slice(3, 5)}월 ${item.historyDt.slice(
                6,
                8
              )}일`,
              creditStatus: item.creditStatus,
            })),
          ]);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  // 대출신청취소 api
  const putDeleteRequestBtn = () => {
    api({
      url: `/v1/au/credit-request/${creditRequestSeq}`,
      method: 'delete',
      headers: { 'content-type': 'application/json' },
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          setBackendAlertModal(true);
          setBackendAlertMessage(
            `대출 신청 취소가 완료되었어요.\n 대출 신청을 다시 진행해 주세요.`
          );
          setShowConfirmModal(false);
          setLocationReload(true);
        } else {
          setBackendAlertModal(true);
          setBackendAlertMessage(message);
          showAlertModal(!alertModal);
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setBackendAlertModal(true);
        setBackendAlertMessage(data.message);
      })
      .finally(() => {
        if (isMemberWithdrawalProcess) {
          // location.state 초기화
          window.history.replaceState({}, '');
        }
      });
  };

  // 직원 변경 api
  const changeManager = () => {
    api({
      url: `/v1/au/credit-request/manager/${creditRequestSeq}`,
      method: 'put',
      headers: { 'content-type': 'application/json' },
      data: {
        businessManagerSeq: selectedManager.businessManagerSeq,
        managerName: selectedManager.managerName,
        managerContact: selectedManager.mobilePhone,
      },
    })
      .then(res => {
        const { success, message } = res.data;
        if (success) {
          setBackendAlertModal(true);
          setBackendAlertMessage('담당자를 변경했어요.');
          showAlertModal(!alertModal);
          // setTimeout(() => window.location.reload(), 1000);
          setLocationReload(true);
        } else {
          setBackendAlertModal(true);
          setBackendAlertMessage(message);
          showAlertModal(!alertModal);
        }
      })
      .catch(err => {
        console.error(err);
        const { data } = err.response;
        setBackendAlertModal(true);
        setBackendAlertMessage(data.message);
        showAlertModal(true);
      });
  };
  const onCloseBackendAlertModal = () => {
    if (locationReload) {
      window.location.reload();
    } else {
      setBackendAlertModal(false);
      setShowConfirmModal(false);
    }
    setLocationReload(false);
  };

  return (
    <LoanCard>
      <LoanCardColumn>
        {creditStatusName === '기한만료' ? (
          <ToolTip onClick={showToolTipModal} alert />
        ) : (
          ''
        )}
        <LoanStatus>{creditStatusName}</LoanStatus>
        <LoanBankName>{financeInstitutionName}</LoanBankName>
        <LoanCardRow>
          <LoanCardRowFirst>신청번호</LoanCardRowFirst>
          <LoanCardRowSecond>
            {creditRequestSeq}&nbsp;&nbsp;&nbsp;&nbsp;
            <LoanCardRowDate>/&nbsp;&nbsp;&nbsp;&nbsp;신청일</LoanCardRowDate>
            &nbsp;&nbsp;&nbsp;&nbsp;{requestCompletionDt}
          </LoanCardRowSecond>
        </LoanCardRow>
        <LoanCardRow>
          <LoanCardRowFirst>판매몰</LoanCardRowFirst>
          <LoanCardRowSecond>{mallList}</LoanCardRowSecond>
        </LoanCardRow>
        <LoanCardRow>
          <LoanCardRowFirst>담당 직원</LoanCardRowFirst>
          <LoanCardRowSecond>
            {managerName} {department} {position}
          </LoanCardRowSecond>
        </LoanCardRow>
        <DetailButton
          onClick={() => onClickShowDetailModal(creditRequestSeq)}
        />

        {/* 대출 상세 모달 */}
        <NoBtnModal
          title={`[${creditStatusName}] ${financeInstitutionName}`}
          showModal={viewDetailModal}
          onClose={onCloseShowDetailModal}
        >
          <ScrollArea title="title">
            <Contents>
              <ButtonWrap>
                <StyledActiveButton
                  onClick={onClickChangeManager}
                  disabled={isDisabledChangeManager}
                >
                  담당자 변경
                </StyledActiveButton>
                <StyledActiveButton
                  halfButton="halfButton"
                  onClick={onClickBtnCancelLoan}
                  disabled={creditStatus !== 'REQ'}
                >
                  대출 신청 취소
                </StyledActiveButton>
              </ButtonWrap>
              <DetailModalWrap>
                <DetailModalTitle>신청번호</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {creditRequestDetailData.creditRequestSeq}
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitle>대출 신청</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {creditRequestDetailData.creditGoodsName}
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitle>담당 직원</DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {creditRequestDetailData.managerName}
                    {creditRequestDetailData.department && (
                      <> / {creditRequestDetailData.department}</>
                    )}
                    {creditRequestDetailData.position && (
                      <> / {creditRequestDetailData.position}</>
                    )}
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitle>
                  판매몰({creditRequestDetailData.mallCount})
                </DetailModalTitle>
                <DetailModalData>
                  <DetailModalText>
                    {creditRequestDetailData.mallList}
                  </DetailModalText>
                </DetailModalData>
                <DetailModalTitleTable>대출상태</DetailModalTitleTable>
                <DataTable
                  tableName="CreditRequest"
                  tableHead={tableHead}
                  tableData={tableData}
                />
              </DetailModalWrap>
            </Contents>
          </ScrollArea>
          <FilterModal showModal={alertModal} onClose={showAlertModal} alert>
            <ScrollArea>
              <Contents>{alertMessage}</Contents>
            </ScrollArea>
          </FilterModal>
        </NoBtnModal>
        {/* TODO - 개선사항: 로딩 추가하기 */}
        {/* <LoadingIndicator /> */}

        {/* 매니저 선택 모달 */}
        <SelectManagerModal
          showSelectManagerModal={showSelectManagerModal}
          setShowSelectManagerModal={setShowSelectManagerModal}
          onClickSelectManager={onClickSelectManager}
          bankName={financeInstitutionName}
          validManagerList={validManagerList}
          setSelectedManager={setSelectedManager}
          onClickFinishSelect={onClickFinishSelect}
          showFilterModal={showFilterModal}
          setShowFilterModal={setShowFilterModal}
          filterModalAlert={filterModalAlert}
          filterModalMessage={filterModalMessage}
        />

        {/* 대출 신청 취소 확인 모달  */}
        <TwoBtnModal
          showModal={showConfirmModal}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          onReset={() => {
            setShowConfirmModal(false);
          }}
          onClick={onClickBtnCancelLoanFinalRequest}
          leftButton="닫기"
          rightButton="대출 신청 취소"
        >
          <ScrollArea>
            <Contents>
              대출 신청을 취소하시겠어요? <br />
              해당 신청건은 담당자가 아직 확인 전이며, <br />
              취소 후 재신청이 가능해요.
            </Contents>
          </ScrollArea>
        </TwoBtnModal>

        {/* Alert 모달  */}
        <NoBtnModal
          showModal={backendAlertModal}
          onClose={onCloseBackendAlertModal}
        >
          <ScrollArea>
            <Contents>{backendAlertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>

        <NoBtnModal
          showModal={toolTipModal}
          onClose={showToolTipModal}
          title="기한만료란?"
        >
          <ScrollArea title="title">
            <Contents>
              대출 신청 3일 내 금융기관 담당자가 확인 안하면 신청을 종료해요.
              다른 금융기관에 대출 신청할 기회를 드리기 위함이에요.
            </Contents>
          </ScrollArea>
        </NoBtnModal>
      </LoanCardColumn>
    </LoanCard>
  );
};

export default LoanProductsList;
