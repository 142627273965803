import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom'; // to render child route elements
import dayjs from 'dayjs';
import useApp from 'hooks/useApp';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import Background from './Background';
import TopBanner from './TopBanner';
import Topbar from './Topbar';
import Footer from './Footer';
import { LayoutWrapper, MainWrapper, MainContent } from './style';
import { TARGET_URL_BY_POPUP_TYPE } from '../pages/Main/constants';
import NoticeBottomPopup from 'components/Popup/NoticeBottomPopup';
import TwoBtnPopup from 'components/Popup/TwoBtnPopup';
import { getDataRequest } from '../utils/request';
import { KEY_FO_POPUP_EXPIRED_DATE } from '../tools/WebTool';
import { NoBtnModal, TwoBtnModal } from 'components/Modal';
import {
  Contents,
  ContentsBox,
  NoScrollArea,
  ScrollArea,
} from 'components/Modal/style';
import GuideBox from 'components/GuideBox';
import IdentificationBox from 'components/IdentificationBox';
import { TextSpan } from 'pages/MyPage/MyPageMain/style';

const Layout = () => {
  const navigate = useNavigate();
  const { auth } = useApp();
  const location = useLocation();
  const { pathname } = location;
  const [showBanner, setShowBanner] = useState(false);
  const [popupData, setPopupData] = useState();
  const [showTwoBtnModal, setShowTwoBtnModal] = useState(false);
  const [modalAlertMessage, setModalAlertMessage] = useState('');
  const [showModalAlertMessage, setShowModalAlertMessage] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const [isFailAddMember, setIsFailAddMember] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [businessManagerHash, setBusinessManagerHash] = useState('');
  const [businessInfo, setBusinessInfo] = useState({
    businessName: '',
    businessRegistrationNumber: 0,
    invitationPeriodExpiredYn: '',
  });
  const isPCMode = window.innerWidth >= 1200;
  const noPadding =
    pathname === '/' ||
    pathname === ROUTE_PATH.mypage.checkLoan ||
    pathname === ROUTE_PATH.mypage.checkLoanList ||
    pathname === ROUTE_PATH.mypage.checkCreditLoanList ||
    pathname === ROUTE_PATH.mypage.checkLoanDetail ||
    pathname === ROUTE_PATH.mypage.checkCreditLoanDetail ||
    pathname === ROUTE_PATH.deepLink.financeAppInterlock;
  const customPadding = pathname.startsWith(ROUTE_PATH.eventDetailDefault)
    ? `padding-left: 0; padding-right: 0; padding-bottom: 0;`
    : '';

  const NO_BANNER_PATH = useMemo(() => {
    // 데이터 제공 동의, 데이터 연동 관리, 데이터 연동, 회원 가입 페이지에서는 주요 액션을 취하니까 안내 배너를 보여주지 않는다.
    return [
      ROUTE_PATH.deepLink.agree,
      ROUTE_PATH.mypage.dataLinkageManage,
      ROUTE_PATH.mypage.dataLinkageDataInterlockRequest,
      ROUTE_PATH.mypage.dataLinkageDataProviderRequest,
      ROUTE_PATH.login.joinMember,
    ];
  }, []);

  useEffect(() => {
    getPopupRequest();
  }, []);

  useEffect(() => {
    if (!!auth.bannerMessage && !NO_BANNER_PATH.includes(pathname)) {
      if (auth.bannerMessage.includes('직원 초대')) {
        setBusinessManagerHash(auth.bannerData.businessManagerHash);
        setShowBanner(false);
        setShowTwoBtnModal(true);
        return;
      }
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
  }, [auth.bannerMessage, pathname, NO_BANNER_PATH]);

  useEffect(() => {
    if (!!businessManagerHash) {
      const successFnc = data => {
        if (data && data.result) {
          setBusinessInfo(data.result);
          return;
        }
      };
      getDataRequest({
        url: `/v1/na/manager/invite-expired/${businessManagerHash}`,
        successFnc,
        setModalAlertMessage,
        setShowModalAlertMessage,
      });
    }
  }, [businessManagerHash]);

  const getPopupRequest = () => {
    getDataRequest({
      url: '/v1/na/popup',
      setData: data => setPopupData(data.result),
      errorFnc: (data, msg) => console.log(msg),
    });
  };

  const onClickClose = () => {
    setShowBanner(false);
  };

  // popup 함수 리스트
  const onClickNotShowingPopupBtn = event => {
    event?.stopPropagation();
    savePopupExpiredDate();
    onClosePopup();
  };

  const onClickNotShowingPopupCheckbox = event => {
    setIsChecked(prevState => !prevState);
  };

  const onClosePopup = event => {
    event?.stopPropagation();
    if (isChecked) {
      savePopupExpiredDate();
      setIsPopupOpen(false);
    }
    setIsPopupOpen(false);
  };

  const onClickPopup = event => {
    event?.stopPropagation();
    moveToTargetPage();
    onClosePopup(event);
  };

  const savePopupExpiredDate = () => {
    const now = dayjs();
    const expiredDate = now.add(1, 'day').format('YYYY-MM-DD');
    localStorage.setItem(KEY_FO_POPUP_EXPIRED_DATE, expiredDate.toString());
  };

  const checkIsShowingPopupByDate = () => {
    const now = dayjs();
    const expiredDate = localStorage.getItem(KEY_FO_POPUP_EXPIRED_DATE);
    return !expiredDate || now.diff(expiredDate, 'second') > 0;
  };

  const moveToTargetPage = () => {
    const isDetailPage = popupData?.targetSeq > 0;
    const isAdminPage = popupData?.targetSeq === 0;
    const pageData = TARGET_URL_BY_POPUP_TYPE.find(
      data => data.type === popupData?.popupTypeCd
    );
    if (isAdminPage) {
      navigate(pageData.adminPageUrl, { state: { key: pageData?.key } });
    } else if (isDetailPage) {
      window.open(
        `${process.env.REACT_APP_API_HOST}/${pageData?.detailPageUrl}/${popupData?.targetSeq}`,
        '_blank'
      );
    }
  };

  const onCloseTwoBtnModal = () => {
    setShowBanner(true);
    setShowTwoBtnModal(false);
  };

  const inviteMember = useCallback(
    ({ memberCertificationSeq }) => {
      const data = {
        memberCertificationSeq,
        businessManagerHash: businessManagerHash,
      };
      api({
        url: `/v1/au/manager/invite-accept`,
        method: 'put',
        headers: { 'content-type': 'application/json' },
        data: data,
      })
        .then(res => {
          const { data } = res;
          if (data.success) {
            setModalAlertMessage(data.message);
            setShowModalAlertMessage(true);
            setShowTwoBtnModal(false);
            setIsFailAddMember(false);
          } else {
            setModalAlertMessage(data.message);
            setShowModalAlertMessage(true);
            setShowTwoBtnModal(false);
            setIsFailAddMember(true);
          }
        })
        .catch(err => {
          setModalAlertMessage(err?.response?.data?.message);
          setShowModalAlertMessage(true);
          setShowTwoBtnModal(false);
          setIsFailAddMember(true);
        });
    },
    [businessManagerHash]
  );

  // 본인인증 완료(성공)
  const identificationSuccessCallback = useCallback(
    memberCertificationSeq => {
      inviteMember({
        memberCertificationSeq,
      });
    },
    [inviteMember]
  );
  // 본인인증 완료(decode 실패)
  const identificationExceptCallback = useCallback(err => {
    console.error(err);
    setModalAlertMessage(err?.response?.data?.message);
    setShowModalAlertMessage(true);
  }, []);

  const IdentificationBtnStyle = {
    width: '50%',
    height: '54px',
    margin: '0px',
    borderRadius: '0 0 20px 0',
  };

  const onCloseAlertModal = () => {
    setShowModalAlertMessage(false);
    setModalAlertMessage('');
    if (isFailAddMember) {
      setShowBanner(true);
    } else {
      window.location.reload();
    }
  };

  return (
    <LayoutWrapper>
      <Background />
      <MainWrapper>
        {showBanner && (
          <TopBanner
            onClickClose={onClickClose}
            bannerMessage={auth.bannerMessage}
            bannerData={auth.bannerData}
            setShowTwoBtnModal={setShowTwoBtnModal}
            setShowBanner={setShowBanner}
          />
        )}
        <Topbar pathname={pathname} showBanner={showBanner} />
        {/* 팝업은 메인 화면에서만 노출 */}
        {!!popupData &&
          isPCMode &&
          checkIsShowingPopupByDate() &&
          pathname === '/' && (
            <TwoBtnPopup
              data={popupData}
              showPopup={isPopupOpen}
              onClose={onClosePopup}
              onClickLeftBtn={onClosePopup}
              onClickRightBtn={onClickPopup}
              onClickCheckBox={onClickNotShowingPopupCheckbox}
              isChecked={isChecked}
            />
          )}
        {!!showTwoBtnModal && (
          <TwoBtnModal
            showModal={showTwoBtnModal}
            onClose={onCloseTwoBtnModal}
            onReset={onCloseTwoBtnModal}
            onClick={() => navigate(ROUTE_PATH.mypage.dataLinkageManage)}
            leftButton="닫기"
            rightButton="초대 수락하기"
            IdentificationBox={
              <IdentificationBox
                btnMessage="초대 수락하기"
                identificationSuccessCallback={identificationSuccessCallback}
                identificationExceptCallback={identificationExceptCallback}
                btnStyle={IdentificationBtnStyle}
              />
            }
          >
            <NoScrollArea>
              <Contents>
                <TextSpan>{businessInfo?.businessName}</TextSpan>
                <br />
                사업자 직원으로 초대되었습니다.
                <br />
                수락하시겠습니까?
                <br />
              </Contents>
              <ContentsBox>
                <GuideBox
                  guideMessage={
                    <>
                      <div>
                        2일 이내 수락이 가능하며, 2일 경과시 수락이 불가합니다.
                        사업자와 연결이 끊어진 회원은 사이트의 사용이
                        제한됩니다.
                      </div>
                      <div>초대 수락시 본인인증이 필요합니다.</div>
                    </>
                  }
                />
              </ContentsBox>
            </NoScrollArea>
          </TwoBtnModal>
        )}
        {!!popupData &&
          !isPCMode &&
          checkIsShowingPopupByDate() &&
          pathname === '/' && (
            <NoticeBottomPopup
              data={popupData}
              showModal={isPopupOpen}
              onClose={onClosePopup}
              onClick={onClickPopup}
              onClickNotShowingPopupBtn={onClickNotShowingPopupBtn}
            />
          )}

        {/* 회원 초대 알럿 메세지 모달 */}
        {/* 성공했을 때는 reload() 실패했을 때는 reload() 하지 않고 배너 보여주기 */}
        <NoBtnModal
          showModal={showModalAlertMessage}
          onClose={onCloseAlertModal}
        >
          <ScrollArea>
            <Contents>{modalAlertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
        <MainContent
          noPadding={noPadding}
          showBanner={showBanner}
          customPadding={customPadding}
        >
          <Outlet />
        </MainContent>
        <Footer />
      </MainWrapper>
    </LayoutWrapper>
  );
};

export default Layout;
