import styled from 'styled-components';
import theme from 'styles/theme';
import FeatherIcon from 'feather-icons-react';

export const Card = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 0 17px;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};
  border-top: ${props =>
    props.borderTop ? `1px solid ${theme.borderColor.lightGrey}` : 'none'};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const CardColumn = styled.div`
  position: relative;
  float: right;
  width: 100%;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const CardName = styled.div`
  display: ${props => (props.isOpen ? 'block' : '-webkit-box')};
  width: calc(100% - 36px);
  margin: 7px 0 4px;
  line-height: 2.7rem;
  font-size: 1.5rem;
  font-weight: 400;
  color: ${theme.color.black};
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  -webkit-line-clamp: 2; /* 크롬, 사파리 */
  -moz-line-clamp: 2; /* 파이어폭스 */
  -o-line-clamp: 2; /* 오페라 */
  -ms-line-clamp: 2; /* 익스플로러 */
  line-clamp: 2;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
`;

export const QuestionMark = styled.span`
  line-height: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: #009ff9;
`;

export const CardRow = styled.div`
  display: block;
  width: 100%;
  margin: 4px 0 0;
  padding-left: ${props => (props.paddingLeft ? '16px' : '0')};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const CardRowFirst = styled.div`
  float: left;
  width: ${props => (props.width ? `${props.width}` : '74px')};
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  color: ${theme.color.grey7};
`;

export const DetailButton = styled.button`
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  top: ${props => (props.rotated ? '15px' : '-14px')};
  right: 0;
  background: url(/images/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
  transform: ${props => (props.rotated ? 'rotate(270deg)' : 'rotate(90deg)')};
`;

export const BlackArrowIcon = styled(FeatherIcon)`
  position: absolute;
  display: block;
  top: 10px;
  right: 0;
  transform: ${props =>
    props.rotated === 'true' ? 'rotate(270deg)' : 'rotate(90deg)'};

  &:hover {
    cursor: pointer;
  }
`;

export const DataBox = styled.div`
  width: 100%;
  padding: 17px;
  line-height: 2.5rem;
  margin: 10px 0 0;
  background: ${theme.backgroundColor.contentBox};
  border-radius: 10px;

  font-size: 1.5rem;
  font-family: 'Noto Sans KR', sans-serif;

  li {
    margin-left: 19px;
  }

  a {
    text-decoration: underline;
  }

  img {
    max-width: 320px;
    margin-bottom: 5px;
    @media screen and (max-width: 320px) {
      max-width: 100%;
    }
  }

  p {
    line-height: 2rem;
  }
`;

export const BlueRightLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  line-height: 1.2rem;
`;

export const BlueText = styled.div`
  margin-left: 3px;
  text-decoration: underline;
  font-size: ${theme.fontSize.h5};
  color: ${theme.color.blue};
  cursor: pointer;
`;
