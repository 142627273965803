import React, { useState } from 'react';
import IdentificationBox from 'components/IdentificationBox';
import {
  OneBtnModal,
  TwoVerticalBtnModal,
  FilterPolicyModal,
} from 'components/Modal';
import PolicyContents from './PolicyContents';
import { Contents, ScrollArea } from 'components/Modal/style';

const IdentificationModal = ({
  showIdentificationModal,
  onCloseIdentificationModal,
  identificationSuccessCallback,
  identificationExceptCallback,
  alertModalMessage,
  mallModalType,
  stipulationList = [],
  setStipulationList,
}) => {
  const [showPolicyDetail, setShowPolicyDetail] = useState(false);
  const [clickedStipulation, setClickedStipulation] = useState(0);

  const onChangeCheckBox = e => {
    const { value, checked } = e.target;

    setStipulationList(list =>
      list.map(item => {
        return +item.stipulationSeq === +value
          ? { ...item, checked: checked }
          : item;
      })
    );
  };
  const onClickViewBtn = stipulationSeq => {
    if (!!stipulationSeq) {
      setClickedStipulation(
        stipulationList.find(item => item.stipulationSeq === stipulationSeq)
      );
      setShowPolicyDetail(true);
    }
  };

  const isAllRequiredChecked = stipulationList
    .filter(item => item.required === true)
    .every(item => item.checked === true);

  const onCloseModal = () => {
    setStipulationList([]);
    setShowPolicyDetail(false);
    onCloseIdentificationModal();
  };

  return mallModalType === 'credit' ? (
    <TwoVerticalBtnModal
      showModal={showIdentificationModal}
      onClose={onCloseModal}
      topButton={
        <IdentificationBox
          modalButton={true}
          btnMessage="약관 동의 후, 휴대폰 본인인증"
          identificationPurposeType="CREDIT_REQUEST"
          identificationSuccessCallback={identificationSuccessCallback}
          identificationExceptCallback={identificationExceptCallback}
        />
      }
      disabled={!isAllRequiredChecked}
      onClickBottomBtn={onCloseModal}
      bottomButton="닫기"
    >
      <ScrollArea>
        <Contents>
          <PolicyContents
            stipulationList={stipulationList}
            onChangeCheckBox={onChangeCheckBox}
            onClickViewBtn={onClickViewBtn}
          />
        </Contents>
      </ScrollArea>

      <FilterPolicyModal
        showModal={showPolicyDetail}
        onClose={() => setShowPolicyDetail(false)}
        stipulation={clickedStipulation}
      />
    </TwoVerticalBtnModal>
  ) : (
    <OneBtnModal
      showModal={showIdentificationModal}
      onClose={onCloseIdentificationModal}
      btnContent={
        <IdentificationBox
          modalButton={true}
          identificationPurposeType="CREDIT_REQUEST"
          identificationSuccessCallback={identificationSuccessCallback}
          identificationExceptCallback={identificationExceptCallback}
        />
      }
    >
      <ScrollArea>
        <Contents>{alertModalMessage}</Contents>
      </ScrollArea>
    </OneBtnModal>
  );
};

export default IdentificationModal;
