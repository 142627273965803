import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import useApp from 'hooks/useApp';
import { getDataRequest, getListDataRequest } from 'utils/request';
import LoadingIndicator from 'components/LoadingIndicator';
import DataProviderModifyPage from './DataProviderModifyPage';
import IndividualModifyPage from './IndividualModifyPage';
import { statusActions } from '../DataLinkageManage/LinkedMall/LinkedMallItem/constants';

const DataLinkageModify = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useApp();
  const { state } = location;
  const { businessRegistrationNumber, memberRoleData, memberTotalData } = auth;

  const [linkageType, setLinkageType] = useState('');
  const [dataProviderInfo, setDataProviderInfo] = useState({});
  const [dataInterlockInfo, setDataInterlockInfo] = useState({});
  const [redGuideContents, setRedGuideContents] = useState('');
  const [secondAuthReleaseMessage, setSecondAuthReleaseMessage] = useState('');
  const [dataInterlockMallList, setDataInterlockMallList] = useState([]);

  const isRepresentation = memberRoleData?.representativeYn === 'Y';

  //연동 정보: linkageType && sellerData
  //url로 변경됨
  useEffect(() => {
    if (!!state) {
      setLinkageType(state.linkageType);
      if (state.linkageType === 'dataProvider') {
        setDataProviderInfo(
          {
            ...state.item,
            linkageType: state.linkageType,
          } || {}
        );
      } else if (state.linkageType === 'dataInterlock') {
        setDataInterlockInfo(
          {
            ...state.item,
            linkageType: state.linkageType,
          } || {}
        );
      }
    }
  }, [state]);

  useEffect(() => {
    if (dataInterlockInfo.mallSeq && dataInterlockInfo.dataProviderSeq) {
      const url = `/v1/au/salesMall/guidance`;
      const params = {
        guidanceExposureLocationCode: 'INTERLOCK',
        mallSeq: dataInterlockInfo.mallSeq,
        dataProviderSeq: dataInterlockInfo.dataProviderSeq,
      };
      getDataRequest({ url, params, setData: setRedGuideContents });
    } else {
      return;
    }
  }, [dataInterlockInfo.mallSeq, dataInterlockInfo.dataProviderSeq]);

  useEffect(() => {
    if (!!dataInterlockInfo.dataProviderSeq) {
      getListDataRequest({
        url: '/v1/au/salesMall/individual/interlock/mall',
        setData: setDataInterlockMallList,
        params: { dataProviderSeq: dataInterlockInfo.dataProviderSeq },
      });
    }
  }, [dataInterlockInfo.dataProviderSeq]);

  useEffect(() => {
    if (
      dataInterlockInfo &&
      dataInterlockMallList &&
      dataInterlockMallList.length > 0
    ) {
      const dataInterlockMallSecondAuthReleaseMessage =
        dataInterlockMallList.find(
          item => item.mallSeq === dataInterlockInfo.mallSeq
        ).secondAuthReleaseMessage;
      setSecondAuthReleaseMessage(dataInterlockMallSecondAuthReleaseMessage);
    }
  }, [dataInterlockInfo, dataInterlockMallList]);

  //  수정페이지에 url 입력해서 접근시 데이터 연동 페이지로 이동
  let isLoading = false;
  if (
    !state ||
    !state.linkageType ||
    !state.item ||
    statusActions[state?.item?.status]?.modify === false
  ) {
    isLoading = true;
    setTimeout(() => navigate(ROUTE_PATH.mypage.dataLinkageManage), 3000);
  }

  return (
    <>
      {linkageType === 'dataProvider' ? (
        <DataProviderModifyPage
          dataProviderInfo={dataProviderInfo}
          setDataProviderInfo={setDataProviderInfo}
          businessRegistrationNumber={businessRegistrationNumber}
        />
      ) : linkageType === 'dataInterlock' ? (
        <IndividualModifyPage
          dataInterlockInfo={dataInterlockInfo}
          setDataInterlockInfo={setDataInterlockInfo}
          businessRegistrationNumber={businessRegistrationNumber}
          secondAuthReleaseMessage={secondAuthReleaseMessage}
          redGuideContents={redGuideContents?.guidanceContents}
          isRepresentation={isRepresentation}
        />
      ) : null}

      {isLoading && (
        <LoadingIndicator
          message={`페이지에 접근할 수 없습니다. 데이터 연동 관리화면으로\n이동합니다.`}
        />
      )}
    </>
  );
};

export default DataLinkageModify;
