import React from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import ROUTE_PATH from 'routes/config';
import BlueAlertBox from 'components/BlueAlertBox';
import CustomerServiceFooter from 'layouts/Footer/CustomerServiceFooter';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import {
  FirstText,
  ChildTextRow,
  BackgroundTextBox,
  BackgroundTextBoxTitle,
  BackgroundTextBoxContents,
  BlueBackgroundBox,
  SmallText,
  BlueBackgroundBoxText,
} from 'pages/MyPage/DataLinkage/style';

const IndividualModifyStep4 = ({
  dataInterlockInfo,
  possibleInterlockYn,
  redGuideContents,
}) => {
  const navigate = useNavigate();
  const { mallName } = dataInterlockInfo;

  return (
    <>
      <FirstText>
        {mallName} 데이터 연동 요청이 완료되었어요. <br />
        잠시 후 연동 결과가
        <br />
        문자로 발송될 예정이예요.
      </FirstText>
      {redGuideContents && (
        <BlueAlertBox
          background="icyRed"
          title="주요 안내"
          contents={
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(redGuideContents),
              }}
            />
          }
        />
      )}
      <BackgroundTextBox style={{ marginTop: '23px' }}>
        <ChildTextRow style={{ display: 'flex', flexDirection: 'row' }}>
          <FeatherIcon icon="alert-circle" size={16} />
          <BackgroundTextBoxTitle style={{ marginLeft: '5px' }}>
            2차 인증 안내 문자가 왔어요.
          </BackgroundTextBoxTitle>
        </ChildTextRow>
        <BackgroundTextBoxContents>
          데이터 연동 요청을 위해 2차 인증을 진행해야 하는 경우가 발생해요. 추가
          2차 인증이 필요한 경우, 2차 인증 요청 문자가 발송될 예정이며 안내에
          따라 2차 인증을 진행해 주세요.
        </BackgroundTextBoxContents>
      </BackgroundTextBox>
      {possibleInterlockYn === 'Y' ? (
        <BlueBackgroundBox
          onClick={() => {
            navigate(ROUTE_PATH.mypage.dataLinkageDataInterlockRequest);
          }}
        >
          <SmallText>다른 판매몰이 더 있으신가요?</SmallText>
          <BlueBackgroundBoxText>데이터 연동 추가 하기</BlueBackgroundBoxText>
        </BlueBackgroundBox>
      ) : null}

      <StyledActiveButton
        backgroundColor="contentBox"
        color="black"
        onClick={() => {
          navigate(ROUTE_PATH.mypage.dataLinkageManage);
        }}
      >
        데이터 연동 관리 화면 가기
      </StyledActiveButton>
      <div style={{ margin: '46px 0px 0px 0px' }}>
        <CustomerServiceFooter hasOneToOne={true} hasInfo={false} />
      </div>
    </>
  );
};

export default IndividualModifyStep4;
