import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'react-js-pagination';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import LoanProductsList from './LoanProductsList';
import FilterModal from './FilterModal';
import { defaultFinanceNameList, defaultManagerNameList } from './constants';
import {
  FilterButton,
  FilterMenu,
  FilterRow,
  Filter,
  FilterText,
  LoanListWrap,
  NoListTextBlue,
  NoListTextTitle,
  PaginationWrapper,
  TotalQuantity,
} from './style';
import { getListDataRequest } from 'utils/request';

const LoanManage = () => {
  const { mypage } = ROUTE_PATH;
  const location = useLocation();
  const navigate = useNavigate();

  const [businessName, setBusinessName] = useState('');
  const [paginationCount, setPaginationCount] = useState({});
  const [loanProductsList, setLoanProductsList] = useState([]);
  const [financeInstitutionList, setFinanceInstitutionList] = useState([]);
  const [validManagerList, setValidManagerList] = useState([]);
  const [filterModalManagerList, setFilterModalManagerList] = useState([]);

  // 필터모달
  const [showFilterModal, setShowFilterModal] = useState(false);

  // 사용자가 필터 모달에서 필터 선택할 때 변경되는 값
  const [selectedFilter, setSelectedFilter] = useState({
    finance: { label: '', value: '' },
    employee: { label: '', value: '' },
    status: { label: '', value: '' },
  });

  // 실제로 데이터 렌더링할 때 적용된 필터
  const [appliedFilter, setAppliedFilter] = useState({
    finance: { label: '', value: '' },
    employee: { label: '', value: '' },
    status: { label: '', value: '' },
  });

  // 필터 Modal창 관리 함수
  const toggleModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  // selected 된 값 가져오기
  const handleSelectedFilter = event => {
    const { name, value } = event.target;
    const selectedOptionText =
      event.target.options[event.target.selectedIndex].textContent;
    setSelectedFilter(prev => ({
      ...prev,
      [name]: { label: selectedOptionText, value: value },
    }));
  };

  // 필터 적용 버튼 함수
  const applyFilter = () => {
    setAppliedFilter(selectedFilter);
    toggleModal();
    setPage(1);
  };

  // 필터 초기화 버튼
  const resetFilterModal = () => {
    setSelectedFilter({
      finance: { label: '', value: '' },
      employee: { label: '', value: '' },
      status: { label: '', value: '' },
    });
  };

  // 필터 닫기
  const onCloseFilterModal = () => {
    toggleModal();
  };

  // 필터 삭제
  const deleteFilter = item => {
    if (item === 'finance') {
      setAppliedFilter(prev => ({
        ...prev,
        finance: { label: '', value: '' },
      }));
    } else if (item === 'employee') {
      setAppliedFilter(prev => ({
        ...prev,
        employee: { label: '', value: '' },
      }));
    } else if (item === 'status') {
      setAppliedFilter(prev => ({
        ...prev,
        status: { label: '', value: '' },
      }));
    }
  };

  // pagination
  const [page, setPage] = useState(1);
  const handlePageChange = page => {
    setPage(page);
  };

  const getLoanProducts = () => {
    const params = {};
    if (Number(appliedFilter.finance.value)) {
      params.financeInstitutionSeq = appliedFilter.finance.value;
    }
    if (Number(appliedFilter.employee.value)) {
      params.businessManagerSeq = appliedFilter.employee.value;
    }
    if (isNaN(appliedFilter.status.value)) {
      params.creditStatus = appliedFilter.status.value;
    }
    if (page) {
      params.pageNo = page;
    }

    api({
      url: '/v1/au/credit-request',
      method: 'get',
      params,
    })
      .then(res => {
        const { data } = res.data;
        setBusinessName(data?.businessName);
        setLoanProductsList(data?.list || []);
        setPaginationCount(data?.paging || {});
      })
      .catch(err => {
        console.error(err);
      });
  };

  // 대출 관리 목록 조회
  useEffect(() => {
    getLoanProducts();
  }, [page, appliedFilter]);

  // 대출 관리 금융 기관 조회
  useEffect(() => {
    api({
      url: '/v1/au/credit-request/finance-institution',
      method: 'get',
    })
      .then(res => {
        const { data } = res.data;
        if (data.list) {
          setFinanceInstitutionList([
            ...defaultFinanceNameList,
            ...data.list.map(item => ({
              value: item.financeInstitutionSeq,
              label: item.financeInstitutionName,
            })),
          ]);
        }
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  // 직원 목록 조회
  useEffect(() => {
    const successFnc = listData => {
      const validData = listData?.filter(
        item =>
          item.useYn === 'Y' &&
          (item.representationYn === 'Y' || item.invitationCompletionYn === 'Y')
      );
      setValidManagerList(validData);

      const filterModalManagerList = [
        ...defaultManagerNameList,
        ...validData.map(item => ({
          value: item.businessManagerSeq,
          label: item.managerName,
        })),
      ];
      setFilterModalManagerList(filterModalManagerList);
    };
    getListDataRequest({ url: '/v1/au/manager', successFnc });
  }, []);

  return (
    <div>
      {!!businessName ? (
        paginationCount?.totalCount > 0 ||
        selectedFilter?.finance?.value ||
        selectedFilter?.status?.value ||
        selectedFilter?.employee?.value ? (
          <div>
            <FilterMenu>
              <TotalQuantity>총 {paginationCount?.totalCount}개</TotalQuantity>
              <FilterButton onClick={toggleModal}>필터</FilterButton>
            </FilterMenu>
            <FilterRow>
              {appliedFilter.finance.label &&
              appliedFilter.finance.label !== '전체' ? (
                <Filter onClick={() => deleteFilter('finance')}>
                  <FilterText>{appliedFilter.finance.label}</FilterText>
                  <FeatherIcon
                    icon="x-circle"
                    width="20px"
                    height="20px"
                    stroke="white"
                    fill="#7E92A2"
                  />
                </Filter>
              ) : null}
              {appliedFilter.employee.label &&
              appliedFilter.employee.label !== '전체 판매몰' ? (
                <Filter onClick={() => deleteFilter('employee')}>
                  <FilterText>{appliedFilter.employee.label}</FilterText>
                  <FeatherIcon
                    icon="x-circle"
                    width="20px"
                    height="20px"
                    stroke="white"
                    fill="#7E92A2"
                  />
                </Filter>
              ) : null}
              {appliedFilter.status.label &&
              appliedFilter.status.label !== '전체 판매몰' ? (
                <Filter onClick={() => deleteFilter('status')}>
                  <FilterText>{appliedFilter.status.label}</FilterText>
                  <FeatherIcon
                    icon="x-circle"
                    width="20px"
                    height="20px"
                    stroke="white"
                    fill="#7E92A2"
                  />
                </Filter>
              ) : null}
            </FilterRow>
            {paginationCount?.totalCount > 0 && (
              <>
                <LoanListWrap>
                  {loanProductsList.map((item, index) => {
                    return (
                      <LoanProductsList
                        item={item}
                        key={`CreditRequest_${item.creditRequestSeq}_${index}`}
                        financeInstitutionList={financeInstitutionList}
                        validManagerList={validManagerList}
                        locationState={location.state}
                      />
                    );
                  })}
                </LoanListWrap>
                <PaginationWrapper>
                  <Pagination
                    activePage={page}
                    itemsCountPerPage={30}
                    totalItemsCount={paginationCount?.totalCount}
                    pageRangeDisplayed={5}
                    prevPageText="<"
                    nextPageText=">"
                    firstPageText="<<"
                    lastPageText=">>"
                    onChange={handlePageChange}
                  />
                </PaginationWrapper>
              </>
            )}
            {paginationCount?.totalCount === 0 && (
              <NoListTextTitle>
                필터 조건에 맞는 대출이 없습니다.
              </NoListTextTitle>
            )}
            <FilterModal
              showModal={showFilterModal}
              financeInstitutionList={financeInstitutionList}
              managerList={filterModalManagerList}
              selectedFilter={selectedFilter}
              handleSelectedFilter={handleSelectedFilter}
              onClose={onCloseFilterModal}
              onReset={resetFilterModal}
              onClick={applyFilter}
            />
          </div>
        ) : (
          <div>
            <NoListTextTitle>
              <NoListTextBlue>{businessName}</NoListTextBlue>님께 맞는
              <br />
              대출을 찾아보세요.
            </NoListTextTitle>
            <StyledActiveButton
              onClick={() => {
                navigate(mypage.checkLoan);
              }}
            >
              대출 신청
            </StyledActiveButton>
          </div>
        )
      ) : null}
    </div>
  );
};

export default LoanManage;
