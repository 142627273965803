import React, { useEffect, useState } from 'react';
import useApp from 'hooks/useApp';
import CustomerServiceFooter from 'layouts/Footer/CustomerServiceFooter';
import IndividualRequestStep1 from './IndividualRequestStep1';
import IndividualRequestStep2 from './IndividualRequestStep2';
import IndividualRequestStep3 from './IndividualRequestStep3';

const DataLinkageIndividualRequestPage = () => {
  const { auth } = useApp();
  const [step, setStep] = useState(1);
  const [dataProviderSeq, setDataProviderSeq] = useState(0);
  const [dataInterlockInfo, setDataInterlockInfo] = useState({
    mallName: '',
  });
  const [possibleInterlockYn, setPossibleInterlockYn] = useState('');

  useEffect(() => {
    // dataProviderSeq 초기화 될때 dataInterlockInfo 값 초기화 해준다.
    if (dataProviderSeq === 0) {
      setDataInterlockInfo({ mallName: '' });
    }
  }, [dataProviderSeq]);

  return (
    <div>
      {step === 1 && (
        <IndividualRequestStep1
          auth={auth}
          setStep={setStep}
          setDataProviderSeq={setDataProviderSeq}
          dataInterlockInfo={dataInterlockInfo}
          setDataInterlockInfo={setDataInterlockInfo}
        />
      )}
      {step === 2 && (
        <IndividualRequestStep2
          setStep={setStep}
          dataProviderSeq={dataProviderSeq}
          setDataProviderSeq={setDataProviderSeq}
          dataInterlockInfo={dataInterlockInfo}
          setDataInterlockInfo={setDataInterlockInfo}
          setPossibleInterlockYn={setPossibleInterlockYn}
        />
      )}
      {step === 3 && (
        <IndividualRequestStep3
          setStep={setStep}
          dataInterlockInfo={dataInterlockInfo}
          possibleInterlockYn={possibleInterlockYn}
          setDataProviderSeq={setDataProviderSeq}
          dataProviderSeq={dataProviderSeq}
        />
      )}

      <CustomerServiceFooter hasOneToOne={true} hasInfo={false} />
    </div>
  );
};

export default DataLinkageIndividualRequestPage;
