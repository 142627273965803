import styled from 'styled-components';
import theme from 'styles/theme';

export const JoinMemberWrapper = styled.div``;

export const TextMedium = styled.div`
  padding: 41px 0 18px;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  color: ${theme.color.black};
  text-align: center;

  @media screen and (max-width: 320px) {
    font-size: 1.5rem;
  }
`;

export const ContentBox = styled.div`
  width: 100%;
  padding: 13px 19px;
  background: ${theme.backgroundColor.contentBox};
  border-radius: 10px;
  margin: 23px 0 0;
`;

export const ContentTitle = styled.div`
  margin: 19px 0 0;
  width: 100%;
  font-size: 1.3rem;
  line-height: 2.1rem;
  font-weight: 400;
  text-align: left;
  color: ${theme.color.navyBlue};

  &:first-child {
    margin: 0;
  }
`;

export const ContentData = styled.div`
  width: 100%;
  margin: 3px 0 0;
`;

export const ContentText = styled.div`
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  line-height: 2.1rem;
  color: ${theme.color.black};
`;

export const ChildText = styled.div`
  width: 100%;
  padding: 0 0 14px 19px;
  margin: 20px 0 0;
  background: url(/images/child.png) 0px 5px no-repeat;
  background-size: 10px 10px;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ChildTextContent = styled.div`
  display: block;
  float: right;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  line-height: 2.1rem;
  color: ${theme.color.black};
`;
