import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from 'utils/api';
import ROUTE_PATH from 'routes/config';
import { validatePassword } from 'tools/ValidationTool';
import TextField from 'components/TextField';
import { NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import { alertMessages } from 'messages/alertMessages';
import { convertNames } from './constants';
import {
  ContentSubpage,
  TextMedium,
  ContentBox,
  ContentTitle,
  ContentData,
  ContentBlueText,
} from './style';

const SearchPasswordReset = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const bizData = location.state;
  const { login } = ROUTE_PATH;

  const [passwordInfo, setPasswordInfo] = useState({
    newPassword: '',
    repeatPassword: '',
  });

  // input alertMessage
  const [inputAlertMessage, setInputAlertMessage] = useState({
    newPassword: '',
    repeatPassword: '',
  });

  // 백엔드 통신 오류 메시지
  const [backendAlertMessage, setBackendAlertMessage] = useState('');

  // 성공 모달 메시지
  const [successAlertMessage, setSuccessAlertMessage] = useState('');

  // onChange
  const onChange = e => {
    const { name, value } = e.target;
    if (name === 'newPassword') {
      validatePassword(value)
        ? setInputAlertMessage(prevState => ({ ...prevState, [name]: '' }))
        : setInputAlertMessage(prevState => ({
            ...prevState,
            [name]: alertMessages.validatePw,
          }));
    }
    setPasswordInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const onBlur = e => {
    const { name, value } = e.target;
    if (value) {
      if (name === 'newPassword') {
        if (!validatePassword(value)) {
          return setInputAlertMessage(prevState => ({
            ...prevState,
            [name]: alertMessages.validatePw,
          }));
        }
      } else if (name === 'repeatPassword') {
        if (passwordInfo.newPassword !== passwordInfo.repeatPassword) {
          return setInputAlertMessage(prevState => ({
            ...prevState,
            [name]: alertMessages.differPw,
          }));
        }
      }
      setInputAlertMessage(prevState => ({ ...prevState, [name]: '' }));
    }
    if (!value) {
      setInputAlertMessage(prevState => ({
        ...prevState,
        [name]: alertMessages[convertNames[name]],
      }));
    }
  };

  const onFocus = e => {
    const { name, value } = e.target;
    if (value) {
      if (name === 'newPassword') {
        if (!validatePassword(value)) {
          return setInputAlertMessage(prevState => ({
            ...prevState,
            [name]: alertMessages.validatePw,
          }));
        }
      } else if (name === 'repeatPassword') {
        if (passwordInfo.newPassword !== passwordInfo.repeatPassword) {
          return setInputAlertMessage(prevState => ({
            ...prevState,
            [name]: alertMessages.differPw,
          }));
        }
      }
      setInputAlertMessage(prevState => ({ ...prevState, [name]: '' }));
    }
    if (!value) {
      setInputAlertMessage(prevState => ({
        ...prevState,
        [name]: alertMessages[convertNames[name]],
      }));
    }
  };

  const onClickResetPasswordBtn = () => {
    resetPassword({
      postData: {
        memberId: bizData.memberId,
        memberPassword: passwordInfo.newPassword,
        memberCertificationSeq: bizData.memberCertificationSeq,
      },
      setBackendAlertMessage,
      setSuccessAlertMessage,
    });
  };

  return (
    <ContentSubpage>
      {bizData ? (
        <>
          <TextMedium>
            본인 확인되었습니다. <br />
            비밀번호 재설정 해주세요.
          </TextMedium>
          <ContentBox>
            <ContentTitle>아이디</ContentTitle>
            <ContentData>
              <ContentBlueText>{bizData && bizData.memberId}</ContentBlueText>
            </ContentData>
          </ContentBox>
          <TextField
            type="password"
            title="비밀번호 입력"
            name="newPassword"
            placeholder="비밀번호"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            showMessage={inputAlertMessage.newPassword}
            message={inputAlertMessage.newPassword}
            value={passwordInfo.newPassword}
            maxLength={10}
          />
          <TextField
            type="password"
            title="비밀번호 입력"
            name="repeatPassword"
            placeholder="비밀번호 확인"
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            showMessage={inputAlertMessage.repeatPassword}
            message={inputAlertMessage.repeatPassword}
            value={passwordInfo.repeatPassword}
            maxLength={10}
          />
          <StyledActiveButton
            tabIndex={0}
            onClick={onClickResetPasswordBtn}
            disabled={passwordInfo.repeatPassword !== passwordInfo.newPassword}
          >
            비밀번호 재설정
          </StyledActiveButton>
        </>
      ) : null}
      {successAlertMessage && (
        <NoBtnModal
          showModal={successAlertMessage}
          onClose={() => navigate(login.login)}
          btnContent="로그인"
        >
          <ScrollArea>
            <Contents>{successAlertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      )}
      {backendAlertMessage && (
        <NoBtnModal
          showModal={backendAlertMessage}
          onClose={() => setBackendAlertMessage('')}
        >
          <ScrollArea>
            <Contents>{backendAlertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      )}
    </ContentSubpage>
  );
};

// reset password
const resetPassword = ({
  postData,
  setBackendAlertMessage,
  setSuccessAlertMessage,
}) => {
  api({
    url: `/v1/na/member/password/set`,
    method: 'put',
    headers: { 'content-type': 'application/json' },
    data: postData,
  })
    .then(res => {
      const { success, message } = res.data;
      if (success) {
        setSuccessAlertMessage(message);
      } else {
        setBackendAlertMessage(message);
      }
    })
    .catch(err => {
      console.error(err);
      const { data } = err.response;
      setBackendAlertMessage(data.message);
    });
};

export default SearchPasswordReset;
