import styled, { css } from 'styled-components';
import theme from 'styles/theme';
import { Header } from '../style';

export const LogoTopbarHeader = styled(Header)`
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props => {
    return css`
      ${getCssStrBorder(props)}
    `;
  }}
`;

export const HomeLogo = styled.button`
  margin-left: 20px;
  width: 175px;
  height: 49px;
  background: url(/images/top-logo-new.png) center no-repeat;
  background-size: 174px 13px;
  font-size: 0;

  @media screen and (max-width: 375px) {
    margin-left: 10px;
    width: 145px;
    height: 11px;
    font-size: 0;
    background: url(/images/top-logo-new.png) center no-repeat;
    background-size: 145px 11px;
  }
`;

export const RContent = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media screen and (max-width: 375px) {
    margin-right: 10px;
  }
`;

export const LoanButton = styled.button`
  height: 38px;
  margin: 0px 15px 0px 0px;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${theme.color.black};

  @media screen and (max-width: 375px) {
    margin-right: 10px;
    font-size: 1.3rem;
  }
`;
const getCssStrBorder = props => {
  let cssStr = '';
  if (props.main === true) {
    cssStr += `border-bottom: none;`;
  }
  return cssStr;
};

export const MypageButton = styled.button`
  height: 20px;
  cursor: pointer;
`;

export const LoginButton = styled.button`
  height: 20px;
  cursor: pointer;
`;
