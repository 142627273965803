import React, { useEffect } from 'react';
import {
  ModalWrapper,
  ModalHeader,
  Title,
  ModalBtnLeft,
  ModalBtnRight,
  ModalBtnWrap,
  ModalClose,
  SideArea,
  Wrapper,
} from '../style';

const TwoBtnModal = ({
  title = '',
  showModal = false,
  onClose = () => {},
  onReset,
  onClick = () => {},
  onClickLeftBtn,
  leftButton = '',
  rightButton = '',
  rightButtonDisabled = false,
  children,
  border = false,
  buttonWidth = '50%',
  IdentificationBox = null,
}) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    showModal && (
      <Wrapper>
        <ModalWrapper border={!!border}>
          <ModalHeader>
            <Title>{title}</Title>
            <ModalClose onClick={onClose} />
          </ModalHeader>
          {children}
          <ModalBtnWrap>
            <ModalBtnLeft
              onClick={
                onClickLeftBtn ? onClickLeftBtn : onReset ? onReset : onClose
              }
            >
              {leftButton}
            </ModalBtnLeft>

            {IdentificationBox ? (
              IdentificationBox
            ) : (
              <ModalBtnRight
                onClick={onClick}
                disabled={rightButtonDisabled}
                width={buttonWidth}
              >
                {rightButton}
              </ModalBtnRight>
            )}
          </ModalBtnWrap>
        </ModalWrapper>
        <SideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default TwoBtnModal;
