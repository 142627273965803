import styled from 'styled-components';
import theme from 'styles/theme';
import { LINKAGE_STATUS } from './constants';
const LINKAGE_STATUS_KEYS = Object.keys(LINKAGE_STATUS);

export const StatusSpan = styled.span`
  display: inline-block;
  padding: 0px 8px;
  margin: 0 8px 2px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 5px;

  @media (max-width: 350px) {
    margin: 7px 0 0 0;
  }

  ${({ status }) => {
    // LINKAGE_STATUS_KEYS : FAIL -> WAIT -> STOP -> FINISH
    // 연동 실패
    if (status === LINKAGE_STATUS_KEYS[0]) {
      return `
        background: ${theme.backgroundColor.red};
        color: ${theme.color.red};
      `;
    }
    // 연동 대기
    if (status === LINKAGE_STATUS_KEYS[1]) {
      return `
        background: ${theme.backgroundColor.purple};
        color: ${theme.color.purple};
      `;
    }
    // 연동 중지
    if (status === LINKAGE_STATUS_KEYS[2]) {
      return `
        background: ${theme.backgroundColor.lightGrey};
        color: ${theme.color.grey7};
      `;
    }
    // 연동 완료
    if (status === LINKAGE_STATUS_KEYS[3]) {
      return `
        background: ${theme.backgroundColor.checkBlue};
        color: ${theme.color.blue};
      `;
    }

    // 연동 불가
    if (status === LINKAGE_STATUS_KEYS[4]) {
      return `
        background: ${theme.backgroundColor.grey7};
        color: ${theme.color.white};
      `;
    }
  }}
`;

export const BlueAlertBoxTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
  white-space: pre-line;
`;

export const BlueAlertBoxContentContainer = styled.div`
  margin-left: 16px;
  margin-bottom: 3px;
  margin-top: 5px;
`;

export const ToolTip = styled.button`
  float: right;
  width: 25px;
  height: 22px;
  background: url(/images/tooltip-icon20.png) left no-repeat;
  background-size: 20px 20px;
`;
