import React, { useEffect, useState } from 'react';
import { getDataRequest, getListDataRequest } from 'utils/request';
import Tab from 'components/Tab';
import EstimatedAmountManageTab from './EstimatedAmountManageTab';
import { MAll_DATA_TAB_OPTIONS } from './constants';

const EstimatedAmountManage = () => {
  //tab index
  const [tabIndex, setTabIndex] = useState(0);
  //tab 전체 데이터
  const [tabListData, setTabListData] = useState([]);
  //초기 데이터 (대출 가능)
  const [loansPossibleMallData, setLoansPossibleMallData] = useState({});
  //초기 데이터 (대출 불가)
  const [loansImpossibleMallData, setLoansImpossibleMallData] = useState({});
  //데이터 제공사
  const [dataProviderList, setDataProviderList] = useState([]);
  //화면에 보이는 몰 리스트
  const [filteredLoansMallList, setFilterdLoansMallList] = useState([]);

  //진입 시점 : api 통신
  useEffect(() => {
    const successFnc = loansPossibleMallData => {
      setFilterdLoansMallList(loansPossibleMallData?.loansPossibleMall);

      //대출 불가 데이터(2)
      getDataRequest({
        url: `/v1/au/data-provider/manage/mall`,
        params: { loansStatus: 'UNAV' },
        setData: setLoansImpossibleMallData,
        successFnc: loansImpossibleMallData => {
          const tabData = MAll_DATA_TAB_OPTIONS.map(item =>
            item.index === 0
              ? {
                  ...item,
                  mallList: loansPossibleMallData?.loansPossibleMall || [],
                  totalScheduledSettlementAmt:
                    loansPossibleMallData?.totalScheduledSettlementAmt || 0,
                  mallInterlockStatusInWait:
                    loansPossibleMallData?.mallInterlockStatusInWait || [],
                  mallInterlockStatusInWaitCount:
                    loansPossibleMallData?.mallInterlockStatusInWaitCount || 0,
                }
              : {
                  ...item,
                  mallList: loansImpossibleMallData?.loansImpossibleMall || [],
                }
          );
          setTabListData(tabData);
        },
      });

      //데이터 제공사(3)
      getListDataRequest({
        url: `/v1/au/data-provider/using-data-provider-list`,
        successFnc: dataProviderList => {
          const filterDataProviderList = dataProviderList.map(item => ({
            label: item.dataProviderName,
            value: item.dataProviderSeq,
          }));
          setDataProviderList([
            { label: '데이터제공사', value: 0 },
            ...filterDataProviderList,
          ]);
        },
      });
    };

    //대출 가능 데이터 (1)
    getDataRequest({
      url: `/v1/au/data-provider/manage/mall`,
      params: { loansStatus: 'AVAI' },
      setData: setLoansPossibleMallData,
      successFnc,
    });
  }, []);

  //tab 변환
  const onChangeTab = index => {
    setTabIndex(index);
    if (index === 0) {
      setFilterdLoansMallList(loansPossibleMallData.loansPossibleMall);
      return;
    }

    if (index === 1) {
      setFilterdLoansMallList(loansImpossibleMallData.loansImpossibleMall);
      return;
    }
  };

  const contentComponents = tabListData.map(item => (
    <EstimatedAmountManageTab
      key={item.tabIndex}
      tabIndex={tabIndex}
      tabData={item}
      dataProviderList={dataProviderList}
      filteredLoansMallList={filteredLoansMallList}
      setFilterdLoansMallList={setFilterdLoansMallList}
    />
  ));

  return (
    tabListData?.length > 0 && (
      <Tab
        options={MAll_DATA_TAB_OPTIONS}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        contentComponents={contentComponents}
        secondary={true}
        onChange={onChangeTab}
      />
    )
  );
};
export default EstimatedAmountManage;
