import React from 'react';
import PolicyAgreeRow from 'components/PolicyAgreeRow';
import { TextBox } from 'components/SelectMallModal/RenderMall/style';
import { Line } from 'components/PolicyAgreeRow/style';

const PolicyContents = ({
  stipulationList = [],
  onChangeCheckBox = () => {},
  onClickViewBtn = () => {},
  noText = false,
}) => {
  return (
    <>
      {noText ? (
        ''
      ) : (
        <TextBox>
          대표자 명의의 휴대폰 본인인증 후
          <br />
          대출 신청할 수 있어요.
        </TextBox>
      )}
      {stipulationList &&
        stipulationList.length > 0 &&
        stipulationList[0].stipulationSeq > 0 && <Line />}
      {stipulationList &&
        stipulationList.length > 0 &&
        stipulationList[0].stipulationSeq > 0 &&
        stipulationList.map((item, index) => {
          const lastRequiredIndex = stipulationList
            .map(({ required }) => required)
            .lastIndexOf(true);

          return (
            <PolicyAgreeRow
              key={`checkBoxList_${index}_${item.stipulationSeq}_${item.checked}`}
              item={item}
              index={index}
              lastRequiredIndex={lastRequiredIndex}
              onChangeCheckBox={onChangeCheckBox}
              onClickViewBtn={onClickViewBtn}
            />
          );
        })}
    </>
  );
};

export default PolicyContents;
