import React, { useEffect, useState } from 'react';
import { createDataRequest, getListDataRequest } from 'utils/request';
import { LINKAGE_FAIL_USER_MESSAGES } from 'pages/MyPage/DataLinkage/constants.js';
import { NoBtnModal, TwoVerticalBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import DataInterlockLinkInfo from './DataInterlockLinkInfo';
import { FirstText, TextBlue } from 'pages/MyPage/DataLinkage/style.js';
import {
  ChildText,
  ChildTextContent,
  ChildTextRow,
  ChildTextSpan,
  ContentBox,
  LinkageGuideLink,
} from 'pages/MyPage/DataLinkage/DataLinkageRequest/style.js';

const IndividualRequestStep1 = ({
  auth,
  setStep,
  setDataProviderSeq,
  dataInterlockInfo,
  setDataInterlockInfo,
}) => {
  const { mallName = '' } = dataInterlockInfo;
  const [dataProviderIndividualList, setDataProviderIndividualList] = useState(
    []
  );
  const dataProviderSeq =
    dataProviderIndividualList &&
    dataProviderIndividualList[0]?.dataProviderSeq;
  const [_dataInterlockMallList, _setDataInterlockMallList] = useState([]);
  const [dataInterlockMallList, setDataInterlockMallList] = useState([]);
  const [mallAuthValidateTryCount, setMallAuthValidateTryCount] = useState(0);
  const [isOpenTwoBtnModal, setIsOpenTwoBtnModal] = useState(false);

  // 2차 인증
  const [isChecked, setIsChecked] = useState(false);
  const [captchaData, setCaptchaData] = useState({});

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    getListDataRequest({
      url: '/v1/au/data-provider/list/individual',
      setData: setDataProviderIndividualList,
    });
  }, []);

  useEffect(() => {
    if (!!dataProviderSeq) {
      setDataProviderSeq(dataProviderSeq);

      getListDataRequest({
        url: '/v1/au/salesMall/individual/interlock/mall',
        setData: _setDataInterlockMallList,
        params: { dataProviderSeq },
      });
    }
  }, [dataProviderSeq]);

  useEffect(() => {
    setDataInterlockMallList([
      {
        label: '판매몰 선택',
        value: '0',
      },
      ..._dataInterlockMallList.map(item => {
        return {
          label: `${item.mallName}`,
          value: `${item.mallSeq}`,
          ...item,
        };
      }),
    ]);
  }, [_dataInterlockMallList]);

  const onClickCheckAuth = event => {
    if (mallAuthValidateTryCount === 2 && !captchaData?.captchaUrl) {
      setIsOpenTwoBtnModal(true);
      setModalMessage(
        `계정을 2회 이상 틀리셨습니다.\n계속 틀릴 경우 아이디가 잠금 처리됩니다.\n해당 판매몰에서 아이디/비밀번호 찾기를 진행 후 계정 확인하는 것을 권장 드립니다.\n계속 진행하시겠습니까?`
      );
      setMallAuthValidateTryCount(3);
    } else {
      const { mallSeq = 0, mallId = '', mallPassword = '' } = dataInterlockInfo;
      let postData = {
        dataProviderSeq,
        mallSeq,
        mallId,
        mallPassword,
      };
      const { captchaId = '', captchaValue = '' } = captchaData;
      if (!!captchaId) {
        postData.captchaId = captchaId;
        postData.captchaValue = captchaValue;
      }

      const successFnc = data => {
        setStep(2);
      };
      const errorFnc = (data, message) => {
        const { errorCode = '' } = data;
        setMallAuthValidateTryCount(prev => prev + 1);

        let errorMessageByErrorCode = errorCode
          ? LINKAGE_FAIL_USER_MESSAGES[errorCode]
          : LINKAGE_FAIL_USER_MESSAGES.ETC;

        if (errorCode === 'E11420') {
          errorMessageByErrorCode = `판매몰 회원 정책으로 인해 로그인이 실패하였습니다. ${mallName}의 고객센터로 문의해주세요.`;
        } else if (errorCode === 'E11415') {
          setCaptchaData({ ...data, captchaValue: '' });
        } else if (errorCode === 'E11900') {
          setIsChecked(false);
        }

        setModalMessage(errorMessageByErrorCode);
        setIsModalOpen(true);
      };

      const serverErrorFnc = () => {
        setMallAuthValidateTryCount(prev => prev + 1);
      };

      createDataRequest({
        url: '/v1/au/data-provider/individual/mallAuthValidate',
        data: postData,
        successFnc,
        errorFnc,
        serverErrorFnc,
        setModalMessage,
        setShowAlertModal: setIsModalOpen,
      });
    }
  };

  const onClickCloseModal = () => {
    setIsModalOpen(false);
  };

  /* 계정 2회 이상 오류 모달 */
  // top 버튼
  const onClickCloseTwoBtnModal = () => {
    setIsOpenTwoBtnModal(false);
  };

  // bottom 버튼
  const onClickBottomBtn = () => {
    setIsOpenTwoBtnModal(false);
    onClickCheckAuth();
  };

  return (
    <div>
      <FirstText>
        <TextBlue>{auth.businessName}</TextBlue>님 쇼핑몰의 계정 정보를 입력 후
        <br />
        데이터 연동을 진행해 주세요.
      </FirstText>

      <ContentBox>
        <ChildText>
          <ChildTextRow>
            <ChildTextSpan bold>사업자 번호</ChildTextSpan>
            <ChildTextContent>
              {!!auth.businessRegistrationNumber &&
                auth.businessRegistrationNumber}
            </ChildTextContent>
          </ChildTextRow>

          <LinkageGuideLink
            target="_blank"
            rel="noopener noreferrer"
            href={
              !!mallName
                ? `${window.location.origin}/data-linkage/guide?mallName=${mallName}`
                : `${window.location.origin}/data-linkage/guide`
            }
          >
            연동 가이드 보기
          </LinkageGuideLink>
        </ChildText>

        <DataInterlockLinkInfo
          dataInterlockMallList={dataInterlockMallList}
          dataInterlockInfo={dataInterlockInfo}
          setDataInterlockInfo={setDataInterlockInfo}
          idPlaceholder={`${mallName} 아이디`}
          pwPlaceholder={`${mallName} 비밀번호`}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          captchaData={captchaData}
          setCaptchaData={setCaptchaData}
          onClickActiveBtn={onClickCheckAuth}
          dataProviderSeq={dataProviderSeq}
        />
      </ContentBox>

      {/* 에러메세지 모달 */}
      <NoBtnModal showModal={isModalOpen} onClose={onClickCloseModal}>
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>

      {/* 계정 2회 이상 오류 모달 */}
      <TwoVerticalBtnModal
        showModal={isOpenTwoBtnModal}
        onClose={onClickCloseTwoBtnModal}
        isVerticalBtn={true}
        topButton="아니요, 계정을 확인해 볼게요"
        bottomButton="네, 계속 진행할게요."
        onClickTopBtn={onClickCloseTwoBtnModal}
        onClickBottomBtn={onClickBottomBtn}
      >
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </TwoVerticalBtnModal>
    </div>
  );
};

export default IndividualRequestStep1;
