import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { getListDataRequest } from 'utils/request';
import SearchBox from 'components/SearchBox';
import { NoBtnModal } from 'components/Modal';
import SearchCategorySection from './SearchCategorySection';
import NoListText from 'components/NoListText';
import { Contents, ScrollArea } from 'components/Modal/style';
import { SearchBoxWrapper } from '../QnATab/style';

const FAQTab = () => {
  const [faqCategoryList, setFaqCategoryList] = useState([]);
  const [_faqList, _setFaqList] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [mallList, setMallList] = useState([]);
  const [selectedMallSeq, setSelectedMallSeq] = useState('');
  // 검색어
  const [searchValue, setSearchValue] = useState('');
  const [findValue, setFindValue] = useState('');
  const [showNoBtnModal, setShowNoBtnModal] = useState(false);

  // pagination
  const [paginationData, setPaginationData] = useState({});
  const [page, setPage] = useState(0);
  // 무한스크롤
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [hasMore, setHasMore] = useState(true);

  const selectedCategory = faqCategoryList.find(
    item => item.isClicked === true
  );

  useEffect(() => {
    getListDataRequest({
      url: '/v1/na/faq/code',
      successFnc: list => {
        if (list.length > 0) {
          setFaqCategoryList(
            [
              {
                code: 'FAQ100',
                codeType: 'FAQ',
                codeName: '자주 찾는 질문',
                codeOrder: 1,
              },
              ...list,
            ]
              .sort((a, b) => a.codeOrder - b.codeOrder)
              .map((item, index) =>
                item.codeOrder === 1 || index === 0
                  ? { ...item, isClicked: true }
                  : { ...item, isClicked: false }
              )
          );
        }
      },
    });
    // 연동가능한 상태인 판매몰 리스트 조회
    getListDataRequest({
      url: '/v1/na/faq/only-y/interlock/mall',
      params: {
        dataProviderSeq: 10002,
      },
      successFnc: list => {
        setMallList([
          { label: '판매몰 전체', value: '' },
          ...list.map(item => ({ value: item.mallSeq, label: item.mallName })),
        ]);
      },
    });
  }, []);

  const onClickCategoryBtn = code => {
    setSearchValue(''); // 검색어 초기화
    resetPage();
    setFaqCategoryList(list =>
      list.map(item =>
        item.code === code
          ? { ...item, isClicked: true }
          : { ...item, isClicked: false }
      )
    );
  };

  const resetPage = () => {
    _setFaqList([]);
    setPaginationData({});
    setPage(0);
    setSelectedMallSeq('');
  };

  const onChangeMall = event => {
    resetPage();
    setSelectedMallSeq(event.target.value);
  };

  const onChangeSearchValue = event => {
    setSearchValue(event.target.value);
  };

  const onClickSearch = () => {
    // 카테고리 셀렉 초기화
    setFaqCategoryList(list =>
      list.map(item => ({ ...item, isClicked: false }))
    );
    resetPage();

    setFindValue(searchValue);

    if (!searchValue.trim()) {
      setShowNoBtnModal(true);
      setSearchValue('');
      return;
    }
    getListDataRequest({
      url: '/v1/na/faq',
      params: { pageNo: 1, searchValue: searchValue },
      setData: _setFaqList,
      setPaginationData,
    });
  };

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory.code !== 'FAQ100') {
        const params = {
          pageNo: page + 1,
          classificationCode: selectedCategory.code,
        };
        // 데이터 연동에서 판매몰 선택
        if (!!selectedMallSeq && selectedCategory.code === 'FAQ120') {
          params.mallSeq = selectedMallSeq;
        }
        getListDataRequest({
          url: '/v1/na/faq',
          params: params,
          setData: _setFaqList,
          setPaginationData,
        });
      } else if (selectedCategory.code === 'FAQ100' && page === 0) {
        setTimeout(() => {
          setHasMore(true);
        }, 250);
      }
    } else if (!!findValue) {
      // 검색어는 돋보기 버튼 클릭했을때 조회
      getListDataRequest({
        url: '/v1/na/faq',
        params: { pageNo: page + 1, searchValue: findValue },
        setData: _setFaqList,
        setPaginationData,
      });
    }
  }, [selectedCategory, page, selectedMallSeq]);

  // 페이지네이션 대신 무한스크롤 적용하는 '자주 찾는 질문' 항목
  useEffect(() => {
    if (selectedCategory?.code === 'FAQ100') {
      if (inView === true && hasMore === true) {
        getListDataRequest({
          url: '/v1/na/faq',
          params: {
            pageNo: page + 1,
            classificationCode: selectedCategory.code,
          },
          setData: _setFaqList,
          setPaginationData,
        });
      }
    }
  }, [inView, hasMore]);

  useEffect(() => {
    if (selectedCategory?.code === 'FAQ100') {
      const list = _faqList;
      const paging = paginationData;

      // 1. 리스트 데이터가 없을 때
      if (list.length === 0) {
        setHasMore(false);
        setFaqList([]);
        // 2. 리스트 데이터가 있을 때
      } else {
        // 현재 페이지가 1이고 마지막 페이지도 1일 때
        if (paging?.endPageNo === 1) {
          setHasMore(false);
          setFaqList(list);
          // 현재 페이지가 1이고 마지막 페이지가 1이 아닐 때
        } else if (paging?.pageNo === 1 && paging?.endPageNo !== 1) {
          setHasMore(true);
          setFaqList(list);
          setPage(prev => prev + 1);
          // 현재 페이지가 1이 아니고 마지막 페이지보다 현재 페이지가 적을 때
        } else if (paging?.pageNo !== 1 && paging?.pageNo < paging?.endPageNo) {
          setHasMore(true);
          setFaqList(prev => [...prev, ...list]);
          setPage(prev => prev + 1);
          // 현재 페이지가 1이 아니고 마지막 페이지랑 현재 페이지랑 같을 때
        } else if (
          paging?.pageNo !== 1 &&
          paging?.pageNo === paging?.endPageNo
        ) {
          setHasMore(false);
          setFaqList(prev => [...prev, ...list]);
        }
      }
    } else {
      setFaqList(
        _faqList
          .sort((a, b) => a.sortingOrder - b.sortingOrder)
          .sort((a, b) => a.regDt - b.regDt)
          .map((item, index) => ({ ...item, isOpen: false }))
      );
    }
  }, [_faqList]);

  return (
    <>
      <SearchBoxWrapper>
        <SearchBox
          placeholder="궁금하신 점을 빠르게 검색해 보세요."
          value={searchValue}
          onClickSearch={onClickSearch}
          onChange={onChangeSearchValue}
          maxLength={14}
        />
      </SearchBoxWrapper>

      {faqCategoryList.length > 0 ? (
        <>
          <SearchCategorySection
            faqCategoryList={faqCategoryList}
            onClickCategoryBtn={onClickCategoryBtn}
            selectedCategory={selectedCategory}
            findValue={findValue}
            faqList={faqList}
            setFaqList={setFaqList}
            page={page}
            setPage={setPage}
            paginationData={paginationData}
            mallList={mallList}
            onChangeMall={onChangeMall}
            selectedMallSeq={selectedMallSeq}
          />
          <div ref={ref} />
        </>
      ) : (
        <NoListText
          title="자주 묻는 질문이 없습니다."
          description="궁금한 점이 있으시면 고객센터로 문의해 주세요."
        />
      )}

      <NoBtnModal
        showModal={showNoBtnModal}
        onClose={() => {
          setShowNoBtnModal(false);
        }}
      >
        <ScrollArea>
          <Contents>검색어를 입력해주세요.</Contents>
        </ScrollArea>
      </NoBtnModal>
    </>
  );
};

export default FAQTab;
