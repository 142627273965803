import styled from 'styled-components';
import theme from 'styles/theme';

export const CardWrapper = styled.div`
  width: 100%;
  padding: 20px 0;
  border-top: ${props =>
    props.index > 0 ? `1px solid ${theme.borderColor.lightGrey}` : `none`};
`;

export const CardColumn = styled.div``;

export const CardName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: 500;
  color: ${theme.color.black};
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const CardNameStatus = styled.div`
  color: ${theme.color.grey9};
  font-weight: 400;
`;

export const DetailButton = styled.button`
  padding-right: 12px;
  font-size: 1.6rem;
  font-weight: 500;
  background: url(/images/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
  color: ${theme.color.infoBlue};
`;

export const CardContent = styled.div`
  margin-top: 10px;
  line-height: 1.3rem;
  font-size: 1.3rem;
  font-weight: 400;
`;
