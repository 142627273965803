import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import StyledLinkButton from 'components/Button/StyledLinkButton';
import { NoBtnModal } from 'components/Modal';
import { ScrollArea, Contents } from 'components/Modal/style';
import {
  ContentSubpage,
  TextMedium,
  ContentBox,
  ContentTitle,
  ContentData,
  ContentText,
  ContentBlueText,
  ButtonSection,
} from './style';

const SearchIdSuccess = () => {
  const { login } = ROUTE_PATH;
  const location = useLocation();
  const bizData = location.state;
  const navigate = useNavigate();

  const onClickLoginBtn = () => {
    navigate(login.login);
  };
  const onClickSearchPWBtn = () => {
    navigate(login.searchPassword);
  };

  // 오류 모달 alertMessage
  const [alertMessage, setAlertMessage] = useState('');
  return (
    <ContentSubpage>
      {bizData ? (
        <>
          <TextMedium>아이디를 찾았어요.</TextMedium>
          <ContentBox>
            <ContentTitle>아이디</ContentTitle>
            <ContentData>
              <ContentBlueText>{bizData && bizData.memberId}</ContentBlueText>
            </ContentData>
            <ContentTitle>상호명</ContentTitle>
            <ContentData>
              <ContentText>{bizData && bizData.bizName}</ContentText>
            </ContentData>
            <ContentTitle>대표자</ContentTitle>
            <ContentData>
              <ContentText>{bizData && bizData.representativeName}</ContentText>
            </ContentData>
          </ContentBox>
          <ButtonSection>
            <StyledActiveButton tabIndex={0} onClick={onClickLoginBtn}>
              로그인
            </StyledActiveButton>
            <StyledLinkButton
              tabIndex={0}
              onClick={onClickSearchPWBtn}
              halfButton
            >
              비밀번호 찾기
            </StyledLinkButton>
          </ButtonSection>
        </>
      ) : null}
      {alertMessage && (
        <NoBtnModal
          showModal={alertMessage}
          onClose={() => setAlertMessage('')}
        >
          <ScrollArea>
            <Contents>{alertMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      )}
    </ContentSubpage>
  );
};

export default SearchIdSuccess;
