import React from 'react';
import SelectBox from 'components/SelectBox';
import { TwoBtnModal } from 'components/Modal';
import { loanStatusList } from '../constants';
import { Contents, ScrollArea } from 'components/Modal/style';

const FilterModal = ({
  showModal,
  financeInstitutionList,
  managerList,
  selectedFilter,
  handleSelectedFilter,
  onClose,
  onReset,
  onClick,
}) => {
  const { finance, employee, status } = selectedFilter;
  return (
    <TwoBtnModal
      title="필터"
      leftButton="초기화"
      rightButton="필터적용"
      showModal={showModal}
      onClose={onClose}
      onReset={onReset}
      onClick={onClick}
    >
      <ScrollArea title="title">
        <Contents>
          <SelectBox
            optionList={financeInstitutionList}
            name="finance"
            value={finance.value}
            optionHandleChange={handleSelectedFilter}
          />
          <SelectBox
            optionList={managerList}
            name="employee"
            value={employee.value}
            optionHandleChange={handleSelectedFilter}
          />
          <SelectBox
            optionList={loanStatusList}
            name="status"
            value={status.value}
            optionHandleChange={handleSelectedFilter}
          />
        </Contents>
      </ScrollArea>
    </TwoBtnModal>
  );
};

export default FilterModal;
