const ROUTE_PATH = {
  serviceView: '/service-view',
  event: '/event',
  eventDetailDefault: '/event/detail',
  eventDetail: '/event/detail/:seq',
  serviceCenter: {
    noAuth: {
      create: '/service-center/contact/noauth',
    },
    auth: {
      create: '/service-center/contact',
    },
    main: '/service-center',
    noticeDetailDefault: '/popup/service-center/notice',
    noticeDetail: '/popup/service-center/notice/:seq',
    faqDetailDefault: '/popup/service-center/faq',
    faqDetail: '/popup/service-center/faq/:seq',
    qnaPolicyDefault: '/popup/policy/qna',
    qnaPolicy: '/popup/policy/qna/:seq',
  },
  login: {
    login: '/login',
    searchId: '/searchid',
    searchIdIdentification: '/searchid/identification',
    searchIdResultFail: '/searchid/result-fail',
    searchIdResultSuccess: '/searchid/result-success',
    searchPassword: '/searchpassword',
    searchPasswordIdentification: '/searchpassword/identification',
    searchPasswordResultFail: '/searchpassword/result-fail',
    searchPasswordResetPassword: '/searchpassword/reset-password',
    joinMember: '/joinmember',
    joinMemberCheckBusinum: '/joinmember/check-businum',
    joinMemberInput: '/joinmember/input',
    joinMemberResult: '/member/joinmember/result',
    joinMemberPolicyDefault: '/popup/policy/join-member',
    joinMemberPolicy: '/popup/policy/join-member/:seq',
  },
  mypage: {
    noAuth: '/mypage/noauth',
    main: '/mypage/main',
    myInform: '/mypage/myinform',
    updateBusiness: '/mypage/myinform/update-business',
    updatePassword: '/mypage/myinform/update-password',
    updateEmail: '/mypage/myinform/update-email',
    withdrawal: '/mypage/myinform/withdrawal',
    manager: '/mypage/manager',
    managerRegister: '/mypage/manager/register',
    managerModify: '/mypage/manager/modify',
    checkLoan: '/mypage/loan',
    checkLoanList: '/mypage/loan/list',
    checkCreditLoanList: '/mypage/credit-loan/list',
    checkLoanDetail: '/mypage/loan/detail',
    checkCreditLoanDetail: '/mypage/credit-loan/detail',
    manageLoan: '/mypage/loan/manage',
    dataLinkageManage: '/data-linkage/manage',
    dataLinkageDataInterlockRequest: '/data-linkage/request/data-interlock',
    dataLinkageDataProviderRequest: '/data-linkage/request/data-provider',
    dataLinkageModify: '/data-linkage/modify',
    dataLinkageGuide: '/data-linkage/guide',
    estimatedAmountManage: '/mypage/data',
    estimatedAmountDetail: '/mypage/data/detail',
    requestLoan: '/mypage/request/loan',
    requestCreditLoan: '/mypage/request/credit-loan',
  },
  deepLink: {
    default: '/data-provide-agree',
    secured: '/data-provide-agree/secured',
    unsecured: '/data-provide-agree/unsecured',
    securedDetail: '/data-provide-agree/secured/:seq',
    unsecuredDetail: '/data-provide-agree/unsecured/:seq',
    agree: '/data-provide-agree/agree',
    serviceGuide: '/deeplink', // 키움 금융사를 제외하고 공통적으로 사용될 화면
    policyDefault: '/popup/policy/data-provide-agree',
    policy: '/popup/policy/data-provide-agree/:seq',
    joinMember: '/joinmember/:hash',
    financeAppInterlock: '/finance-app-interlock/member-join',
  },
  identification: '/identification',
  identificationSuccessCallback: '/identificationSuccessCallback',
  identificationFailCallback: '/identificationFailCallback',
  mallSecondAuth: '/malls/second-auth',
  policyDefault: '/popup/policy',
  policy: '/popup/policy/:seq',
  financialSupervisoryService: '/popup/financial-supervisory-service',
  affiliateFinance: '/popup/affiliate-finance',
};

export default ROUTE_PATH;

export const aizenGlobalAboutUs = 'https://aizenglobal.com/ko/about-us/';
export const sellerbotCash = 'https://www.sellerbot.co.kr/';
export const sellerbotCashMember =
  'https://www.sellerbot.co.kr/pub/member/step1';
