import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const PopupSection = styled.div`
  @media screen and (max-width: 1199.9px) {
    display: none;
  }
`;

export const PopupWrapper = styled.div`
  display: block;
  position: fixed;
  z-index: 3000;
  width: 350px;
  min-width: 265px;
  max-width: 440px;
  top: calc(50% + 30px);
  left: calc(10% + 35px);

  padding: 0 12px;
  background: ${theme.backgroundColor.white};
  border-radius: 20px;
  transform: translateY(-50%);

  border: 1px ${theme.color.lightGrey} solid;
`;

export const SideArea = styled.div`
  display: block;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  min-width: ${theme.layout.minWidth};
  max-width: ${theme.layout.maxWidth};
  min-height: calc(
    (var(--vh, 1vh) * 100) + var(--ios-safe-top) + var(--ios-safe-bottom)
  );
  top: 0;
  left: calc(50% + 12px);

  margin: 0;

  @media only screen and (min-width: 1200px) {
    left: calc(50% + 12px);
    margin: 0;
  }
`;

export const PopupHeader = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 50px;

  padding: 18px 0 0;
  text-align: center;
  border-radius: 20px 20px 0 0;
`;

export const Title = styled.div`
  display: block;
  width: 100%;

  text-align: center;
  line-height: 3rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};
`;

export const PopupClose = styled.button`
  display: block;
  position: absolute;
  z-index: 3001;
  width: 50px;
  height: 54px;
  top: 0;
  right: 0;

  font-size: 0;
  background: url('/images/close-26-r-b4.png') 28px 24px no-repeat;
  background-size: 14px 14px;

  @media screen and (max-width: 375px) {
    background-size: 10px 10px;
  }
`;

export const ScrollArea = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  width: 100%;
  padding: 8px 8px 0;
  margin-bottom: 40px;
  max-height: calc(100vh - 366px);

  @media screen and (max-width: 375px) {
    margin-bottom: 30px;
  }

  ${props => {
    if (props.marginTop) {
      return css`
        margin-top: 20px;
      `;
    }
    if (props.title) {
      return css`
        margin-bottom: 18px;
      `;
    } else if (props.dataLink || props.marginTop) {
      return css`
        margin-top: 20px;
      `;
    }
  }}

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${theme.backgroundColor.blue};
    border-radius: 0;
    background-clip: padding-box;
    border-left: 0 solid transparent;
  }

  &::-webkit-scrollbar-track {
    background-color: ${theme.backgroundColor.scrollIcyBlue};
    border-radius: 0;
  }
`;

export const Contents = styled.div`
  display: block;
  width: 100%;
  padding: 0;
  line-height: 2.4rem;
  font-size: 1.6rem;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 500)};
  text-align: ${props => (props.textAlign ? props.textAlign : 'center')};
  color: ${theme.color.black};

  white-space: pre-line;

  @media screen and (max-width: 375px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 1.4rem;
  }
`;

export const PopupBtnWrap = styled.div`
  display: flex;
  width: calc(100% + 24px);
  height: 54px;

  transform: translateX(-12px);

  @media screen and (max-width: 320px) {
    height: 45px;
  }
`;

export const PopupBtnLeft = styled.button`
  display: block;
  float: left;
  width: 50%;
  height: 54px;
  //line-height: 4.8rem; // 버튼 텍스트가 두줄 될 수 있음
  font-size: 1.6rem;
  text-align: center;
  font-weight: 700;
  color: ${theme.color.white};
  background: ${theme.backgroundColor.grey5};
  border: 1px solid ${theme.borderColor.grey5};
  border-radius: 0 0 0 20px;

  @media screen and (max-width: 320px) {
    height: 45px;
    font-size: 1.3rem;
  }
`;

export const PopupBtnRight = styled.button`
  display: block;
  width: ${({ width }) => (width ? width : '50%')};
  height: 54px;
  //line-height: 4.8rem; // 버튼 텍스트가 두줄 될 수 있음
  font-size: 1.6rem;
  text-align: center;
  font-weight: 700;
  color: ${theme.color.white};
  background: ${theme.backgroundColor.blue};
  border: 1px solid ${theme.borderColor.blue};
  border-radius: 0 0 20px 0;
  float: left;

  @media screen and (max-width: 320px) {
    height: 45px;
    font-size: 1.3rem;
  }
`;

export const ImgSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const PopupImg = styled.img`
  width: 291px;
  height: 182px;
`;

export const PopupTitle = styled.p`
  width: 90%;
  margin: 1.9rem auto;
  font-size: 2rem;
  font-weight: 700;
  display: block;
  padding-top: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
`;

export const PopupText = styled.p`
  width: 90%;
  height: 120px;
  margin: 0 auto;
  font-size: 1.7rem;
  line-height: 2.6rem;
  font-weight: 400;
  color: #777777;
`;

export const CheckboxSection = styled.div`
  margin: 0 0 1.8rem 1rem;
`;
