import React from 'react';
import {
  SearchBoxSection,
  SearchRow,
  SearchBtn,
  SearchBtnText,
  InputNormal,
  ResetBtn,
  ResetBtnText,
} from './style';

const SearchBox = ({
  onClickSearch,
  onClickReset,
  placeholder,
  value,
  onChange,
  maxLength,
  isSearchBtnClicked = false,
  ...children
}) => {
  return (
    <SearchBoxSection>
      <SearchRow>
        <InputNormal
          type="search"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          {...children}
        />
        {!!isSearchBtnClicked ? (
          <ResetBtn tabIndex={0} onClick={onClickReset}>
            <ResetBtnText />
          </ResetBtn>
        ) : (
          <SearchBtn tabIndex={0} onClick={onClickSearch}>
            <SearchBtnText />
          </SearchBtn>
        )}
      </SearchRow>
    </SearchBoxSection>
  );
};

export default SearchBox;
