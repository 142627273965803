import React from 'react';
import { useNavigate } from 'react-router-dom';
import ROUTE_PATH from 'routes/config';
import { getDeepLinkType, getEssentialMalls } from 'tools/WebTool';
import { TwoBtnModal } from 'components/Modal';
import GuideBox from 'components/GuideBox';
import {
  Contents,
  ContentsBox,
  NoScrollArea,
  ScrollArea,
} from 'components/Modal/style';
import { DEEPLINK_TYPE } from '../../../DeepLink/constants';

const JoinMemberStep3 = ({ businessManagerHash = '', goodsData = {} }) => {
  const navigate = useNavigate();
  const deepLinkType = getDeepLinkType();
  const essentialMall = getEssentialMalls();

  // DEEPLINK_TYPE.joinMember - 현재는 금융사 안내 페이지 진입을 의미합니다
  if (
    goodsData.errorCode ||
    (deepLinkType !== DEEPLINK_TYPE.joinMember &&
      essentialMall?.length === 0 &&
      goodsData.dataProvideType === 'UNSECURED')
  ) {
    // 딥링크 타고 들어온 회원이 회원가입 하는 경우
    return (
      <div>
        <TwoBtnModal
          showModal={true}
          onClose={() => navigate('/')}
          onReset={() => navigate('/')}
          onClick={() =>
            navigate(ROUTE_PATH.deepLink.agree, {
              state: {
                goodsData,
              },
            })
          }
          leftButton="닫기"
          rightButton="데이터제공 동의하기"
        >
          <ScrollArea>
            <Contents>
              {`회원가입을 축하드려요.\n
                데이터 제공 동의 후
                판매몰 연동을 진행해 주세요.
                `}
            </Contents>
          </ScrollArea>
        </TwoBtnModal>
      </div>
    );
  }

  if (!!businessManagerHash) {
    // 딥링크 타고 들어온 회원이 회원가입 하는 경우
    return (
      <div>
        <TwoBtnModal
          showModal={true}
          onClose={() => navigate('/')}
          onReset={() => navigate('/')}
          onClick={() => navigate(ROUTE_PATH.mypage.dataLinkageManage)}
          leftButton="닫기"
          rightButton="데이터 연동하기"
        >
          <NoScrollArea>
            <Contents>
              회원가입을 축하드려요.
              <br />
              운영중인 판매몰을 연동해 주세요.
              <br />
            </Contents>
            <ContentsBox>
              <GuideBox
                guideMessage={
                  <>
                    <div>셀러 크레딧커넥트에서는 데이터가 곧 신용!</div>
                    <div>데이터를 불러오기 위해 데이터 연동이 필요해요.</div>
                    <div>
                      등록된 판매몰이 많을수록 연동할 데이터가 많아 대출 신청에
                      유리해요.
                    </div>
                  </>
                }
              />
            </ContentsBox>
          </NoScrollArea>
        </TwoBtnModal>
      </div>
    );
  }

  // * 기존 프로세스 아카이빙
  // if (deepLinkType === DEEPLINK_TYPE.joinMember) {
  //   const goToRedirectUrl = () =>
  //     navigate(
  //       `${ROUTE_PATH.deepLink.serviceGuide}?financialInstitution=${interlockHash}&type=${DEEPLINK_TYPE.joinMember}`
  //     );
  //   return (
  //     <OneBtnModal
  //       showModal={true}
  //       onClose={goToRedirectUrl}
  //       onClick={goToRedirectUrl}
  //       btnContent="닫기"
  //     >
  //       <ScrollArea>
  //         <Contents style={{ marginTop: '15px' }}>
  //           회원가입을 축하드려요.
  //           <br />
  //           {financialInstitutionName} 대출 진행을 위해
  //           <br />
  //           안내 화면을 다시 한번 확인해 주세요.
  //         </Contents>
  //       </ScrollArea>
  //     </OneBtnModal>
  //   );
  // }

  return (
    <TwoBtnModal
      showModal={true}
      onClose={() => navigate('/')}
      onClick={() => navigate(ROUTE_PATH.mypage.dataLinkageManage)}
      leftButton="닫기"
      rightButton="데이터 연동하기"
    >
      <ScrollArea>
        <Contents>
          회원가입을 축하드려요.
          <br />
          판매몰 연동을 진행해 주세요.
          <br />
          데이터를 불러오기 위해 데이터 연동이 필요해요.
        </Contents>
      </ScrollArea>
    </TwoBtnModal>
  );
};

export default JoinMemberStep3;
