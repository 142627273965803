import React from 'react';
import {
  Table,
  TableHead,
  TableHeadRow,
  TableHeadCell,
  TableBody,
  TableDataRow,
  TableDataCell,
} from './style';

const DataTable = ({ tableName, tableHead, tableData }) => {
  const tableLayout = item => {
    if (tableName === 'CreditProduct') {
      return (
        <>
          <TableDataCell smallFont={tableName === 'CreditProduct'}>
            {item.dataProviderName}
          </TableDataCell>
          <TableDataCell smallFont={tableName === 'CreditProduct'}>
            {item.allCount} 건
          </TableDataCell>
          <TableDataCell smallFont={tableName === 'CreditProduct'}>
            {item.availableCount} 건
          </TableDataCell>
        </>
      );
    } else if (tableName === 'NoCreditProduct') {
      return (
        <>
          <TableDataCell smallFont>-</TableDataCell>
          <TableDataCell smallFont>-</TableDataCell>
          <TableDataCell smallFont>-</TableDataCell>
        </>
      );
    } else if (tableName === 'CreditRequest') {
      return (
        <>
          <TableDataCell smallFont={tableName === 'CreditProduct'}>
            {item?.historyDate ? item.historyDate : '-'}
          </TableDataCell>
          <TableDataCell smallFont={tableName === 'CreditProduct'}>
            {item?.creditStatus ? item.creditStatus : '-'}
          </TableDataCell>
        </>
      );
    } else {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
  };

  return (
    <Table>
      <TableHead>
        <TableHeadRow>
          {tableHead?.map((item, index) => {
            return (
              <TableHeadCell
                key={index}
                smallFont={tableName === 'CreditProduct'}
              >
                {item}
              </TableHeadCell>
            );
          })}
        </TableHeadRow>
      </TableHead>
      <TableBody>
        {tableData && tableData.length > 0 ? (
          tableData.map((item, index) => {
            return <TableDataRow key={index}>{tableLayout(item)}</TableDataRow>;
          })
        ) : (
          <TableDataRow>{tableLayout()}</TableDataRow>
        )}
      </TableBody>
    </Table>
  );
};

export default DataTable;
