import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApp from 'hooks/useApp';
import {
  createDataRequest,
  getListDataRequest,
  getResultDataRequest,
} from 'utils/request';
import { KEY_MEMBERCERTIFICATIONSEQ } from 'tools/WebTool';
import ROUTE_PATH from 'routes/config';
import BlueAlertBox from 'components/BlueAlertBox';
import { LoadingBar } from 'components/LoadingIndicator';
import { NoBtnModal } from 'components/Modal';
import SelectMallModal from 'components/SelectMallModal';
import DataProviderTable from './DataProviderTable';
import LinkedDatatStatus from './LinkedDatatStatus';
import NoLinkedDataStatus from './NoLinkedDataStatus';
import FooterInfo from './FooterInfo';
import IdentificationModal from './IdentificationModal';
import { Contents, ScrollArea } from 'components/Modal/style';
import {
  LoanLimitWrapper,
  MainSectionTwo,
  TextMedium,
  TextBlue,
  Overlay,
  LoadingMessage,
} from './style';

const isTestLoanLimitAvailable =
  process.env.REACT_APP_TEST_LOAN_LIMIT_AVAILABLE;

const LoanLimitBasicPage = () => {
  const navigate = useNavigate();
  const { auth } = useApp();

  // 메인페이지 데이터
  const { businessName, savedMemberCertificationSeq, memberRoleData } = auth;
  const tableHead = [
    '데이터 제공사',
    '연동된 판매몰',
    '담보 대출\n가능 판매몰',
    '신용 대출\n가능 판매몰',
  ];
  const [dataProviderList, setDataProviderList] = useState([]);
  const [dataLinkageStatus, setDataLinkageStatus] = useState('');

  // 대출 비교 타입
  const [mallModalType, setMallModalType] = useState('');
  const dataUseType =
    mallModalType === 'credit'
      ? 'CREDIT_LOAN_CMPRS'
      : mallModalType === 'guarantee'
      ? 'LOAN_CMPRS'
      : '';

  // loading
  const [showLoading, setShowLoading] = useState(false);

  // 모달창 state
  const [showIdentificationModal, setShowIdentificationModal] = useState(false); // 휴대폰 본인인증 모달
  const [showSelectMallModal, setShowSelectMallModal] = useState(false); // 판매몰 선택 모달
  const [hasResetCookie, setHasResetCookie] = useState(false);

  // 모달 data
  const [availableMallList, setAvailableMallList] = useState([]); // 사용가능 mall 리스트
  const [checkedMallList, setCheckedMallList] = useState([]); // 사용자가 체크한 몰 리스트
  const checkedMallCount = checkedMallList.length;

  // 약관 목록
  const [stipulationList, setStipulationList] = useState([]);

  // 대출 신청 params
  const [memberCertificationSeq, setMemberCertificationSeq] = useState(0); //본인인증 후 member Seq

  // alert 메시지
  const [showNoBtnModal, setShowNoBtnModal] = useState(false); // 오류 메시지 모달
  const [noBtnModalTitle, setNoBtnModalTitle] = useState('');
  const [alertModalMessage, setAlertModalMessage] = useState(''); // 오류 메시지

  useEffect(() => {
    // 1. 데이터 연동 여부 체크
    getListDataRequest({
      url: 'v1/au/data-provider/manage/interlock-malls',
      setData: setDataProviderList,
      successFnc: successFnc,
    });
  }, []);

  const successFnc = list => {
    // 1-1. 데이터 연동 여부 상태값 저장
    // countNull은 연동된 몰이 없는 데이터 제공사의 갯수, allCount는 연동된 몰 갯수 key name
    const countNull = list.filter(
      ({ allCount }) => allCount === null || allCount === 0
    ).length;

    if (countNull !== list.length) {
      return countNull > 0
        ? setDataLinkageStatus('some') // 연동된 몰이 없는 데이터 제공사가 있는 경우
        : setDataLinkageStatus('all'); // 모든 데이터 제공사에 연동된 몰이 있는 경우
    }
    setDataLinkageStatus('nothing'); // 모든 데이터 제공사에 연동된 몰이 없는 경우
  };

  // 몰 선택 모달 - 몰 선택 버튼
  const onClickSelectMall = () => {
    if (checkedMallList.length > 0) {
      setShowSelectMallModal(false);

      // 본인인증 세션 남아있는 경우
      if (savedMemberCertificationSeq) {
        identificationSuccessCallback(savedMemberCertificationSeq);
        return;
      }
      setShowIdentificationModal(true);
      setAlertModalMessage(
        '신청자의 휴대폰 본인인증 후\n대출 신청할 수 있어요.'
      );
    } else {
      setShowNoBtnModal(true);
      setAlertModalMessage('판매몰을 선택해주세요.');
    }
  };

  // 몰 선택 모달 - 초기화 버튼
  const onClickResetMall = () => {
    // 대출 가능한 몰 목록 조회
    getMallList({
      setAvailableMallList,
      setAlertModalMessage,
      setShowSelectMallModal,
      setShowNoBtnModal,
      dataUseType,
    });
  };

  const getLoanableMall = type => {
    if (isTestLoanLimitAvailable !== 'true') {
      setAlertModalMessage('대출 비교 서비스는 곧 오픈 예정이에요.');
      setShowNoBtnModal(true);
      return;
    }

    if (type === 'credit' && memberRoleData?.representativeYn === 'N') {
      setNoBtnModalTitle('신용 대출 불가 안내');
      setAlertModalMessage('신용대출은 대표자만 신청이 가능합니다.');
      setShowNoBtnModal(true);
      return;
    }

    setMallModalType(type);
    // 대출 가능한 몰 목록 조회
    getMallList({
      setAvailableMallList,
      setAlertModalMessage,
      setShowSelectMallModal,
      setShowNoBtnModal,
      dataUseType: type === 'credit' ? 'CREDIT_LOAN_CMPRS' : 'LOAN_CMPRS',
    });
    if (type === 'credit') {
      // 약관 목록 조회
      getStipulationList({
        setStipulationList,
        stipulationClassificationCd: 'SCC600',
      });
    }
  };

  const agreeList = stipulationList.map(item => ({
    stipulationSeq: item.stipulationSeq,
    agreeYn: item.checked ? 'Y' : 'N',
  }));

  const getCreditGoodsData = () => {
    const data = { agreeList, dataUseType, memberCertificationSeq };

    // 신용대출 휴대폰 본인인증 CI 체크 및 약관 등록
    createDataRequest({
      url: '/v1/au/loans-limit/certification',
      data: data,
      setModalMessage: setAlertModalMessage,
      setShowAlertModal: setShowNoBtnModal,
      errorFnc: () => {
        setHasResetCookie(true);
      },
      serverErrorFnc: () => {
        setShowIdentificationModal(false);
        setHasResetCookie(true);
      },
      successFnc: data => {
        setHasResetCookie(false);
        const loansLimitMallList = checkedMallList.map(
          ({ mallSeq, mallAccountId }) => {
            return { mallSeq, mallAccountId };
          }
        );

        // 계산된 최대한도 조회
        getCreditGoodsList({
          loansLimitMallList,
          loansLimitInquiryRequestSeq: data.loansLimitInquiryRequestSeq,
          dataUseType,
          successCallback: () => {
            mallModalType === 'credit'
              ? navigate(ROUTE_PATH.mypage.checkCreditLoanList, {
                  state: {
                    memberCertificationSeq,
                    usedMallList: loansLimitMallList,
                    agreeList,
                  },
                })
              : navigate(ROUTE_PATH.mypage.checkLoanList, {
                  state: {
                    memberCertificationSeq,
                    usedMallList: loansLimitMallList,
                    agreeList,
                  },
                });
          },
          setShowNoBtnModal,
          setAlertModalMessage,
        });
      },
    });
  };

  // 4.2초 후에 페이지 전환하는 useEffect
  useEffect(() => {
    let intervalId;
    if (showLoading) {
      intervalId = setInterval(() => {
        setShowLoading(false);
        getCreditGoodsData();
      }, 4200);
    }
    return () => clearInterval(intervalId);
    // 언마운트시 타이머 해제
  }, [showLoading]);

  const onCloseIdentificationModal = () => {
    setShowIdentificationModal(false);
  };

  // 본인인증 완료(성공) : decode API가 실행된 이후에 실행할 함수
  const identificationSuccessCallback = memberCertificationSeq => {
    setMemberCertificationSeq(memberCertificationSeq);
    setShowLoading(true);
  };

  // 본인인증 완료(decode 실패) : decode API 오류 생겼을 때, 실행할 함수
  const identificationExceptCallback = err => {
    console.error(err);
    const { data } = err.response;
    setAlertModalMessage(data.message);
    setShowNoBtnModal(true);
  };

  const onClickDataLinkagePage = () => {
    navigate(ROUTE_PATH.mypage.dataLinkageManage);
  };

  if (!dataLinkageStatus) {
    return <div />;
  }

  return (
    <LoanLimitWrapper>
      <Overlay display={showLoading.toString()}>
        <LoadingBar />
        <LoadingMessage>대출 한도를 조회 중이에요.</LoadingMessage>
      </Overlay>
      <MainSectionTwo>
        <BlueAlertBox
          title="대출 비교 서비스는 곧 오픈 예정이에요."
          titleJustifyContent="center"
          center
        >
          <div style={{ textAlign: 'center' }}>
            오픈 후, 여러 금융기관의 대출 조건을 비교해서 <br />
            나에게 맞는 최적의 대출 조건을 선택할 수 있어요.
          </div>
        </BlueAlertBox>

        <TextMedium>
          <TextBlue>{businessName}</TextBlue>님
          <br />
          {dataLinkageStatus === 'nothing'
            ? `데이터 연동 후 금융사의 대출 한도를\n한 번에 조회할 수 있어요.`
            : `금융사의 대출 한도를\n한 번에 조회할 수 있어요.`}
        </TextMedium>

        <DataProviderTable
          tableHead={tableHead}
          dataProviderList={dataProviderList}
        />

        {dataLinkageStatus === 'nothing' ? (
          <NoLinkedDataStatus onClickDataLinkagePage={onClickDataLinkagePage} />
        ) : (
          <LinkedDatatStatus
            onClickDataLinkagePage={onClickDataLinkagePage}
            getLoanableMall={getLoanableMall}
          />
        )}
      </MainSectionTwo>

      {dataLinkageStatus !== 'nothing' && <FooterInfo />}

      {/* 판매몰 선택 모달 */}
      <SelectMallModal
        showSelectMallModal={showSelectMallModal}
        setShowSelectMallModal={setShowSelectMallModal}
        onClickResetMall={onClickResetMall}
        onClickSelectMall={onClickSelectMall}
        checkedMallCount={checkedMallCount}
        availableMallList={availableMallList}
        setCheckedMallList={setCheckedMallList}
        rightButtonDisabled={checkedMallList?.length === 0}
        mallModalType={mallModalType}
        onClickDataLinkagePage={onClickDataLinkagePage}
      />
      {/* 휴대폰 본인인증 모달 */}
      <IdentificationModal
        showIdentificationModal={showIdentificationModal}
        onCloseIdentificationModal={onCloseIdentificationModal}
        identificationSuccessCallback={identificationSuccessCallback}
        identificationExceptCallback={identificationExceptCallback}
        alertModalMessage={alertModalMessage}
        mallModalType={mallModalType}
        stipulationList={stipulationList}
        setStipulationList={setStipulationList}
      />
      {/* 오류 메시지 모달 */}
      <NoBtnModal
        title={noBtnModalTitle}
        showModal={showNoBtnModal}
        onClose={() => {
          setShowNoBtnModal(false);
          setTimeout(() => {
            if (hasResetCookie) {
              localStorage.removeItem(KEY_MEMBERCERTIFICATIONSEQ);
              setShowIdentificationModal(true);
            }
          }, 100);
        }}
      >
        <ScrollArea>
          <Contents>{alertModalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </LoanLimitWrapper>
  );
};

// 대출 가능한 몰 목록 조회
export const getMallList = ({
  setAvailableMallList,
  setAlertModalMessage,
  setShowSelectMallModal,
  setShowNoBtnModal,
  dataUseType,
}) => {
  getListDataRequest({
    url: '/v1/au/loans-limit/mall',
    params: { dataUseType },
    successFnc: list => {
      if (list.length > 0) {
        setAvailableMallList(list);
        setShowSelectMallModal(true);
      } else {
        setShowNoBtnModal(true);
        setAlertModalMessage('대출 한도 조회에 사용 가능한 판매몰이 없어요.');
      }
    },
  });
};

// 약관 목록 조회
export const getStipulationList = ({
  setStipulationList,
  stipulationClassificationCd,
}) => {
  getResultDataRequest({
    url: '/v1/na/stipulation',
    params: { stipulationClassificationCd: stipulationClassificationCd },
    successFnc: result => {
      if (result && result.length > 0) {
        setStipulationList(
          result
            .filter(item => item.stipulationTypeCd === 'STC100')
            .map(item => ({
              ...item,
              required: item.essentialYn === 'Y',
              checked: false,
            }))
            .sort((a, b) => b.required - a.required) // '필수'를 앞에 정렬
        );
      }
    },
  });
};

// 계산된 최대한도 조회
export const getCreditGoodsList = ({
  loansLimitMallList,
  loansLimitInquiryRequestSeq,
  dataUseType,
  successCallback,
  setShowNoBtnModal,
  setAlertModalMessage,
}) => {
  createDataRequest({
    url: '/v1/au/loans-limit/credit-goods',
    data: { loansLimitMallList, loansLimitInquiryRequestSeq, dataUseType },
    successFnc: (data, message) => {
      if (!!data.interestAscList && !!data.limitDescList) {
        successCallback(data);
      } else {
        setShowNoBtnModal(true);
        setAlertModalMessage(message);
      }
    },
    setModalMessage: setAlertModalMessage,
    setShowAlertModal: setShowNoBtnModal,
  });
};

export default LoanLimitBasicPage;
