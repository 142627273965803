import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import BlueAlertBox from 'components/BlueAlertBox';
import CheckBox from 'components/CheckBox';
import {
  CheckBoxWrapper,
  SecondAuthInfoWrapper,
  Card,
  CardColumn,
  DetailButton,
  CardName,
} from './style';

const LinkageGuideBox = ({
  secondAuthReleaseMessage = '',
  secondAuthReleaseType = '',
  isChecked = false,
  onChangeCheckBox = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isChecked) {
      setIsOpen(true);
    }
  }, [isChecked]);

  const onClickDetailBtn = event => {
    setIsOpen(prev => !prev);
  };

  const contents = (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(secondAuthReleaseMessage, {
          ADD_ATTR: ['target'],
        }),
      }}
    />
  );

  return (
    <SecondAuthInfoWrapper>
      <CheckBoxWrapper>
        <CheckBox
          id="check-box-0"
          isChecked={isChecked}
          onChangeCheckBox={onChangeCheckBox}
          value="secondAuth"
          text="2차 인증 해제를 완료하였습니다."
          required={secondAuthReleaseType === 'REQUIRED'}
          recommended={secondAuthReleaseType === 'RECOMMENDED'}
          style={{ fontSize: '1.4rem' }}
        />
      </CheckBoxWrapper>

      <Card isOpen={isOpen}>
        <CardColumn>
          <CardName>
            {secondAuthReleaseType === 'REQUIRED'
              ? `2차 인증 해제 후 데이터 연동이 가능해요.\n2차 인증 해제를 먼저 진행해 주세요.`
              : secondAuthReleaseType === 'RECOMMENDED'
              ? `원활한 데이터 연동을 위해 2차 인증 해제를\n권장 드려요.`
              : ''}
          </CardName>
        </CardColumn>
        {!!secondAuthReleaseMessage && (
          <DetailButton
            onClick={onClickDetailBtn}
            rotated={isOpen}
            aria-label={isOpen ? '접기' : '펼치기'}
          />
        )}
      </Card>
      {isOpen && secondAuthReleaseMessage && (
        <BlueAlertBox contents={contents} margin="5px 0 0" background="white" />
      )}
    </SecondAuthInfoWrapper>
  );
};

export default LinkageGuideBox;
