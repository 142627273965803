import React, { useEffect } from 'react';
import {
  ModalWrapper,
  ModalHeader,
  Title,
  ModalBtn,
  ModalVerticalBtnWrap,
  ModalClose,
  SideArea,
  Wrapper,
} from '../style';

const TwoVerticalBtnModal = ({
  title = '',
  showModal = false,
  onClose = () => {},
  onClickTopBtn = () => {},
  onClickBottomBtn = () => {},
  topButton = '',
  bottomButton = '',
  children,
  border = false,
  disabled = false,
}) => {
  useEffect(() => {
    document.body.style.overflowY = showModal ? 'hidden' : 'auto';
  }, [showModal]);

  return (
    showModal && (
      <Wrapper>
        <ModalWrapper border={!!border}>
          <ModalHeader>
            <Title>{title}</Title>
            <ModalClose onClick={onClose} />
          </ModalHeader>
          {children}
          <ModalVerticalBtnWrap>
            <ModalBtn
              background="blue"
              onClick={onClickTopBtn}
              disabled={disabled}
            >
              {topButton}
            </ModalBtn>
            <ModalBtn onClick={onClickBottomBtn}>{bottomButton}</ModalBtn>
          </ModalVerticalBtnWrap>
        </ModalWrapper>
        <SideArea onClick={onClose} />
      </Wrapper>
    )
  );
};

export default TwoVerticalBtnModal;
