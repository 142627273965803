import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import useApp from 'hooks/useApp';
import { getListDataRequest } from 'utils/request';
import ROUTE_PATH from 'routes/config';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import SearchBox from 'components/SearchBox';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import AuthQuestion from './AuthQuestion';
import NoAuthQuestion from './NoAuthQuestion';
import { SearchBoxWrapper, QnAListWrap, NoListTextTitle } from './style';

const QnATab = () => {
  const { auth } = useApp();
  const isLogin = auth.authenticated;
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [qnaList, setQnaList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [alertMessage, setAlertMessage] = useState('');
  // 무한 스크롤 구현
  const [ref, inView] = useInView();

  // QnA 목록 조회: 최초 렌더링
  useEffect(() => {
    getQnaList(1);
  }, []);

  const getQnaList = (page = 1) => {
    const url = isLogin ? `/v1/au/qna` : `/v1/na/qna`;
    getListDataRequest({
      url: url,
      params: {
        pageNo: page,
        searchValue: searchValue,
        articleCount: 30,
      },
      setData: setQnaList,
      setPaginationData: setPaginationData,
      setModalMessage: setAlertMessage,
    });
  };

  // QnA 목록 조회: 무한 스크롤 구현
  const { list, onClickSearchButton } = useInfiniteScroll(
    qnaList,
    getQnaList,
    paginationData,
    inView
  );

  // 문의하기 버튼
  const onClickCreatQuestionBtn = isLogin => {
    const createQuestionPage = isLogin
      ? ROUTE_PATH.serviceCenter.auth.create
      : ROUTE_PATH.serviceCenter.noAuth.create;
    navigate(createQuestionPage);
  };

  // 사용자 입력 값 담기
  const onChangeSearchValue = event => {
    setSearchValue(event.target.value);
  };

  return (
    <div>
      {/* 문의 검색창 */}
      <SearchBoxWrapper>
        <SearchBox
          placeholder={
            isLogin
              ? '문의 내용을 빠르게 검색해 보세요.'
              : '이메일 주소 전체를 입력해 주세요.'
          }
          value={searchValue}
          onClickSearch={onClickSearchButton}
          onChange={onChangeSearchValue}
          maxLength={50}
        />
      </SearchBoxWrapper>

      {/* 문의 추가 버튼 */}
      <StyledActiveButton
        margin="0px 0px 17px 0px"
        onClick={() => onClickCreatQuestionBtn(isLogin)}
      >
        문의하기
      </StyledActiveButton>

      {/* 저장된 문의 목록 */}
      {paginationData?.totalCount > 0 && (
        <QnAListWrap>
          {list.map((item, index) => {
            return isLogin ? (
              <AuthQuestion
                simpleQnAData={item}
                key={`QnA_${item.manToManInquirySeq}_${index}`}
              />
            ) : (
              <NoAuthQuestion
                simpleQnAData={item}
                key={`QnA_${item.manToManInquirySeq}_${index}`}
              />
            );
          })}
          {/* 무한 스크롤 구현 */}
          <div ref={ref} />
        </QnAListWrap>
      )}
      {paginationData?.totalCount === 0 && (
        <NoListTextTitle>문의 내역이 없습니다.</NoListTextTitle>
      )}

      <NoBtnModal
        showModal={!!alertMessage}
        onClose={() => setAlertMessage('')}
      >
        <ScrollArea>
          <Contents>{alertMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    </div>
  );
};

export default QnATab;
