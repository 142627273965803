import styled from 'styled-components';
import theme from 'styles/theme';

export const LoanCard = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 16px 0 17px;
  border-bottom: 1px solid ${theme.borderColor.lightGrey};

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const LoanCardColumn = styled.div`
  position: relative;
  float: right;
  width: 100%;

  &:after {
    content: '';
    display: block;
    clear: both;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
  }
`;

export const LoanStatus = styled.div`
  display: block;
  width: 100%;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.grey4};
`;

export const LoanBankName = styled.div`
  display: block;
  width: 100%;
  margin: 7px 0 0;
  padding: 0 0 14px;
  line-height: 2rem;
  font-size: 1.8rem;
  font-weight: 700;
  color: ${theme.color.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LoanCardRow = styled.div`
  display: block;
  width: 100%;
  margin: 4px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const LoanCardRowFirst = styled.div`
  float: left;
  width: 74px;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  color: ${theme.color.grey7};
`;

export const LoanCardRowSecond = styled.div`
  float: left;
  width: calc(100% - 74px);
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 2rem;
  color: ${theme.color.black};
`;

export const LoanCardRowDate = styled.span`
  font-weight: 400;
  font-size: 1.3rem;
  color: ${theme.color.grey7};
`;

export const DetailButton = styled.button`
  position: absolute;
  display: block;
  width: 36px;
  height: 36px;
  top: -9px;
  right: 0;
  background: url(/images/main_ar_s2.png) right no-repeat;
  background-size: 6px 11px;
`;

export const DetailModalWrap = styled.div`
  display: block;
  width: 100%;
  margin: 23px 0 0;
  padding: 20px 0 0;
  border-radius: 10px;
`;

export const DetailModalTitle = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.navyBlue};
  text-align: left;
  line-height: 2.1rem;

  &:first-child {
    margin: 0;
  }
`;

export const DetailModalTitleTable = styled.div`
  display: block;
  width: 100%;
  margin: 19px 0 0;
  padding: 0 0 10px 0;
  font-size: 1.3rem;
  font-weight: 400;
  color: ${theme.color.navyBlue};
  text-align: left;
  line-height: 2.1rem;

  &:first-child {
    margin: 0;
  }
`;

export const DetailModalData = styled.div`
  display: block;
  width: 100%;
  margin: 3px 0 0;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const DetailModalText = styled.div`
  float: left;
  display: block;
  color: ${theme.color.black};
  font-size: 1.3rem;
  font-weight: 500;
  text-align: left;
  line-height: 2.1rem;
`;

export const ButtonWrap = styled.div`
  display: flex;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`;

export const ToolTip = styled.button`
  display: block;
  float: left;
  width: 25px;
  height: 22px;
  margin: -1px 1px 0 0;
  background: url(/images/tooltip-icon20.png) left no-repeat;
  background-size: 20px 20px;
`;
