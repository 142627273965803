import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createDataRequest } from 'utils/request';
import useApp from 'hooks/useApp';
import { moneyFormat } from 'tools/MoneyTool';
import { getCurrentDate } from 'tools/DateTool';
import ROUTE_PATH from 'routes/config';
import { getCreditGoodsList } from 'pages/MyPage/LoanLimit/LoanLimitBasicPage';
import { NoBtnModal } from 'components/Modal';
import { Contents, ScrollArea } from 'components/Modal/style';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import BasicTable from 'components/Table/BasicTable';
import MainLoanProduct from './MainLoanProduct';
import TooltipModal from './TooltipModal';
import { DATA_USE_TYPE_KR, ORDER_OPTION } from './constants';
import { ColoredStatusSpan } from 'styles/ColoredStatusSpan';
import {
  LoanLimitWrapper,
  MainSectionTwo,
  TextMedium,
  TextBlue,
  ChildText,
  ChildTextContent,
  Tabs,
  TabButton,
  ImgDiv,
  Img,
  Column,
  LoanName,
  BlackTitle,
  TextDiv,
  TextTitle,
  TextContent,
  Text,
  BasicTableDiv,
  MainBoxText,
  MainInfoSection,
  TabSection,
  ListTop,
  MainBoxBtn,
  MainBoxTooltip,
} from './style';

const imgHost = process.env.REACT_APP_FINANCE_IMG_HOST;

const LoanProductsListPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const { auth } = useApp();
  const { businessName } = auth;
  const { mypage } = ROUTE_PATH;

  // 대출상품리스트페이지 데이터
  const [activeTab, setActiveTab] = useState(ORDER_OPTION.interest);
  const [_creditGoodsList, _setCreditGoodsList] = useState({});
  const [creditGoodsList, setCreditGoodsList] = useState([]);
  const [lowestInterestRateData, setLowsetInterestRateData] = useState({});
  const [highestLimitData, setHighestLimitData] = useState({});

  // 모달 data
  const [usedMallList, setUsedMallList] = useState([]); //이전 페이지 사용자가 체크한 몰 리스트

  // 대출 신청 params
  const [memberCertificationSeq, setMemberCertificationSeq] = useState(0); //본인인증 후 member Seq

  // alert 메시지
  const [isNoBtnModalOpen, setIsNoBtnModalOpen] = useState(false); // 오류 메시지 모달
  const [alertModalMessage, setAlertModalMessage] = useState(''); // 오류 메시지
  const [isTooltipModalOpen, setIsTooltipModalOpen] = useState(false); // 대출 불가 안내 모달
  const [tooltipMessageCd, setTooltipMessageCd] = useState('');

  const dataUseType = location.pathname.includes('credit-loan')
    ? 'CREDIT_LOAN_CMPRS'
    : 'LOAN_CMPRS';

  useEffect(() => {
    if (state) {
      setMemberCertificationSeq(state.memberCertificationSeq);
      setUsedMallList(state.usedMallList || []);
    } else {
      navigate(mypage.checkLoan);
    }
  }, [state]);

  const onClickTabButton = e => {
    const { value } = e.target;
    setActiveTab(value);
  };

  useEffect(() => {
    if (usedMallList?.length > 0) {
      const data = {
        agreeList: state.agreeList,
        dataUseType,
        memberCertificationSeq: state.memberCertificationSeq,
      };

      // 신용대출 휴대폰 본인인증 CI 체크 및 약관 등록
      createDataRequest({
        url: '/v1/au/loans-limit/certification',
        data: data,
        setModalMessage: setAlertModalMessage,
        setShowAlertModal: setIsNoBtnModalOpen,
        successFnc: data => {
          // 계산된 최대한도 조회
          getCreditGoodsList({
            loansLimitMallList: usedMallList,
            loansLimitInquiryRequestSeq: data.loansLimitInquiryRequestSeq,
            dataUseType,
            successCallback: data => {
              _setCreditGoodsList(data || {});
            },
            setShowNoBtnModal: setIsNoBtnModalOpen,
            setAlertModalMessage,
          });
        },
      });
    }
  }, [usedMallList]);

  useEffect(() => {
    activeTab === ORDER_OPTION.limit
      ? setCreditGoodsList(_creditGoodsList.limitDescList || [])
      : setCreditGoodsList(_creditGoodsList.interestAscList || []);

    // 최저금리
    setLowsetInterestRateData(_creditGoodsList.interestAscList?.[0]);
    // 최대한도
    setHighestLimitData(_creditGoodsList.limitDescList?.[0]);
  }, [_creditGoodsList, activeTab]);

  // 디테일페이지로 이동
  const onClickProductPage = (
    creditGoodsMasterEventSeq,
    loansLimitInquirySeq
  ) => {
    location.pathname.includes('credit-loan')
      ? navigate(mypage.checkCreditLoanDetail, {
          state: {
            creditGoodsMasterEventSeq: creditGoodsMasterEventSeq,
            memberCertificationSeq: memberCertificationSeq,
            usedMallList: usedMallList,
            loansLimitInquirySeq: loansLimitInquirySeq,
          },
        })
      : navigate(mypage.checkLoanDetail, {
          state: {
            creditGoodsMasterEventSeq: creditGoodsMasterEventSeq,
            memberCertificationSeq: memberCertificationSeq,
            usedMallList: usedMallList,
            loansLimitInquirySeq: loansLimitInquirySeq,
          },
        });
  };

  const onClickTooltip = event => {
    setTooltipMessageCd(event?.target?.value || '');
    setIsTooltipModalOpen(prev => !prev);
  };

  const onClickCheckLoanLimit = () => {
    navigate(ROUTE_PATH.mypage.checkLoan);
  };

  const tableLayout = item => {
    const {
      creditGoodsMasterEventSeq,
      creditGoodsMasterEventName,
      financeInstitutionName,
      financeImgPath = '',
      maximumLimit,
      lowestInterest,
      badge,
      loansLimitInquirySeq,
      targetMallList,
      inquiryResultCd,
    } = item;
    const financeImgUrl = imgHost + financeImgPath;
    const maxLimitAmount = moneyFormat(maximumLimit);

    return (
      <>
        <ImgDiv>
          <Img src={financeImgUrl} alt={financeInstitutionName} />
        </ImgDiv>
        <Column>
          <ListTop>
            <LoanName>{creditGoodsMasterEventName}</LoanName>
            {badge === '대출 불가' ? (
              <MainBoxTooltip onClick={onClickTooltip} value={inquiryResultCd}>
                <ColoredStatusSpan
                  badge={badge}
                  onClick={event => event.stopPropagation()}
                >
                  {badge}
                </ColoredStatusSpan>
              </MainBoxTooltip>
            ) : (
              <MainBoxBtn
                onClick={() =>
                  onClickProductPage(
                    creditGoodsMasterEventSeq,
                    loansLimitInquirySeq
                  )
                }
              >
                <ColoredStatusSpan badge={badge}>
                  {badge || ''}
                </ColoredStatusSpan>
              </MainBoxBtn>
            )}
          </ListTop>
          <BlackTitle>{financeInstitutionName}</BlackTitle>
          {badge === '대출 불가' ? (
            <TextDiv>
              {!!targetMallList && (
                <Text>
                  <TextTitle>
                    {location.pathname.includes('credit-loan')
                      ? '전용 상품'
                      : '대상 판매몰'}
                  </TextTitle>
                  <TextContent>{targetMallList}</TextContent>
                </Text>
              )}
            </TextDiv>
          ) : (
            <div>
              <TextDiv>
                <Text>
                  <TextTitle>최대한도</TextTitle>
                  <TextContent>{maxLimitAmount}</TextContent>
                </Text>
                <Text>
                  <TextTitle>최저금리</TextTitle>
                  <TextContent>{lowestInterest}%</TextContent>
                </Text>
              </TextDiv>
              <TextDiv>
                {!!targetMallList && (
                  <Text>
                    <TextTitle>
                      {location.pathname.includes('credit-loan')
                        ? '전용 상품'
                        : '대상 판매몰'}
                    </TextTitle>
                    <TextContent>{targetMallList}</TextContent>
                  </Text>
                )}
              </TextDiv>
            </div>
          )}
        </Column>
      </>
    );
  };

  return (
    <LoanLimitWrapper>
      <MainSectionTwo>
        <TextMedium>
          <TextBlue>{businessName}</TextBlue>님
          <br />
          <TextBlue>{creditGoodsList.length}</TextBlue>개 금융사가{' '}
          {DATA_USE_TYPE_KR[dataUseType]} 대출 조건을 보내왔어요.
        </TextMedium>

        {creditGoodsList.length > 2 && (
          <MainInfoSection>
            <MainLoanProduct
              title="최저금리"
              data={lowestInterestRateData}
              onClickProductPage={onClickProductPage}
              memberCertificationSeq={memberCertificationSeq}
              usedMallList={usedMallList}
            />
            <MainLoanProduct
              title="최대한도"
              data={highestLimitData}
              onClickProductPage={onClickProductPage}
            />
          </MainInfoSection>
        )}

        <TabSection>
          <Tabs>
            <TabButton
              tabIndex={0}
              value={ORDER_OPTION.interest}
              active={activeTab === ORDER_OPTION.interest}
              onClick={onClickTabButton}
            >
              금리 낮은순
            </TabButton>
            <TabButton
              tabIndex={0}
              value={ORDER_OPTION.limit}
              active={activeTab === ORDER_OPTION.limit}
              onClick={onClickTabButton}
            >
              한도 높은순
            </TabButton>
            <MainBoxText
              style={{ marginTop: '20px' }}
            >{`${getCurrentDate()} - 연동데이터 ${
              usedMallList?.length
            }개 기준`}</MainBoxText>
          </Tabs>
        </TabSection>

        <BasicTable tableLayout={tableLayout} tableData={creditGoodsList} />

        <StyledActiveButton tabIndex={0} onClick={onClickCheckLoanLimit}>
          대출 한도 다시 조회
        </StyledActiveButton>
        <ChildText>
          <ChildTextContent>
            한도와 금리는 대출 한도 조회 시점마다 달라질 수 있습니다.
          </ChildTextContent>
        </ChildText>
        <BasicTableDiv />

        {/* 대출 불가 안내 모달 */}
        <TooltipModal
          isTooltipModalOpen={isTooltipModalOpen}
          onClickTooltip={onClickTooltip}
          tooltipMessageCd={tooltipMessageCd}
        />

        {/* 오류 메시지 모달 */}
        <NoBtnModal
          showModal={isNoBtnModalOpen}
          onClose={() => {
            setIsNoBtnModalOpen(false);
          }}
        >
          <ScrollArea>
            <Contents>{alertModalMessage}</Contents>
          </ScrollArea>
        </NoBtnModal>
      </MainSectionTwo>
    </LoanLimitWrapper>
  );
};

export default LoanProductsListPage;
