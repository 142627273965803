/* FO와 BO > LINKAGE_FAIL_USER_MESSAGES 공통 내용입니다. (업데이트 시 동일 작업 필수) */
/* E11900, E11901 은 CCN 에러. 그 외에는 모두 통신에러 입니다. (수진 23.12.06) */

export const LINKAGE_FAIL_USER_MESSAGES = {
  E00500: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11211:
    '등록하신 계정은 이미 등록되었습니다.\n등록된 계정이 아니라면, 고객센터로 문의해 주세요.',
  E11212: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11311: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11321: '판매몰 연동이 불가합니다.\n고객센터로 문의해 주세요.',
  E11322: '판매몰 연동이 불가합니다.\n고객센터로 문의해 주세요.',
  E11411: '판매몰 연동이 불가합니다.\n고객센터로 문의해 주세요.',
  E11412:
    '연동 판매몰의 로그인 계정 정보가 상이합니다.\n계정을 다시 한 번 확인해 주세요.',
  E11413: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11414: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11415:
    '판매몰의 캡차(captcha) 인증이 필요합니다.\n캡차 인증을 진행해 주세요.',
  E11416:
    '현재 판매몰 데이터 연동 진행중입니다.\n잠시 후 연동상태를 확인해 주세요.',
  E11417:
    '판매몰의 계정이 잠겼습니다.\n해당 몰에 접속 후 잠금 해제를 진행해 주세요.',
  E11418:
    '판매몰의 비밀번호 재설정이 필요합니다.\n해당 몰에서 비밀번호를 재설정 해주세요.',
  E11419:
    '판매자 센터에 가입되지 않은 계정입니다.\n판매자 센터에 가입된 계정으로 다시 한번 연동을 진행해 주세요.',
  E11511: '판매몰 연동이 불가합니다.\n고객센터로 문의해 주세요.',
  E11611: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11612:
    '현재 판매몰 데이터 연동 진행중입니다.\n잠시 후 연동상태를 확인해 주세요.',
  E11613: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11711: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  E11900:
    '2차 인증 해제가 완료되지 않았습니다.\n해당 몰에서 2차 인증 해제를 진행해 주세요.',
  E11901: '이미 등록되어있는 계정입니다.\n계정을 다시 한번 확인해 주세요.',
  FRT111:
    '연동 판매몰의 로그인 계정 정보가 상이합니다.\n연동정보를 변경해 주세요.',
  FRT112: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT113: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT114: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT121:
    '판매몰의 2차 인증이 실패하였습니다.\n연동을 다시 한번 진행해 주세요.',
  FRT122: '판매몰의 2차 인증이 실패하였습니다.\n고객센터로 문의해주세요.',
  FRT123: '판매몰의 2차 인증이 실패하였습니다.\n고객센터로 문의해주세요.',
  FRT124:
    '판매몰의 2차 인증시간이 만료되었습니다.\n연동을 다시 한번 진행해 주세요.',
  FRT125:
    '판매몰의 2차 인증 정보가 맞지않습니다.\n2차 인증을 다시 한번 진행해 주세요.',
  FRT126:
    '판매몰의 2차 인증이 필요합니다.\n문자로 전송된 URL에 접속 후 2차 인증을 진행해 주세요.',
  FRT127:
    '2차 인증이 유효하지 않습니다. 해당 몰에서 2차 인증 수신 정보를 재설정 해주세요.',
  FRT128: '2차 인증 진행이 취소되었습니다.',
  FRT129:
    '판매몰의 2차 인증을 수신받을 정보가 저장되어있지 않습니다. 해당 몰에서 2차 인증 수신 정보를 재설정 해주세요.',
  FRT131: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT132: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT133: '판매몰 연동이 불가합니다.\n고객센터로 문의해 주세요',
  FRT134:
    '판매몰 내 사업자 번호가 존재하지 않습니다.\n해당 몰에서 사업자 정보를 등록해 주세요.',
  FRT141: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT142: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT143: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT151:
    '판매몰의 캡차(captcha) 인증이 실패하였습니다.\n연동을 다시 한번 진행해 주세요.',
  FRT155:
    '판매몰의 캡차(captcha) 인증시간이 만료되었습니다.\n캡차 인증을 다시 한번 진행해 주세요.',
  FRT157:
    '판매몰의 캡차(captcha) 인증이 필요합니다.\n문자로 전송된 URL에 접속 후 캡차 인증을 진행해 주세요.',
  FRT158:
    '판매몰의 캡차(captcha) 인증이 실패하였습니다.\n연동을 다시 한번 진행해 주세요.',
  FRT161:
    '현재 판매몰의 계정이 잠김 상태 입니다.\n해당 판매자 센터에 로그인 후 계정 잠금을 해제해 주세요.',
  FRT162:
    '판매몰의 비밀번호 재설정이 필요합니다.\n해당 몰에서 비밀번호를 재설정 해주세요.',
  FRT163:
    '현재 판매몰 연동시 인증 갱신을 필요로 합니다.\n해당 판매자 센터에 로그인 후 인증 갱신을 진행해 주세요.',
  FRT164:
    '현재 판매몰 연동시 비밀번호 재설정을 필요로 합니다.\n해당 판매자 센터에 로그인 후 비밀번호 재설정을 진행해 주세요.',
  FRT165:
    '네이버 판매자 센터에 가입되지 않은 계정입니다.\n판매자 센터에 가입된 계정으로 다시 한번 연동을 진행해 주세요.',
  FRT167:
    '사업자 전환이 필요한 계정입니다.\n해당 판매자 센터에 로그인 후 사업자 전환을 진행해 주세요.',
  FRT168:
    '현재 판매몰 로그인이 필요한 계정입니다. 해당 판매자 센터에 로그인 후 내용을 확인해 주세요.',
  FRT169:
    '해당 판매몰 계정에 1건 이상의 스토어가 운영되고 있습니다. 고객센터로 문의해 주세요.',
  FRT171: `반복된 2차 인증 시도로 인해 일시적으로 잠김 상태입니다.\n잠시 후 '2차 인증 재요청하기' 버튼을 클릭 후 다시 한번 인증을 진행해주세요.\n(잠김 상태는 최대 30분동안 지속될 수 있습니다.)`,
  FRT997: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT998: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  FRT999: '현재 판매몰 연동이 원할하지 않습니다.\n고객센터로 문의해 주세요.',
  ETC: '판매몰 연동이 불가합니다.\n고객센터로 문의해 주세요.',
};
