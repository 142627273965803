import React, { useEffect, useState } from 'react';
import { createDataRequest } from 'utils/request';
import StyledActiveButton from 'components/Button/StyledActiveButton';
import TextField from 'components/TextField';
import { NoBtnModal } from 'components/Modal';
import GoBackToPrev from '../../../GoBackToPrev';
import SelectManagerModal from '../../SelectManagerModal';
import { Contents, ScrollArea } from 'components/Modal/style';
import { FirstText, TextBlue } from '../../../style';
import {
  ChildText,
  ChildTextContent,
  ChildTextRow,
  ContentBox,
} from '../../style';
import useApp from 'hooks/useApp';

const IndividualRequestStep2 = ({
  setStep,
  dataProviderSeq,
  setDataProviderSeq,
  dataInterlockInfo,
  setDataInterlockInfo,
  setPossibleInterlockYn,
}) => {
  const { auth } = useApp();
  const { memberRoleData, memberTotalData } = auth;
  const { mallName = '', name = '' } = dataInterlockInfo;

  // 연동 담당자
  const [showSelectManagerModal, setShowSelectManagerModal] = useState(false);

  // Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const isRepresentation = memberRoleData?.representativeYn === 'Y';

  const onClickReset = event => {
    // 초기화 및 페이지 이동
    setDataProviderSeq(0);
    setStep(1);
  };

  const onClickRequestBtn = event => {
    const {
      mallSeq,
      mallId,
      mallPassword,
      secondAuthReleaseYn,
      name,
      mobilePhone,
    } = dataInterlockInfo;
    const postData = {
      dataProviderSeq,
      mallSeq,
      mallId,
      mallPassword,
      secondAuthReleaseYn,
      businessManagerName: name,
      businessManagerMobilePhone: mobilePhone,
    };

    const successFnc = data => {
      setStep(3);
      setPossibleInterlockYn(data.possibleInterlockYn);
    };

    createDataRequest({
      url: '/v1/au/data-provider/individual',
      data: postData,
      successFnc,
      setModalMessage,
      setShowAlertModal: setIsModalOpen,
    });
  };

  const renderNoBtnModal = () => {
    const onClickCloseModal = () => {
      setIsModalOpen(false);
    };

    return (
      <NoBtnModal showModal={isModalOpen} onClose={onClickCloseModal}>
        <ScrollArea>
          <Contents>{modalMessage}</Contents>
        </ScrollArea>
      </NoBtnModal>
    );
  };

  const onClickManagerModal = () => {
    setShowSelectManagerModal(true);
  };

  useEffect(() => {
    // 직원일 경우, 접속한 해당 직원 정보로 설정
    if (!isRepresentation) {
      !!setDataInterlockInfo &&
        typeof setDataInterlockInfo === 'function' &&
        setDataInterlockInfo(prev => ({
          ...prev,
          name: memberTotalData.memberName,
          mobilePhone: memberTotalData.mobilePhone,
        }));
    }
  }, [isRepresentation]);

  return (
    <div>
      <GoBackToPrev onClickReset={onClickReset} />
      <FirstText>
        <TextBlue>{mallName}</TextBlue> 계정 확인이 완료되었어요.
        <br />
        마지막 단계에요
        <br />
        데이터 연동 요청을 진행해 주세요.
      </FirstText>

      <ContentBox>
        <ChildText>
          <ChildTextRow>
            <ChildTextContent>데이터 연동 담당자</ChildTextContent>
            {isRepresentation && (
              <StyledActiveButton $small onClick={onClickManagerModal}>
                담당자 선택
              </StyledActiveButton>
            )}
          </ChildTextRow>
          <TextField
            name="managerName"
            placeholder="데이터 연동 담당자를 선택해 주세요."
            value={name}
            readOnly
            style={{ margin: '0 0 23px' }}
          />
          <SelectManagerModal
            showSelectManagerModal={showSelectManagerModal}
            setShowSelectManagerModal={setShowSelectManagerModal}
            mallName={mallName}
            setDataInterlockInfo={setDataInterlockInfo}
            managerName={name}
          />
          <StyledActiveButton
            onClick={onClickRequestBtn}
            margin="6px 0 23px"
            disabled={!name}
          >
            데이터 연동 요청
          </StyledActiveButton>
        </ChildText>
      </ContentBox>

      {renderNoBtnModal()}
    </div>
  );
};

export default IndividualRequestStep2;
